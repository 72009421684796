import { ContactUsStandard } from "../../components/ContactUsStandard";
import { Footer } from "../../components/Footer";
import { IconsRow } from "../../components/IconsRow";
import { SlideScritta, VideoSlide } from "../../components/MainSlide";
import { Partner } from "../../components/Partner";
import { Scroller } from "../../components/Scroller";
import { TopDescription } from "../../components/TopDescription";
import { WorkWithUs } from "../../components/WorkWithUs";
import { ServicesResort } from "../resort/Resort";
import { Restaurants } from "./restaurants";
import { ExpiriencesScroller } from "./expiriencesScroller";
import { HotelIconsRow } from "./hotelIconRow";
import { IncludedInRooms } from "./includedInRooms";
import { Rooms } from "./Rooms";
import { HotelServicesRow } from "./servicesRow";
import { Pool } from "./pool";
import { Como } from "./como";

export function Hotel() {
  return (
    <>
      <VideoSlide
        onClick={() =>
          window.open(
            "https://reservations.verticalbooking.com//premium/index.html?id_stile=20865&lingua_int=ita&id_albergo=27613&dc=8387"
          )
        }
        logo="/hotelImg/hotelScritta.webp"
        videoUrl="https://tiglivideo.b-cdn.net/hotel.mp4"
      />
      <TopDescription reverse id="16jGUA7Ex6qXgO5yBHMxO8" justifyContent />

      <Rooms />
      <IncludedInRooms />
      <HotelServicesRow />
      <TopDescription reverse id="5wVYjvHZacQbfJEqgqlh3y" />
      <Scroller len={21} name="hotelImg/expiriences/expiriences" />

      <HotelIconsRow />
      <Restaurants />
      <ExpiriencesScroller />
      <Pool />
      <Como id="iYvRsprzzcxXxoYMwi3Z2" />
      <IconsRow />
      <ServicesResort />
      <Scroller len={33} name="hotelImg/gallery/hotel" />
      <ContactUsStandard />
      <Partner />
      <WorkWithUs />
      <Footer
        number="+390318180800"
        email="info@comohills.it"
        prettyNumber="+39 031 8180 800"
      />
    </>
  );
}
