import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  DateTime: { input: any; output: any; }
  Dimension: { input: any; output: any; }
  HexColor: { input: any; output: any; }
  JSON: { input: any; output: any; }
  Quality: { input: any; output: any; }
};

/** [See type definition](https://app.contentful.com/spaces/v2h0bz1hqac3/content_types/articoloStampa) */
export type ArticoloStampa = Entry & _Node & {
  __typename?: 'ArticoloStampa';
  _id: Scalars['ID']['output'];
  anteprima?: Maybe<Scalars['String']['output']>;
  contentfulMetadata: ContentfulMetadata;
  data?: Maybe<Scalars['DateTime']['output']>;
  immagine?: Maybe<Asset>;
  link?: Maybe<Scalars['String']['output']>;
  linkedFrom?: Maybe<ArticoloStampaLinkingCollections>;
  publisher?: Maybe<Scalars['String']['output']>;
  sys: Sys;
};


/** [See type definition](https://app.contentful.com/spaces/v2h0bz1hqac3/content_types/articoloStampa) */
export type ArticoloStampaAnteprimaArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** [See type definition](https://app.contentful.com/spaces/v2h0bz1hqac3/content_types/articoloStampa) */
export type ArticoloStampaDataArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** [See type definition](https://app.contentful.com/spaces/v2h0bz1hqac3/content_types/articoloStampa) */
export type ArticoloStampaImmagineArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};


/** [See type definition](https://app.contentful.com/spaces/v2h0bz1hqac3/content_types/articoloStampa) */
export type ArticoloStampaLinkArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** [See type definition](https://app.contentful.com/spaces/v2h0bz1hqac3/content_types/articoloStampa) */
export type ArticoloStampaLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


/** [See type definition](https://app.contentful.com/spaces/v2h0bz1hqac3/content_types/articoloStampa) */
export type ArticoloStampaPublisherArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

export type ArticoloStampaCollection = {
  __typename?: 'ArticoloStampaCollection';
  items: Array<Maybe<ArticoloStampa>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type ArticoloStampaFilter = {
  AND?: InputMaybe<Array<InputMaybe<ArticoloStampaFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<ArticoloStampaFilter>>>;
  anteprima?: InputMaybe<Scalars['String']['input']>;
  anteprima_contains?: InputMaybe<Scalars['String']['input']>;
  anteprima_exists?: InputMaybe<Scalars['Boolean']['input']>;
  anteprima_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  anteprima_not?: InputMaybe<Scalars['String']['input']>;
  anteprima_not_contains?: InputMaybe<Scalars['String']['input']>;
  anteprima_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  data?: InputMaybe<Scalars['DateTime']['input']>;
  data_exists?: InputMaybe<Scalars['Boolean']['input']>;
  data_gt?: InputMaybe<Scalars['DateTime']['input']>;
  data_gte?: InputMaybe<Scalars['DateTime']['input']>;
  data_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  data_lt?: InputMaybe<Scalars['DateTime']['input']>;
  data_lte?: InputMaybe<Scalars['DateTime']['input']>;
  data_not?: InputMaybe<Scalars['DateTime']['input']>;
  data_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  immagine_exists?: InputMaybe<Scalars['Boolean']['input']>;
  link?: InputMaybe<Scalars['String']['input']>;
  link_contains?: InputMaybe<Scalars['String']['input']>;
  link_exists?: InputMaybe<Scalars['Boolean']['input']>;
  link_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  link_not?: InputMaybe<Scalars['String']['input']>;
  link_not_contains?: InputMaybe<Scalars['String']['input']>;
  link_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  publisher?: InputMaybe<Scalars['String']['input']>;
  publisher_contains?: InputMaybe<Scalars['String']['input']>;
  publisher_exists?: InputMaybe<Scalars['Boolean']['input']>;
  publisher_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  publisher_not?: InputMaybe<Scalars['String']['input']>;
  publisher_not_contains?: InputMaybe<Scalars['String']['input']>;
  publisher_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sys?: InputMaybe<SysFilter>;
};

export type ArticoloStampaLinkingCollections = {
  __typename?: 'ArticoloStampaLinkingCollections';
  entryCollection?: Maybe<EntryCollection>;
};


export type ArticoloStampaLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export enum ArticoloStampaOrder {
  DataAsc = 'data_ASC',
  DataDesc = 'data_DESC',
  LinkAsc = 'link_ASC',
  LinkDesc = 'link_DESC',
  PublisherAsc = 'publisher_ASC',
  PublisherDesc = 'publisher_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

/** Represents a binary file in a space. An asset can be any file type. */
export type Asset = {
  __typename?: 'Asset';
  contentType?: Maybe<Scalars['String']['output']>;
  contentfulMetadata: ContentfulMetadata;
  description?: Maybe<Scalars['String']['output']>;
  fileName?: Maybe<Scalars['String']['output']>;
  height?: Maybe<Scalars['Int']['output']>;
  linkedFrom?: Maybe<AssetLinkingCollections>;
  size?: Maybe<Scalars['Int']['output']>;
  sys: Sys;
  title?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
  width?: Maybe<Scalars['Int']['output']>;
};


/** Represents a binary file in a space. An asset can be any file type. */
export type AssetContentTypeArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** Represents a binary file in a space. An asset can be any file type. */
export type AssetDescriptionArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** Represents a binary file in a space. An asset can be any file type. */
export type AssetFileNameArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** Represents a binary file in a space. An asset can be any file type. */
export type AssetHeightArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** Represents a binary file in a space. An asset can be any file type. */
export type AssetLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


/** Represents a binary file in a space. An asset can be any file type. */
export type AssetSizeArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** Represents a binary file in a space. An asset can be any file type. */
export type AssetTitleArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** Represents a binary file in a space. An asset can be any file type. */
export type AssetUrlArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
  transform?: InputMaybe<ImageTransformOptions>;
};


/** Represents a binary file in a space. An asset can be any file type. */
export type AssetWidthArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

export type AssetCollection = {
  __typename?: 'AssetCollection';
  items: Array<Maybe<Asset>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type AssetFilter = {
  AND?: InputMaybe<Array<InputMaybe<AssetFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<AssetFilter>>>;
  contentType?: InputMaybe<Scalars['String']['input']>;
  contentType_contains?: InputMaybe<Scalars['String']['input']>;
  contentType_exists?: InputMaybe<Scalars['Boolean']['input']>;
  contentType_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  contentType_not?: InputMaybe<Scalars['String']['input']>;
  contentType_not_contains?: InputMaybe<Scalars['String']['input']>;
  contentType_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  description?: InputMaybe<Scalars['String']['input']>;
  description_contains?: InputMaybe<Scalars['String']['input']>;
  description_exists?: InputMaybe<Scalars['Boolean']['input']>;
  description_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  description_not?: InputMaybe<Scalars['String']['input']>;
  description_not_contains?: InputMaybe<Scalars['String']['input']>;
  description_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fileName?: InputMaybe<Scalars['String']['input']>;
  fileName_contains?: InputMaybe<Scalars['String']['input']>;
  fileName_exists?: InputMaybe<Scalars['Boolean']['input']>;
  fileName_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fileName_not?: InputMaybe<Scalars['String']['input']>;
  fileName_not_contains?: InputMaybe<Scalars['String']['input']>;
  fileName_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  height?: InputMaybe<Scalars['Int']['input']>;
  height_exists?: InputMaybe<Scalars['Boolean']['input']>;
  height_gt?: InputMaybe<Scalars['Int']['input']>;
  height_gte?: InputMaybe<Scalars['Int']['input']>;
  height_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  height_lt?: InputMaybe<Scalars['Int']['input']>;
  height_lte?: InputMaybe<Scalars['Int']['input']>;
  height_not?: InputMaybe<Scalars['Int']['input']>;
  height_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  size?: InputMaybe<Scalars['Int']['input']>;
  size_exists?: InputMaybe<Scalars['Boolean']['input']>;
  size_gt?: InputMaybe<Scalars['Int']['input']>;
  size_gte?: InputMaybe<Scalars['Int']['input']>;
  size_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  size_lt?: InputMaybe<Scalars['Int']['input']>;
  size_lte?: InputMaybe<Scalars['Int']['input']>;
  size_not?: InputMaybe<Scalars['Int']['input']>;
  size_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  sys?: InputMaybe<SysFilter>;
  title?: InputMaybe<Scalars['String']['input']>;
  title_contains?: InputMaybe<Scalars['String']['input']>;
  title_exists?: InputMaybe<Scalars['Boolean']['input']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title_not?: InputMaybe<Scalars['String']['input']>;
  title_not_contains?: InputMaybe<Scalars['String']['input']>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  url?: InputMaybe<Scalars['String']['input']>;
  url_contains?: InputMaybe<Scalars['String']['input']>;
  url_exists?: InputMaybe<Scalars['Boolean']['input']>;
  url_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  url_not?: InputMaybe<Scalars['String']['input']>;
  url_not_contains?: InputMaybe<Scalars['String']['input']>;
  url_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  width?: InputMaybe<Scalars['Int']['input']>;
  width_exists?: InputMaybe<Scalars['Boolean']['input']>;
  width_gt?: InputMaybe<Scalars['Int']['input']>;
  width_gte?: InputMaybe<Scalars['Int']['input']>;
  width_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  width_lt?: InputMaybe<Scalars['Int']['input']>;
  width_lte?: InputMaybe<Scalars['Int']['input']>;
  width_not?: InputMaybe<Scalars['Int']['input']>;
  width_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
};

export type AssetLinkingCollections = {
  __typename?: 'AssetLinkingCollections';
  articoloStampaCollection?: Maybe<ArticoloStampaCollection>;
  bloccoServiziCollection?: Maybe<BloccoServiziCollection>;
  cameraCollection?: Maybe<CameraCollection>;
  entryCollection?: Maybe<EntryCollection>;
  eventoPiscinaCollection?: Maybe<EventoPiscinaCollection>;
  imgTitleDescCollection?: Maybe<ImgTitleDescCollection>;
  padelAccademyDescCollection?: Maybe<PadelAccademyDescCollection>;
  poolCollection?: Maybe<PoolCollection>;
  ristoranteFilosofiaCollection?: Maybe<RistoranteFilosofiaCollection>;
};


export type AssetLinkingCollectionsArticoloStampaCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type AssetLinkingCollectionsBloccoServiziCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type AssetLinkingCollectionsCameraCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type AssetLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type AssetLinkingCollectionsEventoPiscinaCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type AssetLinkingCollectionsImgTitleDescCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type AssetLinkingCollectionsPadelAccademyDescCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type AssetLinkingCollectionsPoolCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type AssetLinkingCollectionsRistoranteFilosofiaCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export enum AssetOrder {
  ContentTypeAsc = 'contentType_ASC',
  ContentTypeDesc = 'contentType_DESC',
  FileNameAsc = 'fileName_ASC',
  FileNameDesc = 'fileName_DESC',
  HeightAsc = 'height_ASC',
  HeightDesc = 'height_DESC',
  SizeAsc = 'size_ASC',
  SizeDesc = 'size_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  UrlAsc = 'url_ASC',
  UrlDesc = 'url_DESC',
  WidthAsc = 'width_ASC',
  WidthDesc = 'width_DESC'
}

/** [See type definition](https://app.contentful.com/spaces/v2h0bz1hqac3/content_types/bloccoServizi) */
export type BloccoServizi = Entry & _Node & {
  __typename?: 'BloccoServizi';
  _id: Scalars['ID']['output'];
  contentfulMetadata: ContentfulMetadata;
  linkInfo?: Maybe<Scalars['String']['output']>;
  linkPrenota?: Maybe<Scalars['String']['output']>;
  linkedFrom?: Maybe<BloccoServiziLinkingCollections>;
  listino?: Maybe<Asset>;
  sottoTitolo?: Maybe<Scalars['String']['output']>;
  sys: Sys;
  testo?: Maybe<Scalars['String']['output']>;
  testoPulsanteInfo?: Maybe<Scalars['String']['output']>;
  testoPulsantePrimario?: Maybe<Scalars['String']['output']>;
  titolo?: Maybe<Scalars['String']['output']>;
};


/** [See type definition](https://app.contentful.com/spaces/v2h0bz1hqac3/content_types/bloccoServizi) */
export type BloccoServiziLinkInfoArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** [See type definition](https://app.contentful.com/spaces/v2h0bz1hqac3/content_types/bloccoServizi) */
export type BloccoServiziLinkPrenotaArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** [See type definition](https://app.contentful.com/spaces/v2h0bz1hqac3/content_types/bloccoServizi) */
export type BloccoServiziLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


/** [See type definition](https://app.contentful.com/spaces/v2h0bz1hqac3/content_types/bloccoServizi) */
export type BloccoServiziListinoArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};


/** [See type definition](https://app.contentful.com/spaces/v2h0bz1hqac3/content_types/bloccoServizi) */
export type BloccoServiziSottoTitoloArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** [See type definition](https://app.contentful.com/spaces/v2h0bz1hqac3/content_types/bloccoServizi) */
export type BloccoServiziTestoArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** [See type definition](https://app.contentful.com/spaces/v2h0bz1hqac3/content_types/bloccoServizi) */
export type BloccoServiziTestoPulsanteInfoArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** [See type definition](https://app.contentful.com/spaces/v2h0bz1hqac3/content_types/bloccoServizi) */
export type BloccoServiziTestoPulsantePrimarioArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** [See type definition](https://app.contentful.com/spaces/v2h0bz1hqac3/content_types/bloccoServizi) */
export type BloccoServiziTitoloArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

export type BloccoServiziCollection = {
  __typename?: 'BloccoServiziCollection';
  items: Array<Maybe<BloccoServizi>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type BloccoServiziFilter = {
  AND?: InputMaybe<Array<InputMaybe<BloccoServiziFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<BloccoServiziFilter>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  linkInfo?: InputMaybe<Scalars['String']['input']>;
  linkInfo_contains?: InputMaybe<Scalars['String']['input']>;
  linkInfo_exists?: InputMaybe<Scalars['Boolean']['input']>;
  linkInfo_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  linkInfo_not?: InputMaybe<Scalars['String']['input']>;
  linkInfo_not_contains?: InputMaybe<Scalars['String']['input']>;
  linkInfo_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  linkPrenota?: InputMaybe<Scalars['String']['input']>;
  linkPrenota_contains?: InputMaybe<Scalars['String']['input']>;
  linkPrenota_exists?: InputMaybe<Scalars['Boolean']['input']>;
  linkPrenota_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  linkPrenota_not?: InputMaybe<Scalars['String']['input']>;
  linkPrenota_not_contains?: InputMaybe<Scalars['String']['input']>;
  linkPrenota_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  listino_exists?: InputMaybe<Scalars['Boolean']['input']>;
  sottoTitolo?: InputMaybe<Scalars['String']['input']>;
  sottoTitolo_contains?: InputMaybe<Scalars['String']['input']>;
  sottoTitolo_exists?: InputMaybe<Scalars['Boolean']['input']>;
  sottoTitolo_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sottoTitolo_not?: InputMaybe<Scalars['String']['input']>;
  sottoTitolo_not_contains?: InputMaybe<Scalars['String']['input']>;
  sottoTitolo_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sys?: InputMaybe<SysFilter>;
  testo?: InputMaybe<Scalars['String']['input']>;
  testoPulsanteInfo?: InputMaybe<Scalars['String']['input']>;
  testoPulsanteInfo_contains?: InputMaybe<Scalars['String']['input']>;
  testoPulsanteInfo_exists?: InputMaybe<Scalars['Boolean']['input']>;
  testoPulsanteInfo_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  testoPulsanteInfo_not?: InputMaybe<Scalars['String']['input']>;
  testoPulsanteInfo_not_contains?: InputMaybe<Scalars['String']['input']>;
  testoPulsanteInfo_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  testoPulsantePrimario?: InputMaybe<Scalars['String']['input']>;
  testoPulsantePrimario_contains?: InputMaybe<Scalars['String']['input']>;
  testoPulsantePrimario_exists?: InputMaybe<Scalars['Boolean']['input']>;
  testoPulsantePrimario_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  testoPulsantePrimario_not?: InputMaybe<Scalars['String']['input']>;
  testoPulsantePrimario_not_contains?: InputMaybe<Scalars['String']['input']>;
  testoPulsantePrimario_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  testo_contains?: InputMaybe<Scalars['String']['input']>;
  testo_exists?: InputMaybe<Scalars['Boolean']['input']>;
  testo_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  testo_not?: InputMaybe<Scalars['String']['input']>;
  testo_not_contains?: InputMaybe<Scalars['String']['input']>;
  testo_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  titolo?: InputMaybe<Scalars['String']['input']>;
  titolo_contains?: InputMaybe<Scalars['String']['input']>;
  titolo_exists?: InputMaybe<Scalars['Boolean']['input']>;
  titolo_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  titolo_not?: InputMaybe<Scalars['String']['input']>;
  titolo_not_contains?: InputMaybe<Scalars['String']['input']>;
  titolo_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type BloccoServiziLinkingCollections = {
  __typename?: 'BloccoServiziLinkingCollections';
  entryCollection?: Maybe<EntryCollection>;
};


export type BloccoServiziLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export enum BloccoServiziOrder {
  LinkInfoAsc = 'linkInfo_ASC',
  LinkInfoDesc = 'linkInfo_DESC',
  LinkPrenotaAsc = 'linkPrenota_ASC',
  LinkPrenotaDesc = 'linkPrenota_DESC',
  SottoTitoloAsc = 'sottoTitolo_ASC',
  SottoTitoloDesc = 'sottoTitolo_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TestoPulsanteInfoAsc = 'testoPulsanteInfo_ASC',
  TestoPulsanteInfoDesc = 'testoPulsanteInfo_DESC',
  TestoPulsantePrimarioAsc = 'testoPulsantePrimario_ASC',
  TestoPulsantePrimarioDesc = 'testoPulsantePrimario_DESC',
  TitoloAsc = 'titolo_ASC',
  TitoloDesc = 'titolo_DESC'
}

/** [See type definition](https://app.contentful.com/spaces/v2h0bz1hqac3/content_types/camera) */
export type Camera = Entry & _Node & {
  __typename?: 'Camera';
  _id: Scalars['ID']['output'];
  caratteristiche?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  checkTimes?: Maybe<Scalars['String']['output']>;
  coloriDisponibili?: Maybe<Scalars['String']['output']>;
  contentfulMetadata: ContentfulMetadata;
  descrizione?: Maybe<CameraDescrizione>;
  foto?: Maybe<Asset>;
  linkedFrom?: Maybe<CameraLinkingCollections>;
  sottitolo?: Maybe<Scalars['String']['output']>;
  sys: Sys;
  titolo?: Maybe<Scalars['String']['output']>;
};


/** [See type definition](https://app.contentful.com/spaces/v2h0bz1hqac3/content_types/camera) */
export type CameraCaratteristicheArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** [See type definition](https://app.contentful.com/spaces/v2h0bz1hqac3/content_types/camera) */
export type CameraCheckTimesArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** [See type definition](https://app.contentful.com/spaces/v2h0bz1hqac3/content_types/camera) */
export type CameraColoriDisponibiliArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** [See type definition](https://app.contentful.com/spaces/v2h0bz1hqac3/content_types/camera) */
export type CameraDescrizioneArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** [See type definition](https://app.contentful.com/spaces/v2h0bz1hqac3/content_types/camera) */
export type CameraFotoArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};


/** [See type definition](https://app.contentful.com/spaces/v2h0bz1hqac3/content_types/camera) */
export type CameraLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


/** [See type definition](https://app.contentful.com/spaces/v2h0bz1hqac3/content_types/camera) */
export type CameraSottitoloArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** [See type definition](https://app.contentful.com/spaces/v2h0bz1hqac3/content_types/camera) */
export type CameraTitoloArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

export type CameraCollection = {
  __typename?: 'CameraCollection';
  items: Array<Maybe<Camera>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type CameraDescrizione = {
  __typename?: 'CameraDescrizione';
  json: Scalars['JSON']['output'];
  links: CameraDescrizioneLinks;
};

export type CameraDescrizioneAssets = {
  __typename?: 'CameraDescrizioneAssets';
  block: Array<Maybe<Asset>>;
  hyperlink: Array<Maybe<Asset>>;
};

export type CameraDescrizioneEntries = {
  __typename?: 'CameraDescrizioneEntries';
  block: Array<Maybe<Entry>>;
  hyperlink: Array<Maybe<Entry>>;
  inline: Array<Maybe<Entry>>;
};

export type CameraDescrizioneLinks = {
  __typename?: 'CameraDescrizioneLinks';
  assets: CameraDescrizioneAssets;
  entries: CameraDescrizioneEntries;
  resources: CameraDescrizioneResources;
};

export type CameraDescrizioneResources = {
  __typename?: 'CameraDescrizioneResources';
  block: Array<CameraDescrizioneResourcesBlock>;
  hyperlink: Array<CameraDescrizioneResourcesHyperlink>;
  inline: Array<CameraDescrizioneResourcesInline>;
};

export type CameraDescrizioneResourcesBlock = ResourceLink & {
  __typename?: 'CameraDescrizioneResourcesBlock';
  sys: ResourceSys;
};

export type CameraDescrizioneResourcesHyperlink = ResourceLink & {
  __typename?: 'CameraDescrizioneResourcesHyperlink';
  sys: ResourceSys;
};

export type CameraDescrizioneResourcesInline = ResourceLink & {
  __typename?: 'CameraDescrizioneResourcesInline';
  sys: ResourceSys;
};

export type CameraFilter = {
  AND?: InputMaybe<Array<InputMaybe<CameraFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<CameraFilter>>>;
  caratteristiche_contains_all?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  caratteristiche_contains_none?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  caratteristiche_contains_some?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  caratteristiche_exists?: InputMaybe<Scalars['Boolean']['input']>;
  checkTimes?: InputMaybe<Scalars['String']['input']>;
  checkTimes_contains?: InputMaybe<Scalars['String']['input']>;
  checkTimes_exists?: InputMaybe<Scalars['Boolean']['input']>;
  checkTimes_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  checkTimes_not?: InputMaybe<Scalars['String']['input']>;
  checkTimes_not_contains?: InputMaybe<Scalars['String']['input']>;
  checkTimes_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  coloriDisponibili?: InputMaybe<Scalars['String']['input']>;
  coloriDisponibili_contains?: InputMaybe<Scalars['String']['input']>;
  coloriDisponibili_exists?: InputMaybe<Scalars['Boolean']['input']>;
  coloriDisponibili_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  coloriDisponibili_not?: InputMaybe<Scalars['String']['input']>;
  coloriDisponibili_not_contains?: InputMaybe<Scalars['String']['input']>;
  coloriDisponibili_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  descrizione_contains?: InputMaybe<Scalars['String']['input']>;
  descrizione_exists?: InputMaybe<Scalars['Boolean']['input']>;
  descrizione_not_contains?: InputMaybe<Scalars['String']['input']>;
  foto_exists?: InputMaybe<Scalars['Boolean']['input']>;
  sottitolo?: InputMaybe<Scalars['String']['input']>;
  sottitolo_contains?: InputMaybe<Scalars['String']['input']>;
  sottitolo_exists?: InputMaybe<Scalars['Boolean']['input']>;
  sottitolo_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sottitolo_not?: InputMaybe<Scalars['String']['input']>;
  sottitolo_not_contains?: InputMaybe<Scalars['String']['input']>;
  sottitolo_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sys?: InputMaybe<SysFilter>;
  titolo?: InputMaybe<Scalars['String']['input']>;
  titolo_contains?: InputMaybe<Scalars['String']['input']>;
  titolo_exists?: InputMaybe<Scalars['Boolean']['input']>;
  titolo_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  titolo_not?: InputMaybe<Scalars['String']['input']>;
  titolo_not_contains?: InputMaybe<Scalars['String']['input']>;
  titolo_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type CameraLinkingCollections = {
  __typename?: 'CameraLinkingCollections';
  entryCollection?: Maybe<EntryCollection>;
};


export type CameraLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export enum CameraOrder {
  CheckTimesAsc = 'checkTimes_ASC',
  CheckTimesDesc = 'checkTimes_DESC',
  ColoriDisponibiliAsc = 'coloriDisponibili_ASC',
  ColoriDisponibiliDesc = 'coloriDisponibili_DESC',
  SottitoloAsc = 'sottitolo_ASC',
  SottitoloDesc = 'sottitolo_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitoloAsc = 'titolo_ASC',
  TitoloDesc = 'titolo_DESC'
}

/** [See type definition](https://app.contentful.com/spaces/v2h0bz1hqac3/content_types/campiPadel) */
export type CampiPadel = Entry & _Node & {
  __typename?: 'CampiPadel';
  _id: Scalars['ID']['output'];
  contentfulMetadata: ContentfulMetadata;
  descrizione?: Maybe<Scalars['String']['output']>;
  linkedFrom?: Maybe<CampiPadelLinkingCollections>;
  sys: Sys;
  titolo?: Maybe<Scalars['String']['output']>;
};


/** [See type definition](https://app.contentful.com/spaces/v2h0bz1hqac3/content_types/campiPadel) */
export type CampiPadelDescrizioneArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** [See type definition](https://app.contentful.com/spaces/v2h0bz1hqac3/content_types/campiPadel) */
export type CampiPadelLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


/** [See type definition](https://app.contentful.com/spaces/v2h0bz1hqac3/content_types/campiPadel) */
export type CampiPadelTitoloArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

export type CampiPadelCollection = {
  __typename?: 'CampiPadelCollection';
  items: Array<Maybe<CampiPadel>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type CampiPadelFilter = {
  AND?: InputMaybe<Array<InputMaybe<CampiPadelFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<CampiPadelFilter>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  descrizione?: InputMaybe<Scalars['String']['input']>;
  descrizione_contains?: InputMaybe<Scalars['String']['input']>;
  descrizione_exists?: InputMaybe<Scalars['Boolean']['input']>;
  descrizione_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descrizione_not?: InputMaybe<Scalars['String']['input']>;
  descrizione_not_contains?: InputMaybe<Scalars['String']['input']>;
  descrizione_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sys?: InputMaybe<SysFilter>;
  titolo?: InputMaybe<Scalars['String']['input']>;
  titolo_contains?: InputMaybe<Scalars['String']['input']>;
  titolo_exists?: InputMaybe<Scalars['Boolean']['input']>;
  titolo_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  titolo_not?: InputMaybe<Scalars['String']['input']>;
  titolo_not_contains?: InputMaybe<Scalars['String']['input']>;
  titolo_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type CampiPadelLinkingCollections = {
  __typename?: 'CampiPadelLinkingCollections';
  entryCollection?: Maybe<EntryCollection>;
};


export type CampiPadelLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export enum CampiPadelOrder {
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitoloAsc = 'titolo_ASC',
  TitoloDesc = 'titolo_DESC'
}

/** [See type definition](https://app.contentful.com/spaces/v2h0bz1hqac3/content_types/comoShowCase) */
export type ComoShowCase = Entry & _Node & {
  __typename?: 'ComoShowCase';
  _id: Scalars['ID']['output'];
  contentfulMetadata: ContentfulMetadata;
  linkedFrom?: Maybe<ComoShowCaseLinkingCollections>;
  sys: Sys;
  testo?: Maybe<ComoShowCaseTesto>;
  title?: Maybe<Scalars['String']['output']>;
};


/** [See type definition](https://app.contentful.com/spaces/v2h0bz1hqac3/content_types/comoShowCase) */
export type ComoShowCaseLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


/** [See type definition](https://app.contentful.com/spaces/v2h0bz1hqac3/content_types/comoShowCase) */
export type ComoShowCaseTestoArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** [See type definition](https://app.contentful.com/spaces/v2h0bz1hqac3/content_types/comoShowCase) */
export type ComoShowCaseTitleArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

export type ComoShowCaseCollection = {
  __typename?: 'ComoShowCaseCollection';
  items: Array<Maybe<ComoShowCase>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type ComoShowCaseFilter = {
  AND?: InputMaybe<Array<InputMaybe<ComoShowCaseFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<ComoShowCaseFilter>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  sys?: InputMaybe<SysFilter>;
  testo_contains?: InputMaybe<Scalars['String']['input']>;
  testo_exists?: InputMaybe<Scalars['Boolean']['input']>;
  testo_not_contains?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  title_contains?: InputMaybe<Scalars['String']['input']>;
  title_exists?: InputMaybe<Scalars['Boolean']['input']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title_not?: InputMaybe<Scalars['String']['input']>;
  title_not_contains?: InputMaybe<Scalars['String']['input']>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type ComoShowCaseLinkingCollections = {
  __typename?: 'ComoShowCaseLinkingCollections';
  entryCollection?: Maybe<EntryCollection>;
};


export type ComoShowCaseLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export enum ComoShowCaseOrder {
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC'
}

export type ComoShowCaseTesto = {
  __typename?: 'ComoShowCaseTesto';
  json: Scalars['JSON']['output'];
  links: ComoShowCaseTestoLinks;
};

export type ComoShowCaseTestoAssets = {
  __typename?: 'ComoShowCaseTestoAssets';
  block: Array<Maybe<Asset>>;
  hyperlink: Array<Maybe<Asset>>;
};

export type ComoShowCaseTestoEntries = {
  __typename?: 'ComoShowCaseTestoEntries';
  block: Array<Maybe<Entry>>;
  hyperlink: Array<Maybe<Entry>>;
  inline: Array<Maybe<Entry>>;
};

export type ComoShowCaseTestoLinks = {
  __typename?: 'ComoShowCaseTestoLinks';
  assets: ComoShowCaseTestoAssets;
  entries: ComoShowCaseTestoEntries;
  resources: ComoShowCaseTestoResources;
};

export type ComoShowCaseTestoResources = {
  __typename?: 'ComoShowCaseTestoResources';
  block: Array<ComoShowCaseTestoResourcesBlock>;
  hyperlink: Array<ComoShowCaseTestoResourcesHyperlink>;
  inline: Array<ComoShowCaseTestoResourcesInline>;
};

export type ComoShowCaseTestoResourcesBlock = ResourceLink & {
  __typename?: 'ComoShowCaseTestoResourcesBlock';
  sys: ResourceSys;
};

export type ComoShowCaseTestoResourcesHyperlink = ResourceLink & {
  __typename?: 'ComoShowCaseTestoResourcesHyperlink';
  sys: ResourceSys;
};

export type ComoShowCaseTestoResourcesInline = ResourceLink & {
  __typename?: 'ComoShowCaseTestoResourcesInline';
  sys: ResourceSys;
};

/** [See type definition](https://app.contentful.com/spaces/v2h0bz1hqac3/content_types/contatti) */
export type Contatti = Entry & _Node & {
  __typename?: 'Contatti';
  _id: Scalars['ID']['output'];
  contentfulMetadata: ContentfulMetadata;
  datiAzienda?: Maybe<ContattiDatiAzienda>;
  gdpr?: Maybe<Scalars['String']['output']>;
  iTigliDettagli?: Maybe<Scalars['String']['output']>;
  iscrivitiAlClub?: Maybe<Scalars['String']['output']>;
  linkedFrom?: Maybe<ContattiLinkingCollections>;
  padelArenaDettagli?: Maybe<Scalars['String']['output']>;
  piscinaDettagli?: Maybe<Scalars['String']['output']>;
  serviziDettagli?: Maybe<Scalars['String']['output']>;
  sys: Sys;
  testoAccettoPrivacy?: Maybe<Scalars['String']['output']>;
  testoContattaci?: Maybe<Scalars['String']['output']>;
  testoIscriviti?: Maybe<Scalars['String']['output']>;
};


/** [See type definition](https://app.contentful.com/spaces/v2h0bz1hqac3/content_types/contatti) */
export type ContattiDatiAziendaArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** [See type definition](https://app.contentful.com/spaces/v2h0bz1hqac3/content_types/contatti) */
export type ContattiGdprArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** [See type definition](https://app.contentful.com/spaces/v2h0bz1hqac3/content_types/contatti) */
export type ContattiITigliDettagliArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** [See type definition](https://app.contentful.com/spaces/v2h0bz1hqac3/content_types/contatti) */
export type ContattiIscrivitiAlClubArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** [See type definition](https://app.contentful.com/spaces/v2h0bz1hqac3/content_types/contatti) */
export type ContattiLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


/** [See type definition](https://app.contentful.com/spaces/v2h0bz1hqac3/content_types/contatti) */
export type ContattiPadelArenaDettagliArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** [See type definition](https://app.contentful.com/spaces/v2h0bz1hqac3/content_types/contatti) */
export type ContattiPiscinaDettagliArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** [See type definition](https://app.contentful.com/spaces/v2h0bz1hqac3/content_types/contatti) */
export type ContattiServiziDettagliArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** [See type definition](https://app.contentful.com/spaces/v2h0bz1hqac3/content_types/contatti) */
export type ContattiTestoAccettoPrivacyArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** [See type definition](https://app.contentful.com/spaces/v2h0bz1hqac3/content_types/contatti) */
export type ContattiTestoContattaciArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** [See type definition](https://app.contentful.com/spaces/v2h0bz1hqac3/content_types/contatti) */
export type ContattiTestoIscrivitiArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

export type ContattiCollection = {
  __typename?: 'ContattiCollection';
  items: Array<Maybe<Contatti>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type ContattiDatiAzienda = {
  __typename?: 'ContattiDatiAzienda';
  json: Scalars['JSON']['output'];
  links: ContattiDatiAziendaLinks;
};

export type ContattiDatiAziendaAssets = {
  __typename?: 'ContattiDatiAziendaAssets';
  block: Array<Maybe<Asset>>;
  hyperlink: Array<Maybe<Asset>>;
};

export type ContattiDatiAziendaEntries = {
  __typename?: 'ContattiDatiAziendaEntries';
  block: Array<Maybe<Entry>>;
  hyperlink: Array<Maybe<Entry>>;
  inline: Array<Maybe<Entry>>;
};

export type ContattiDatiAziendaLinks = {
  __typename?: 'ContattiDatiAziendaLinks';
  assets: ContattiDatiAziendaAssets;
  entries: ContattiDatiAziendaEntries;
  resources: ContattiDatiAziendaResources;
};

export type ContattiDatiAziendaResources = {
  __typename?: 'ContattiDatiAziendaResources';
  block: Array<ContattiDatiAziendaResourcesBlock>;
  hyperlink: Array<ContattiDatiAziendaResourcesHyperlink>;
  inline: Array<ContattiDatiAziendaResourcesInline>;
};

export type ContattiDatiAziendaResourcesBlock = ResourceLink & {
  __typename?: 'ContattiDatiAziendaResourcesBlock';
  sys: ResourceSys;
};

export type ContattiDatiAziendaResourcesHyperlink = ResourceLink & {
  __typename?: 'ContattiDatiAziendaResourcesHyperlink';
  sys: ResourceSys;
};

export type ContattiDatiAziendaResourcesInline = ResourceLink & {
  __typename?: 'ContattiDatiAziendaResourcesInline';
  sys: ResourceSys;
};

export type ContattiFilter = {
  AND?: InputMaybe<Array<InputMaybe<ContattiFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<ContattiFilter>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  datiAzienda_contains?: InputMaybe<Scalars['String']['input']>;
  datiAzienda_exists?: InputMaybe<Scalars['Boolean']['input']>;
  datiAzienda_not_contains?: InputMaybe<Scalars['String']['input']>;
  gdpr?: InputMaybe<Scalars['String']['input']>;
  gdpr_contains?: InputMaybe<Scalars['String']['input']>;
  gdpr_exists?: InputMaybe<Scalars['Boolean']['input']>;
  gdpr_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  gdpr_not?: InputMaybe<Scalars['String']['input']>;
  gdpr_not_contains?: InputMaybe<Scalars['String']['input']>;
  gdpr_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  iTigliDettagli?: InputMaybe<Scalars['String']['input']>;
  iTigliDettagli_contains?: InputMaybe<Scalars['String']['input']>;
  iTigliDettagli_exists?: InputMaybe<Scalars['Boolean']['input']>;
  iTigliDettagli_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  iTigliDettagli_not?: InputMaybe<Scalars['String']['input']>;
  iTigliDettagli_not_contains?: InputMaybe<Scalars['String']['input']>;
  iTigliDettagli_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  iscrivitiAlClub?: InputMaybe<Scalars['String']['input']>;
  iscrivitiAlClub_contains?: InputMaybe<Scalars['String']['input']>;
  iscrivitiAlClub_exists?: InputMaybe<Scalars['Boolean']['input']>;
  iscrivitiAlClub_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  iscrivitiAlClub_not?: InputMaybe<Scalars['String']['input']>;
  iscrivitiAlClub_not_contains?: InputMaybe<Scalars['String']['input']>;
  iscrivitiAlClub_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  padelArenaDettagli?: InputMaybe<Scalars['String']['input']>;
  padelArenaDettagli_contains?: InputMaybe<Scalars['String']['input']>;
  padelArenaDettagli_exists?: InputMaybe<Scalars['Boolean']['input']>;
  padelArenaDettagli_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  padelArenaDettagli_not?: InputMaybe<Scalars['String']['input']>;
  padelArenaDettagli_not_contains?: InputMaybe<Scalars['String']['input']>;
  padelArenaDettagli_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  piscinaDettagli?: InputMaybe<Scalars['String']['input']>;
  piscinaDettagli_contains?: InputMaybe<Scalars['String']['input']>;
  piscinaDettagli_exists?: InputMaybe<Scalars['Boolean']['input']>;
  piscinaDettagli_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  piscinaDettagli_not?: InputMaybe<Scalars['String']['input']>;
  piscinaDettagli_not_contains?: InputMaybe<Scalars['String']['input']>;
  piscinaDettagli_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  serviziDettagli?: InputMaybe<Scalars['String']['input']>;
  serviziDettagli_contains?: InputMaybe<Scalars['String']['input']>;
  serviziDettagli_exists?: InputMaybe<Scalars['Boolean']['input']>;
  serviziDettagli_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  serviziDettagli_not?: InputMaybe<Scalars['String']['input']>;
  serviziDettagli_not_contains?: InputMaybe<Scalars['String']['input']>;
  serviziDettagli_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sys?: InputMaybe<SysFilter>;
  testoAccettoPrivacy?: InputMaybe<Scalars['String']['input']>;
  testoAccettoPrivacy_contains?: InputMaybe<Scalars['String']['input']>;
  testoAccettoPrivacy_exists?: InputMaybe<Scalars['Boolean']['input']>;
  testoAccettoPrivacy_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  testoAccettoPrivacy_not?: InputMaybe<Scalars['String']['input']>;
  testoAccettoPrivacy_not_contains?: InputMaybe<Scalars['String']['input']>;
  testoAccettoPrivacy_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  testoContattaci?: InputMaybe<Scalars['String']['input']>;
  testoContattaci_contains?: InputMaybe<Scalars['String']['input']>;
  testoContattaci_exists?: InputMaybe<Scalars['Boolean']['input']>;
  testoContattaci_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  testoContattaci_not?: InputMaybe<Scalars['String']['input']>;
  testoContattaci_not_contains?: InputMaybe<Scalars['String']['input']>;
  testoContattaci_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  testoIscriviti?: InputMaybe<Scalars['String']['input']>;
  testoIscriviti_contains?: InputMaybe<Scalars['String']['input']>;
  testoIscriviti_exists?: InputMaybe<Scalars['Boolean']['input']>;
  testoIscriviti_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  testoIscriviti_not?: InputMaybe<Scalars['String']['input']>;
  testoIscriviti_not_contains?: InputMaybe<Scalars['String']['input']>;
  testoIscriviti_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type ContattiLinkingCollections = {
  __typename?: 'ContattiLinkingCollections';
  entryCollection?: Maybe<EntryCollection>;
};


export type ContattiLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export enum ContattiOrder {
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TestoAccettoPrivacyAsc = 'testoAccettoPrivacy_ASC',
  TestoAccettoPrivacyDesc = 'testoAccettoPrivacy_DESC',
  TestoContattaciAsc = 'testoContattaci_ASC',
  TestoContattaciDesc = 'testoContattaci_DESC'
}

export type ContentfulMetadata = {
  __typename?: 'ContentfulMetadata';
  tags: Array<Maybe<ContentfulTag>>;
};

export type ContentfulMetadataFilter = {
  tags?: InputMaybe<ContentfulMetadataTagsFilter>;
  tags_exists?: InputMaybe<Scalars['Boolean']['input']>;
};

export type ContentfulMetadataTagsFilter = {
  id_contains_all?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  id_contains_none?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  id_contains_some?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

/**
 * Represents a tag entity for finding and organizing content easily.
 *       Find out more here: https://www.contentful.com/developers/docs/references/content-delivery-api/#/reference/content-tags
 */
export type ContentfulTag = {
  __typename?: 'ContentfulTag';
  id?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

/** Descrizione appena sotto la prima foto [See type definition](https://app.contentful.com/spaces/v2h0bz1hqac3/content_types/descrizionePrincipale) */
export type DescrizionePrincipale = Entry & _Node & {
  __typename?: 'DescrizionePrincipale';
  _id: Scalars['ID']['output'];
  contentfulMetadata: ContentfulMetadata;
  descrizione?: Maybe<DescrizionePrincipaleDescrizione>;
  linkedFrom?: Maybe<DescrizionePrincipaleLinkingCollections>;
  sys: Sys;
  titolo?: Maybe<Scalars['String']['output']>;
};


/** Descrizione appena sotto la prima foto [See type definition](https://app.contentful.com/spaces/v2h0bz1hqac3/content_types/descrizionePrincipale) */
export type DescrizionePrincipaleDescrizioneArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** Descrizione appena sotto la prima foto [See type definition](https://app.contentful.com/spaces/v2h0bz1hqac3/content_types/descrizionePrincipale) */
export type DescrizionePrincipaleLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


/** Descrizione appena sotto la prima foto [See type definition](https://app.contentful.com/spaces/v2h0bz1hqac3/content_types/descrizionePrincipale) */
export type DescrizionePrincipaleTitoloArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

export type DescrizionePrincipaleCollection = {
  __typename?: 'DescrizionePrincipaleCollection';
  items: Array<Maybe<DescrizionePrincipale>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type DescrizionePrincipaleDescrizione = {
  __typename?: 'DescrizionePrincipaleDescrizione';
  json: Scalars['JSON']['output'];
  links: DescrizionePrincipaleDescrizioneLinks;
};

export type DescrizionePrincipaleDescrizioneAssets = {
  __typename?: 'DescrizionePrincipaleDescrizioneAssets';
  block: Array<Maybe<Asset>>;
  hyperlink: Array<Maybe<Asset>>;
};

export type DescrizionePrincipaleDescrizioneEntries = {
  __typename?: 'DescrizionePrincipaleDescrizioneEntries';
  block: Array<Maybe<Entry>>;
  hyperlink: Array<Maybe<Entry>>;
  inline: Array<Maybe<Entry>>;
};

export type DescrizionePrincipaleDescrizioneLinks = {
  __typename?: 'DescrizionePrincipaleDescrizioneLinks';
  assets: DescrizionePrincipaleDescrizioneAssets;
  entries: DescrizionePrincipaleDescrizioneEntries;
  resources: DescrizionePrincipaleDescrizioneResources;
};

export type DescrizionePrincipaleDescrizioneResources = {
  __typename?: 'DescrizionePrincipaleDescrizioneResources';
  block: Array<DescrizionePrincipaleDescrizioneResourcesBlock>;
  hyperlink: Array<DescrizionePrincipaleDescrizioneResourcesHyperlink>;
  inline: Array<DescrizionePrincipaleDescrizioneResourcesInline>;
};

export type DescrizionePrincipaleDescrizioneResourcesBlock = ResourceLink & {
  __typename?: 'DescrizionePrincipaleDescrizioneResourcesBlock';
  sys: ResourceSys;
};

export type DescrizionePrincipaleDescrizioneResourcesHyperlink = ResourceLink & {
  __typename?: 'DescrizionePrincipaleDescrizioneResourcesHyperlink';
  sys: ResourceSys;
};

export type DescrizionePrincipaleDescrizioneResourcesInline = ResourceLink & {
  __typename?: 'DescrizionePrincipaleDescrizioneResourcesInline';
  sys: ResourceSys;
};

export type DescrizionePrincipaleFilter = {
  AND?: InputMaybe<Array<InputMaybe<DescrizionePrincipaleFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<DescrizionePrincipaleFilter>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  descrizione_contains?: InputMaybe<Scalars['String']['input']>;
  descrizione_exists?: InputMaybe<Scalars['Boolean']['input']>;
  descrizione_not_contains?: InputMaybe<Scalars['String']['input']>;
  sys?: InputMaybe<SysFilter>;
  titolo?: InputMaybe<Scalars['String']['input']>;
  titolo_contains?: InputMaybe<Scalars['String']['input']>;
  titolo_exists?: InputMaybe<Scalars['Boolean']['input']>;
  titolo_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  titolo_not?: InputMaybe<Scalars['String']['input']>;
  titolo_not_contains?: InputMaybe<Scalars['String']['input']>;
  titolo_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type DescrizionePrincipaleLinkingCollections = {
  __typename?: 'DescrizionePrincipaleLinkingCollections';
  entryCollection?: Maybe<EntryCollection>;
};


export type DescrizionePrincipaleLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export enum DescrizionePrincipaleOrder {
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitoloAsc = 'titolo_ASC',
  TitoloDesc = 'titolo_DESC'
}

export type Entry = {
  contentfulMetadata: ContentfulMetadata;
  sys: Sys;
};

export type EntryCollection = {
  __typename?: 'EntryCollection';
  items: Array<Maybe<Entry>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type EntryFilter = {
  AND?: InputMaybe<Array<InputMaybe<EntryFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<EntryFilter>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  sys?: InputMaybe<SysFilter>;
};

export enum EntryOrder {
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

/** [See type definition](https://app.contentful.com/spaces/v2h0bz1hqac3/content_types/eventoPiscina) */
export type EventoPiscina = Entry & _Node & {
  __typename?: 'EventoPiscina';
  _id: Scalars['ID']['output'];
  contentfulMetadata: ContentfulMetadata;
  data?: Maybe<Scalars['DateTime']['output']>;
  descrizione?: Maybe<EventoPiscinaDescrizione>;
  foto?: Maybe<Asset>;
  linkedFrom?: Maybe<EventoPiscinaLinkingCollections>;
  prenotaLInk?: Maybe<Scalars['String']['output']>;
  sys: Sys;
  titolo?: Maybe<Scalars['String']['output']>;
};


/** [See type definition](https://app.contentful.com/spaces/v2h0bz1hqac3/content_types/eventoPiscina) */
export type EventoPiscinaDataArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** [See type definition](https://app.contentful.com/spaces/v2h0bz1hqac3/content_types/eventoPiscina) */
export type EventoPiscinaDescrizioneArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** [See type definition](https://app.contentful.com/spaces/v2h0bz1hqac3/content_types/eventoPiscina) */
export type EventoPiscinaFotoArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};


/** [See type definition](https://app.contentful.com/spaces/v2h0bz1hqac3/content_types/eventoPiscina) */
export type EventoPiscinaLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


/** [See type definition](https://app.contentful.com/spaces/v2h0bz1hqac3/content_types/eventoPiscina) */
export type EventoPiscinaPrenotaLInkArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** [See type definition](https://app.contentful.com/spaces/v2h0bz1hqac3/content_types/eventoPiscina) */
export type EventoPiscinaTitoloArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

export type EventoPiscinaCollection = {
  __typename?: 'EventoPiscinaCollection';
  items: Array<Maybe<EventoPiscina>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type EventoPiscinaDescrizione = {
  __typename?: 'EventoPiscinaDescrizione';
  json: Scalars['JSON']['output'];
  links: EventoPiscinaDescrizioneLinks;
};

export type EventoPiscinaDescrizioneAssets = {
  __typename?: 'EventoPiscinaDescrizioneAssets';
  block: Array<Maybe<Asset>>;
  hyperlink: Array<Maybe<Asset>>;
};

export type EventoPiscinaDescrizioneEntries = {
  __typename?: 'EventoPiscinaDescrizioneEntries';
  block: Array<Maybe<Entry>>;
  hyperlink: Array<Maybe<Entry>>;
  inline: Array<Maybe<Entry>>;
};

export type EventoPiscinaDescrizioneLinks = {
  __typename?: 'EventoPiscinaDescrizioneLinks';
  assets: EventoPiscinaDescrizioneAssets;
  entries: EventoPiscinaDescrizioneEntries;
  resources: EventoPiscinaDescrizioneResources;
};

export type EventoPiscinaDescrizioneResources = {
  __typename?: 'EventoPiscinaDescrizioneResources';
  block: Array<EventoPiscinaDescrizioneResourcesBlock>;
  hyperlink: Array<EventoPiscinaDescrizioneResourcesHyperlink>;
  inline: Array<EventoPiscinaDescrizioneResourcesInline>;
};

export type EventoPiscinaDescrizioneResourcesBlock = ResourceLink & {
  __typename?: 'EventoPiscinaDescrizioneResourcesBlock';
  sys: ResourceSys;
};

export type EventoPiscinaDescrizioneResourcesHyperlink = ResourceLink & {
  __typename?: 'EventoPiscinaDescrizioneResourcesHyperlink';
  sys: ResourceSys;
};

export type EventoPiscinaDescrizioneResourcesInline = ResourceLink & {
  __typename?: 'EventoPiscinaDescrizioneResourcesInline';
  sys: ResourceSys;
};

export type EventoPiscinaFilter = {
  AND?: InputMaybe<Array<InputMaybe<EventoPiscinaFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<EventoPiscinaFilter>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  data?: InputMaybe<Scalars['DateTime']['input']>;
  data_exists?: InputMaybe<Scalars['Boolean']['input']>;
  data_gt?: InputMaybe<Scalars['DateTime']['input']>;
  data_gte?: InputMaybe<Scalars['DateTime']['input']>;
  data_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  data_lt?: InputMaybe<Scalars['DateTime']['input']>;
  data_lte?: InputMaybe<Scalars['DateTime']['input']>;
  data_not?: InputMaybe<Scalars['DateTime']['input']>;
  data_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  descrizione_contains?: InputMaybe<Scalars['String']['input']>;
  descrizione_exists?: InputMaybe<Scalars['Boolean']['input']>;
  descrizione_not_contains?: InputMaybe<Scalars['String']['input']>;
  foto_exists?: InputMaybe<Scalars['Boolean']['input']>;
  prenotaLInk?: InputMaybe<Scalars['String']['input']>;
  prenotaLInk_contains?: InputMaybe<Scalars['String']['input']>;
  prenotaLInk_exists?: InputMaybe<Scalars['Boolean']['input']>;
  prenotaLInk_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  prenotaLInk_not?: InputMaybe<Scalars['String']['input']>;
  prenotaLInk_not_contains?: InputMaybe<Scalars['String']['input']>;
  prenotaLInk_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sys?: InputMaybe<SysFilter>;
  titolo?: InputMaybe<Scalars['String']['input']>;
  titolo_contains?: InputMaybe<Scalars['String']['input']>;
  titolo_exists?: InputMaybe<Scalars['Boolean']['input']>;
  titolo_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  titolo_not?: InputMaybe<Scalars['String']['input']>;
  titolo_not_contains?: InputMaybe<Scalars['String']['input']>;
  titolo_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type EventoPiscinaLinkingCollections = {
  __typename?: 'EventoPiscinaLinkingCollections';
  entryCollection?: Maybe<EntryCollection>;
};


export type EventoPiscinaLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export enum EventoPiscinaOrder {
  DataAsc = 'data_ASC',
  DataDesc = 'data_DESC',
  PrenotaLInkAsc = 'prenotaLInk_ASC',
  PrenotaLInkDesc = 'prenotaLInk_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitoloAsc = 'titolo_ASC',
  TitoloDesc = 'titolo_DESC'
}

/** [See type definition](https://app.contentful.com/spaces/v2h0bz1hqac3/content_types/expirience) */
export type Expirience = Entry & _Node & {
  __typename?: 'Expirience';
  _id: Scalars['ID']['output'];
  contentfulMetadata: ContentfulMetadata;
  descrizione?: Maybe<ExpirienceDescrizione>;
  linkedFrom?: Maybe<ExpirienceLinkingCollections>;
  prezzo?: Maybe<Scalars['String']['output']>;
  sottotitolo?: Maybe<Scalars['String']['output']>;
  sys: Sys;
  titolo?: Maybe<Scalars['String']['output']>;
};


/** [See type definition](https://app.contentful.com/spaces/v2h0bz1hqac3/content_types/expirience) */
export type ExpirienceDescrizioneArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** [See type definition](https://app.contentful.com/spaces/v2h0bz1hqac3/content_types/expirience) */
export type ExpirienceLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


/** [See type definition](https://app.contentful.com/spaces/v2h0bz1hqac3/content_types/expirience) */
export type ExpiriencePrezzoArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** [See type definition](https://app.contentful.com/spaces/v2h0bz1hqac3/content_types/expirience) */
export type ExpirienceSottotitoloArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** [See type definition](https://app.contentful.com/spaces/v2h0bz1hqac3/content_types/expirience) */
export type ExpirienceTitoloArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

export type ExpirienceCollection = {
  __typename?: 'ExpirienceCollection';
  items: Array<Maybe<Expirience>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type ExpirienceDescrizione = {
  __typename?: 'ExpirienceDescrizione';
  json: Scalars['JSON']['output'];
  links: ExpirienceDescrizioneLinks;
};

export type ExpirienceDescrizioneAssets = {
  __typename?: 'ExpirienceDescrizioneAssets';
  block: Array<Maybe<Asset>>;
  hyperlink: Array<Maybe<Asset>>;
};

export type ExpirienceDescrizioneEntries = {
  __typename?: 'ExpirienceDescrizioneEntries';
  block: Array<Maybe<Entry>>;
  hyperlink: Array<Maybe<Entry>>;
  inline: Array<Maybe<Entry>>;
};

export type ExpirienceDescrizioneLinks = {
  __typename?: 'ExpirienceDescrizioneLinks';
  assets: ExpirienceDescrizioneAssets;
  entries: ExpirienceDescrizioneEntries;
  resources: ExpirienceDescrizioneResources;
};

export type ExpirienceDescrizioneResources = {
  __typename?: 'ExpirienceDescrizioneResources';
  block: Array<ExpirienceDescrizioneResourcesBlock>;
  hyperlink: Array<ExpirienceDescrizioneResourcesHyperlink>;
  inline: Array<ExpirienceDescrizioneResourcesInline>;
};

export type ExpirienceDescrizioneResourcesBlock = ResourceLink & {
  __typename?: 'ExpirienceDescrizioneResourcesBlock';
  sys: ResourceSys;
};

export type ExpirienceDescrizioneResourcesHyperlink = ResourceLink & {
  __typename?: 'ExpirienceDescrizioneResourcesHyperlink';
  sys: ResourceSys;
};

export type ExpirienceDescrizioneResourcesInline = ResourceLink & {
  __typename?: 'ExpirienceDescrizioneResourcesInline';
  sys: ResourceSys;
};

export type ExpirienceFilter = {
  AND?: InputMaybe<Array<InputMaybe<ExpirienceFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<ExpirienceFilter>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  descrizione_contains?: InputMaybe<Scalars['String']['input']>;
  descrizione_exists?: InputMaybe<Scalars['Boolean']['input']>;
  descrizione_not_contains?: InputMaybe<Scalars['String']['input']>;
  prezzo?: InputMaybe<Scalars['String']['input']>;
  prezzo_contains?: InputMaybe<Scalars['String']['input']>;
  prezzo_exists?: InputMaybe<Scalars['Boolean']['input']>;
  prezzo_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  prezzo_not?: InputMaybe<Scalars['String']['input']>;
  prezzo_not_contains?: InputMaybe<Scalars['String']['input']>;
  prezzo_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sottotitolo?: InputMaybe<Scalars['String']['input']>;
  sottotitolo_contains?: InputMaybe<Scalars['String']['input']>;
  sottotitolo_exists?: InputMaybe<Scalars['Boolean']['input']>;
  sottotitolo_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sottotitolo_not?: InputMaybe<Scalars['String']['input']>;
  sottotitolo_not_contains?: InputMaybe<Scalars['String']['input']>;
  sottotitolo_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sys?: InputMaybe<SysFilter>;
  titolo?: InputMaybe<Scalars['String']['input']>;
  titolo_contains?: InputMaybe<Scalars['String']['input']>;
  titolo_exists?: InputMaybe<Scalars['Boolean']['input']>;
  titolo_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  titolo_not?: InputMaybe<Scalars['String']['input']>;
  titolo_not_contains?: InputMaybe<Scalars['String']['input']>;
  titolo_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type ExpirienceLinkingCollections = {
  __typename?: 'ExpirienceLinkingCollections';
  entryCollection?: Maybe<EntryCollection>;
};


export type ExpirienceLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export enum ExpirienceOrder {
  PrezzoAsc = 'prezzo_ASC',
  PrezzoDesc = 'prezzo_DESC',
  SottotitoloAsc = 'sottotitolo_ASC',
  SottotitoloDesc = 'sottotitolo_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitoloAsc = 'titolo_ASC',
  TitoloDesc = 'titolo_DESC'
}

/** [See type definition](https://app.contentful.com/spaces/v2h0bz1hqac3/content_types/girella) */
export type Girella = Entry & _Node & {
  __typename?: 'Girella';
  _id: Scalars['ID']['output'];
  concessionaria?: Maybe<GirellaConcessionaria>;
  contentfulMetadata: ContentfulMetadata;
  hotel?: Maybe<GirellaHotel>;
  linkedFrom?: Maybe<GirellaLinkingCollections>;
  padel?: Maybe<GirellaPadel>;
  ristorante?: Maybe<GirellaRistorante>;
  sys: Sys;
};


/** [See type definition](https://app.contentful.com/spaces/v2h0bz1hqac3/content_types/girella) */
export type GirellaConcessionariaArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** [See type definition](https://app.contentful.com/spaces/v2h0bz1hqac3/content_types/girella) */
export type GirellaHotelArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** [See type definition](https://app.contentful.com/spaces/v2h0bz1hqac3/content_types/girella) */
export type GirellaLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


/** [See type definition](https://app.contentful.com/spaces/v2h0bz1hqac3/content_types/girella) */
export type GirellaPadelArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** [See type definition](https://app.contentful.com/spaces/v2h0bz1hqac3/content_types/girella) */
export type GirellaRistoranteArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

export type GirellaCollection = {
  __typename?: 'GirellaCollection';
  items: Array<Maybe<Girella>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type GirellaConcessionaria = {
  __typename?: 'GirellaConcessionaria';
  json: Scalars['JSON']['output'];
  links: GirellaConcessionariaLinks;
};

export type GirellaConcessionariaAssets = {
  __typename?: 'GirellaConcessionariaAssets';
  block: Array<Maybe<Asset>>;
  hyperlink: Array<Maybe<Asset>>;
};

export type GirellaConcessionariaEntries = {
  __typename?: 'GirellaConcessionariaEntries';
  block: Array<Maybe<Entry>>;
  hyperlink: Array<Maybe<Entry>>;
  inline: Array<Maybe<Entry>>;
};

export type GirellaConcessionariaLinks = {
  __typename?: 'GirellaConcessionariaLinks';
  assets: GirellaConcessionariaAssets;
  entries: GirellaConcessionariaEntries;
  resources: GirellaConcessionariaResources;
};

export type GirellaConcessionariaResources = {
  __typename?: 'GirellaConcessionariaResources';
  block: Array<GirellaConcessionariaResourcesBlock>;
  hyperlink: Array<GirellaConcessionariaResourcesHyperlink>;
  inline: Array<GirellaConcessionariaResourcesInline>;
};

export type GirellaConcessionariaResourcesBlock = ResourceLink & {
  __typename?: 'GirellaConcessionariaResourcesBlock';
  sys: ResourceSys;
};

export type GirellaConcessionariaResourcesHyperlink = ResourceLink & {
  __typename?: 'GirellaConcessionariaResourcesHyperlink';
  sys: ResourceSys;
};

export type GirellaConcessionariaResourcesInline = ResourceLink & {
  __typename?: 'GirellaConcessionariaResourcesInline';
  sys: ResourceSys;
};

export type GirellaFilter = {
  AND?: InputMaybe<Array<InputMaybe<GirellaFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<GirellaFilter>>>;
  concessionaria_contains?: InputMaybe<Scalars['String']['input']>;
  concessionaria_exists?: InputMaybe<Scalars['Boolean']['input']>;
  concessionaria_not_contains?: InputMaybe<Scalars['String']['input']>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  hotel_contains?: InputMaybe<Scalars['String']['input']>;
  hotel_exists?: InputMaybe<Scalars['Boolean']['input']>;
  hotel_not_contains?: InputMaybe<Scalars['String']['input']>;
  padel_contains?: InputMaybe<Scalars['String']['input']>;
  padel_exists?: InputMaybe<Scalars['Boolean']['input']>;
  padel_not_contains?: InputMaybe<Scalars['String']['input']>;
  ristorante_contains?: InputMaybe<Scalars['String']['input']>;
  ristorante_exists?: InputMaybe<Scalars['Boolean']['input']>;
  ristorante_not_contains?: InputMaybe<Scalars['String']['input']>;
  sys?: InputMaybe<SysFilter>;
};

export type GirellaHotel = {
  __typename?: 'GirellaHotel';
  json: Scalars['JSON']['output'];
  links: GirellaHotelLinks;
};

export type GirellaHotelAssets = {
  __typename?: 'GirellaHotelAssets';
  block: Array<Maybe<Asset>>;
  hyperlink: Array<Maybe<Asset>>;
};

export type GirellaHotelEntries = {
  __typename?: 'GirellaHotelEntries';
  block: Array<Maybe<Entry>>;
  hyperlink: Array<Maybe<Entry>>;
  inline: Array<Maybe<Entry>>;
};

export type GirellaHotelLinks = {
  __typename?: 'GirellaHotelLinks';
  assets: GirellaHotelAssets;
  entries: GirellaHotelEntries;
  resources: GirellaHotelResources;
};

export type GirellaHotelResources = {
  __typename?: 'GirellaHotelResources';
  block: Array<GirellaHotelResourcesBlock>;
  hyperlink: Array<GirellaHotelResourcesHyperlink>;
  inline: Array<GirellaHotelResourcesInline>;
};

export type GirellaHotelResourcesBlock = ResourceLink & {
  __typename?: 'GirellaHotelResourcesBlock';
  sys: ResourceSys;
};

export type GirellaHotelResourcesHyperlink = ResourceLink & {
  __typename?: 'GirellaHotelResourcesHyperlink';
  sys: ResourceSys;
};

export type GirellaHotelResourcesInline = ResourceLink & {
  __typename?: 'GirellaHotelResourcesInline';
  sys: ResourceSys;
};

export type GirellaLinkingCollections = {
  __typename?: 'GirellaLinkingCollections';
  entryCollection?: Maybe<EntryCollection>;
};


export type GirellaLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export enum GirellaOrder {
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

export type GirellaPadel = {
  __typename?: 'GirellaPadel';
  json: Scalars['JSON']['output'];
  links: GirellaPadelLinks;
};

export type GirellaPadelAssets = {
  __typename?: 'GirellaPadelAssets';
  block: Array<Maybe<Asset>>;
  hyperlink: Array<Maybe<Asset>>;
};

export type GirellaPadelEntries = {
  __typename?: 'GirellaPadelEntries';
  block: Array<Maybe<Entry>>;
  hyperlink: Array<Maybe<Entry>>;
  inline: Array<Maybe<Entry>>;
};

export type GirellaPadelLinks = {
  __typename?: 'GirellaPadelLinks';
  assets: GirellaPadelAssets;
  entries: GirellaPadelEntries;
  resources: GirellaPadelResources;
};

export type GirellaPadelResources = {
  __typename?: 'GirellaPadelResources';
  block: Array<GirellaPadelResourcesBlock>;
  hyperlink: Array<GirellaPadelResourcesHyperlink>;
  inline: Array<GirellaPadelResourcesInline>;
};

export type GirellaPadelResourcesBlock = ResourceLink & {
  __typename?: 'GirellaPadelResourcesBlock';
  sys: ResourceSys;
};

export type GirellaPadelResourcesHyperlink = ResourceLink & {
  __typename?: 'GirellaPadelResourcesHyperlink';
  sys: ResourceSys;
};

export type GirellaPadelResourcesInline = ResourceLink & {
  __typename?: 'GirellaPadelResourcesInline';
  sys: ResourceSys;
};

export type GirellaRistorante = {
  __typename?: 'GirellaRistorante';
  json: Scalars['JSON']['output'];
  links: GirellaRistoranteLinks;
};

export type GirellaRistoranteAssets = {
  __typename?: 'GirellaRistoranteAssets';
  block: Array<Maybe<Asset>>;
  hyperlink: Array<Maybe<Asset>>;
};

export type GirellaRistoranteEntries = {
  __typename?: 'GirellaRistoranteEntries';
  block: Array<Maybe<Entry>>;
  hyperlink: Array<Maybe<Entry>>;
  inline: Array<Maybe<Entry>>;
};

export type GirellaRistoranteLinks = {
  __typename?: 'GirellaRistoranteLinks';
  assets: GirellaRistoranteAssets;
  entries: GirellaRistoranteEntries;
  resources: GirellaRistoranteResources;
};

export type GirellaRistoranteResources = {
  __typename?: 'GirellaRistoranteResources';
  block: Array<GirellaRistoranteResourcesBlock>;
  hyperlink: Array<GirellaRistoranteResourcesHyperlink>;
  inline: Array<GirellaRistoranteResourcesInline>;
};

export type GirellaRistoranteResourcesBlock = ResourceLink & {
  __typename?: 'GirellaRistoranteResourcesBlock';
  sys: ResourceSys;
};

export type GirellaRistoranteResourcesHyperlink = ResourceLink & {
  __typename?: 'GirellaRistoranteResourcesHyperlink';
  sys: ResourceSys;
};

export type GirellaRistoranteResourcesInline = ResourceLink & {
  __typename?: 'GirellaRistoranteResourcesInline';
  sys: ResourceSys;
};

/** [See type definition](https://app.contentful.com/spaces/v2h0bz1hqac3/content_types/hotelPage) */
export type HotelPage = Entry & _Node & {
  __typename?: 'HotelPage';
  _id: Scalars['ID']['output'];
  camereIncludono?: Maybe<Scalars['String']['output']>;
  contentfulMetadata: ContentfulMetadata;
  descrizione?: Maybe<HotelPageDescrizione>;
  linkedFrom?: Maybe<HotelPageLinkingCollections>;
  serviziSuRichiesta?: Maybe<Scalars['String']['output']>;
  sottoTitolo?: Maybe<Scalars['String']['output']>;
  sys: Sys;
  titolo?: Maybe<Scalars['String']['output']>;
  titoloAlternativo?: Maybe<Scalars['String']['output']>;
};


/** [See type definition](https://app.contentful.com/spaces/v2h0bz1hqac3/content_types/hotelPage) */
export type HotelPageCamereIncludonoArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** [See type definition](https://app.contentful.com/spaces/v2h0bz1hqac3/content_types/hotelPage) */
export type HotelPageDescrizioneArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** [See type definition](https://app.contentful.com/spaces/v2h0bz1hqac3/content_types/hotelPage) */
export type HotelPageLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


/** [See type definition](https://app.contentful.com/spaces/v2h0bz1hqac3/content_types/hotelPage) */
export type HotelPageServiziSuRichiestaArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** [See type definition](https://app.contentful.com/spaces/v2h0bz1hqac3/content_types/hotelPage) */
export type HotelPageSottoTitoloArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** [See type definition](https://app.contentful.com/spaces/v2h0bz1hqac3/content_types/hotelPage) */
export type HotelPageTitoloArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** [See type definition](https://app.contentful.com/spaces/v2h0bz1hqac3/content_types/hotelPage) */
export type HotelPageTitoloAlternativoArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

export type HotelPageCollection = {
  __typename?: 'HotelPageCollection';
  items: Array<Maybe<HotelPage>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type HotelPageDescrizione = {
  __typename?: 'HotelPageDescrizione';
  json: Scalars['JSON']['output'];
  links: HotelPageDescrizioneLinks;
};

export type HotelPageDescrizioneAssets = {
  __typename?: 'HotelPageDescrizioneAssets';
  block: Array<Maybe<Asset>>;
  hyperlink: Array<Maybe<Asset>>;
};

export type HotelPageDescrizioneEntries = {
  __typename?: 'HotelPageDescrizioneEntries';
  block: Array<Maybe<Entry>>;
  hyperlink: Array<Maybe<Entry>>;
  inline: Array<Maybe<Entry>>;
};

export type HotelPageDescrizioneLinks = {
  __typename?: 'HotelPageDescrizioneLinks';
  assets: HotelPageDescrizioneAssets;
  entries: HotelPageDescrizioneEntries;
  resources: HotelPageDescrizioneResources;
};

export type HotelPageDescrizioneResources = {
  __typename?: 'HotelPageDescrizioneResources';
  block: Array<HotelPageDescrizioneResourcesBlock>;
  hyperlink: Array<HotelPageDescrizioneResourcesHyperlink>;
  inline: Array<HotelPageDescrizioneResourcesInline>;
};

export type HotelPageDescrizioneResourcesBlock = ResourceLink & {
  __typename?: 'HotelPageDescrizioneResourcesBlock';
  sys: ResourceSys;
};

export type HotelPageDescrizioneResourcesHyperlink = ResourceLink & {
  __typename?: 'HotelPageDescrizioneResourcesHyperlink';
  sys: ResourceSys;
};

export type HotelPageDescrizioneResourcesInline = ResourceLink & {
  __typename?: 'HotelPageDescrizioneResourcesInline';
  sys: ResourceSys;
};

export type HotelPageFilter = {
  AND?: InputMaybe<Array<InputMaybe<HotelPageFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<HotelPageFilter>>>;
  camereIncludono?: InputMaybe<Scalars['String']['input']>;
  camereIncludono_contains?: InputMaybe<Scalars['String']['input']>;
  camereIncludono_exists?: InputMaybe<Scalars['Boolean']['input']>;
  camereIncludono_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  camereIncludono_not?: InputMaybe<Scalars['String']['input']>;
  camereIncludono_not_contains?: InputMaybe<Scalars['String']['input']>;
  camereIncludono_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  descrizione_contains?: InputMaybe<Scalars['String']['input']>;
  descrizione_exists?: InputMaybe<Scalars['Boolean']['input']>;
  descrizione_not_contains?: InputMaybe<Scalars['String']['input']>;
  serviziSuRichiesta?: InputMaybe<Scalars['String']['input']>;
  serviziSuRichiesta_contains?: InputMaybe<Scalars['String']['input']>;
  serviziSuRichiesta_exists?: InputMaybe<Scalars['Boolean']['input']>;
  serviziSuRichiesta_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  serviziSuRichiesta_not?: InputMaybe<Scalars['String']['input']>;
  serviziSuRichiesta_not_contains?: InputMaybe<Scalars['String']['input']>;
  serviziSuRichiesta_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sottoTitolo?: InputMaybe<Scalars['String']['input']>;
  sottoTitolo_contains?: InputMaybe<Scalars['String']['input']>;
  sottoTitolo_exists?: InputMaybe<Scalars['Boolean']['input']>;
  sottoTitolo_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sottoTitolo_not?: InputMaybe<Scalars['String']['input']>;
  sottoTitolo_not_contains?: InputMaybe<Scalars['String']['input']>;
  sottoTitolo_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sys?: InputMaybe<SysFilter>;
  titolo?: InputMaybe<Scalars['String']['input']>;
  titoloAlternativo?: InputMaybe<Scalars['String']['input']>;
  titoloAlternativo_contains?: InputMaybe<Scalars['String']['input']>;
  titoloAlternativo_exists?: InputMaybe<Scalars['Boolean']['input']>;
  titoloAlternativo_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  titoloAlternativo_not?: InputMaybe<Scalars['String']['input']>;
  titoloAlternativo_not_contains?: InputMaybe<Scalars['String']['input']>;
  titoloAlternativo_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  titolo_contains?: InputMaybe<Scalars['String']['input']>;
  titolo_exists?: InputMaybe<Scalars['Boolean']['input']>;
  titolo_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  titolo_not?: InputMaybe<Scalars['String']['input']>;
  titolo_not_contains?: InputMaybe<Scalars['String']['input']>;
  titolo_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type HotelPageLinkingCollections = {
  __typename?: 'HotelPageLinkingCollections';
  entryCollection?: Maybe<EntryCollection>;
};


export type HotelPageLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export enum HotelPageOrder {
  CamereIncludonoAsc = 'camereIncludono_ASC',
  CamereIncludonoDesc = 'camereIncludono_DESC',
  ServiziSuRichiestaAsc = 'serviziSuRichiesta_ASC',
  ServiziSuRichiestaDesc = 'serviziSuRichiesta_DESC',
  SottoTitoloAsc = 'sottoTitolo_ASC',
  SottoTitoloDesc = 'sottoTitolo_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitoloAlternativoAsc = 'titoloAlternativo_ASC',
  TitoloAlternativoDesc = 'titoloAlternativo_DESC',
  TitoloAsc = 'titolo_ASC',
  TitoloDesc = 'titolo_DESC'
}

/** [See type definition](https://app.contentful.com/spaces/v2h0bz1hqac3/content_types/icone) */
export type Icone = Entry & _Node & {
  __typename?: 'Icone';
  _id: Scalars['ID']['output'];
  ariaCondizionata?: Maybe<Scalars['String']['output']>;
  arrediDiDesign?: Maybe<Scalars['String']['output']>;
  autostrada?: Maybe<Scalars['String']['output']>;
  bagnoInMarmo?: Maybe<Scalars['String']['output']>;
  barBistrotERistorante?: Maybe<Scalars['String']['output']>;
  cabinaArmadio?: Maybe<Scalars['String']['output']>;
  caniDiPiccolaTaglia?: Maybe<Scalars['String']['output']>;
  cassaforte?: Maybe<Scalars['String']['output']>;
  cleaningService?: Maybe<Scalars['String']['output']>;
  colonnineDiRicarica?: Maybe<Scalars['String']['output']>;
  como?: Maybe<Scalars['String']['output']>;
  contentfulMetadata: ContentfulMetadata;
  cusciniEPiuminiKingSize?: Maybe<Scalars['String']['output']>;
  dayBed?: Maybe<Scalars['String']['output']>;
  desk?: Maybe<Scalars['String']['output']>;
  eventi?: Maybe<Scalars['String']['output']>;
  freeWifi?: Maybe<Scalars['String']['output']>;
  frigobar?: Maybe<Scalars['String']['output']>;
  lettini?: Maybe<Scalars['String']['output']>;
  lettiniInPiscina?: Maybe<Scalars['String']['output']>;
  linkedFrom?: Maybe<IconeLinkingCollections>;
  lugano?: Maybe<Scalars['String']['output']>;
  milano?: Maybe<Scalars['String']['output']>;
  nespressoConBollitore?: Maybe<Scalars['String']['output']>;
  palestra?: Maybe<Scalars['String']['output']>;
  parcheggi?: Maybe<Scalars['String']['output']>;
  parcheggioInterno?: Maybe<Scalars['String']['output']>;
  phonProfessionaleParlux?: Maybe<Scalars['String']['output']>;
  prodottiDaBagnoRitualsCosmetics?: Maybe<Scalars['String']['output']>;
  roomService?: Maybe<Scalars['String']['output']>;
  stanzeComunicanti?: Maybe<Scalars['String']['output']>;
  sys: Sys;
  telefonoFisso?: Maybe<Scalars['String']['output']>;
  teliSpiaggia?: Maybe<Scalars['String']['output']>;
  transferAeroporto?: Maybe<Scalars['String']['output']>;
  tv50ASchermoPiatto?: Maybe<Scalars['String']['output']>;
  vietatoFumare?: Maybe<Scalars['String']['output']>;
};


/** [See type definition](https://app.contentful.com/spaces/v2h0bz1hqac3/content_types/icone) */
export type IconeAriaCondizionataArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** [See type definition](https://app.contentful.com/spaces/v2h0bz1hqac3/content_types/icone) */
export type IconeArrediDiDesignArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** [See type definition](https://app.contentful.com/spaces/v2h0bz1hqac3/content_types/icone) */
export type IconeAutostradaArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** [See type definition](https://app.contentful.com/spaces/v2h0bz1hqac3/content_types/icone) */
export type IconeBagnoInMarmoArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** [See type definition](https://app.contentful.com/spaces/v2h0bz1hqac3/content_types/icone) */
export type IconeBarBistrotERistoranteArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** [See type definition](https://app.contentful.com/spaces/v2h0bz1hqac3/content_types/icone) */
export type IconeCabinaArmadioArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** [See type definition](https://app.contentful.com/spaces/v2h0bz1hqac3/content_types/icone) */
export type IconeCaniDiPiccolaTagliaArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** [See type definition](https://app.contentful.com/spaces/v2h0bz1hqac3/content_types/icone) */
export type IconeCassaforteArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** [See type definition](https://app.contentful.com/spaces/v2h0bz1hqac3/content_types/icone) */
export type IconeCleaningServiceArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** [See type definition](https://app.contentful.com/spaces/v2h0bz1hqac3/content_types/icone) */
export type IconeColonnineDiRicaricaArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** [See type definition](https://app.contentful.com/spaces/v2h0bz1hqac3/content_types/icone) */
export type IconeComoArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** [See type definition](https://app.contentful.com/spaces/v2h0bz1hqac3/content_types/icone) */
export type IconeCusciniEPiuminiKingSizeArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** [See type definition](https://app.contentful.com/spaces/v2h0bz1hqac3/content_types/icone) */
export type IconeDayBedArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** [See type definition](https://app.contentful.com/spaces/v2h0bz1hqac3/content_types/icone) */
export type IconeDeskArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** [See type definition](https://app.contentful.com/spaces/v2h0bz1hqac3/content_types/icone) */
export type IconeEventiArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** [See type definition](https://app.contentful.com/spaces/v2h0bz1hqac3/content_types/icone) */
export type IconeFreeWifiArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** [See type definition](https://app.contentful.com/spaces/v2h0bz1hqac3/content_types/icone) */
export type IconeFrigobarArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** [See type definition](https://app.contentful.com/spaces/v2h0bz1hqac3/content_types/icone) */
export type IconeLettiniArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** [See type definition](https://app.contentful.com/spaces/v2h0bz1hqac3/content_types/icone) */
export type IconeLettiniInPiscinaArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** [See type definition](https://app.contentful.com/spaces/v2h0bz1hqac3/content_types/icone) */
export type IconeLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


/** [See type definition](https://app.contentful.com/spaces/v2h0bz1hqac3/content_types/icone) */
export type IconeLuganoArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** [See type definition](https://app.contentful.com/spaces/v2h0bz1hqac3/content_types/icone) */
export type IconeMilanoArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** [See type definition](https://app.contentful.com/spaces/v2h0bz1hqac3/content_types/icone) */
export type IconeNespressoConBollitoreArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** [See type definition](https://app.contentful.com/spaces/v2h0bz1hqac3/content_types/icone) */
export type IconePalestraArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** [See type definition](https://app.contentful.com/spaces/v2h0bz1hqac3/content_types/icone) */
export type IconeParcheggiArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** [See type definition](https://app.contentful.com/spaces/v2h0bz1hqac3/content_types/icone) */
export type IconeParcheggioInternoArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** [See type definition](https://app.contentful.com/spaces/v2h0bz1hqac3/content_types/icone) */
export type IconePhonProfessionaleParluxArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** [See type definition](https://app.contentful.com/spaces/v2h0bz1hqac3/content_types/icone) */
export type IconeProdottiDaBagnoRitualsCosmeticsArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** [See type definition](https://app.contentful.com/spaces/v2h0bz1hqac3/content_types/icone) */
export type IconeRoomServiceArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** [See type definition](https://app.contentful.com/spaces/v2h0bz1hqac3/content_types/icone) */
export type IconeStanzeComunicantiArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** [See type definition](https://app.contentful.com/spaces/v2h0bz1hqac3/content_types/icone) */
export type IconeTelefonoFissoArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** [See type definition](https://app.contentful.com/spaces/v2h0bz1hqac3/content_types/icone) */
export type IconeTeliSpiaggiaArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** [See type definition](https://app.contentful.com/spaces/v2h0bz1hqac3/content_types/icone) */
export type IconeTransferAeroportoArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** [See type definition](https://app.contentful.com/spaces/v2h0bz1hqac3/content_types/icone) */
export type IconeTv50ASchermoPiattoArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** [See type definition](https://app.contentful.com/spaces/v2h0bz1hqac3/content_types/icone) */
export type IconeVietatoFumareArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

export type IconeCollection = {
  __typename?: 'IconeCollection';
  items: Array<Maybe<Icone>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type IconeFilter = {
  AND?: InputMaybe<Array<InputMaybe<IconeFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<IconeFilter>>>;
  ariaCondizionata?: InputMaybe<Scalars['String']['input']>;
  ariaCondizionata_contains?: InputMaybe<Scalars['String']['input']>;
  ariaCondizionata_exists?: InputMaybe<Scalars['Boolean']['input']>;
  ariaCondizionata_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  ariaCondizionata_not?: InputMaybe<Scalars['String']['input']>;
  ariaCondizionata_not_contains?: InputMaybe<Scalars['String']['input']>;
  ariaCondizionata_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  arrediDiDesign?: InputMaybe<Scalars['String']['input']>;
  arrediDiDesign_contains?: InputMaybe<Scalars['String']['input']>;
  arrediDiDesign_exists?: InputMaybe<Scalars['Boolean']['input']>;
  arrediDiDesign_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  arrediDiDesign_not?: InputMaybe<Scalars['String']['input']>;
  arrediDiDesign_not_contains?: InputMaybe<Scalars['String']['input']>;
  arrediDiDesign_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  autostrada?: InputMaybe<Scalars['String']['input']>;
  autostrada_contains?: InputMaybe<Scalars['String']['input']>;
  autostrada_exists?: InputMaybe<Scalars['Boolean']['input']>;
  autostrada_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  autostrada_not?: InputMaybe<Scalars['String']['input']>;
  autostrada_not_contains?: InputMaybe<Scalars['String']['input']>;
  autostrada_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  bagnoInMarmo?: InputMaybe<Scalars['String']['input']>;
  bagnoInMarmo_contains?: InputMaybe<Scalars['String']['input']>;
  bagnoInMarmo_exists?: InputMaybe<Scalars['Boolean']['input']>;
  bagnoInMarmo_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  bagnoInMarmo_not?: InputMaybe<Scalars['String']['input']>;
  bagnoInMarmo_not_contains?: InputMaybe<Scalars['String']['input']>;
  bagnoInMarmo_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  barBistrotERistorante?: InputMaybe<Scalars['String']['input']>;
  barBistrotERistorante_contains?: InputMaybe<Scalars['String']['input']>;
  barBistrotERistorante_exists?: InputMaybe<Scalars['Boolean']['input']>;
  barBistrotERistorante_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  barBistrotERistorante_not?: InputMaybe<Scalars['String']['input']>;
  barBistrotERistorante_not_contains?: InputMaybe<Scalars['String']['input']>;
  barBistrotERistorante_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  cabinaArmadio?: InputMaybe<Scalars['String']['input']>;
  cabinaArmadio_contains?: InputMaybe<Scalars['String']['input']>;
  cabinaArmadio_exists?: InputMaybe<Scalars['Boolean']['input']>;
  cabinaArmadio_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  cabinaArmadio_not?: InputMaybe<Scalars['String']['input']>;
  cabinaArmadio_not_contains?: InputMaybe<Scalars['String']['input']>;
  cabinaArmadio_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  caniDiPiccolaTaglia?: InputMaybe<Scalars['String']['input']>;
  caniDiPiccolaTaglia_contains?: InputMaybe<Scalars['String']['input']>;
  caniDiPiccolaTaglia_exists?: InputMaybe<Scalars['Boolean']['input']>;
  caniDiPiccolaTaglia_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  caniDiPiccolaTaglia_not?: InputMaybe<Scalars['String']['input']>;
  caniDiPiccolaTaglia_not_contains?: InputMaybe<Scalars['String']['input']>;
  caniDiPiccolaTaglia_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  cassaforte?: InputMaybe<Scalars['String']['input']>;
  cassaforte_contains?: InputMaybe<Scalars['String']['input']>;
  cassaforte_exists?: InputMaybe<Scalars['Boolean']['input']>;
  cassaforte_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  cassaforte_not?: InputMaybe<Scalars['String']['input']>;
  cassaforte_not_contains?: InputMaybe<Scalars['String']['input']>;
  cassaforte_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  cleaningService?: InputMaybe<Scalars['String']['input']>;
  cleaningService_contains?: InputMaybe<Scalars['String']['input']>;
  cleaningService_exists?: InputMaybe<Scalars['Boolean']['input']>;
  cleaningService_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  cleaningService_not?: InputMaybe<Scalars['String']['input']>;
  cleaningService_not_contains?: InputMaybe<Scalars['String']['input']>;
  cleaningService_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  colonnineDiRicarica?: InputMaybe<Scalars['String']['input']>;
  colonnineDiRicarica_contains?: InputMaybe<Scalars['String']['input']>;
  colonnineDiRicarica_exists?: InputMaybe<Scalars['Boolean']['input']>;
  colonnineDiRicarica_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  colonnineDiRicarica_not?: InputMaybe<Scalars['String']['input']>;
  colonnineDiRicarica_not_contains?: InputMaybe<Scalars['String']['input']>;
  colonnineDiRicarica_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  como?: InputMaybe<Scalars['String']['input']>;
  como_contains?: InputMaybe<Scalars['String']['input']>;
  como_exists?: InputMaybe<Scalars['Boolean']['input']>;
  como_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  como_not?: InputMaybe<Scalars['String']['input']>;
  como_not_contains?: InputMaybe<Scalars['String']['input']>;
  como_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  cusciniEPiuminiKingSize?: InputMaybe<Scalars['String']['input']>;
  cusciniEPiuminiKingSize_contains?: InputMaybe<Scalars['String']['input']>;
  cusciniEPiuminiKingSize_exists?: InputMaybe<Scalars['Boolean']['input']>;
  cusciniEPiuminiKingSize_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  cusciniEPiuminiKingSize_not?: InputMaybe<Scalars['String']['input']>;
  cusciniEPiuminiKingSize_not_contains?: InputMaybe<Scalars['String']['input']>;
  cusciniEPiuminiKingSize_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dayBed?: InputMaybe<Scalars['String']['input']>;
  dayBed_contains?: InputMaybe<Scalars['String']['input']>;
  dayBed_exists?: InputMaybe<Scalars['Boolean']['input']>;
  dayBed_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dayBed_not?: InputMaybe<Scalars['String']['input']>;
  dayBed_not_contains?: InputMaybe<Scalars['String']['input']>;
  dayBed_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  desk?: InputMaybe<Scalars['String']['input']>;
  desk_contains?: InputMaybe<Scalars['String']['input']>;
  desk_exists?: InputMaybe<Scalars['Boolean']['input']>;
  desk_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  desk_not?: InputMaybe<Scalars['String']['input']>;
  desk_not_contains?: InputMaybe<Scalars['String']['input']>;
  desk_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  eventi?: InputMaybe<Scalars['String']['input']>;
  eventi_contains?: InputMaybe<Scalars['String']['input']>;
  eventi_exists?: InputMaybe<Scalars['Boolean']['input']>;
  eventi_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  eventi_not?: InputMaybe<Scalars['String']['input']>;
  eventi_not_contains?: InputMaybe<Scalars['String']['input']>;
  eventi_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  freeWifi?: InputMaybe<Scalars['String']['input']>;
  freeWifi_contains?: InputMaybe<Scalars['String']['input']>;
  freeWifi_exists?: InputMaybe<Scalars['Boolean']['input']>;
  freeWifi_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  freeWifi_not?: InputMaybe<Scalars['String']['input']>;
  freeWifi_not_contains?: InputMaybe<Scalars['String']['input']>;
  freeWifi_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  frigobar?: InputMaybe<Scalars['String']['input']>;
  frigobar_contains?: InputMaybe<Scalars['String']['input']>;
  frigobar_exists?: InputMaybe<Scalars['Boolean']['input']>;
  frigobar_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  frigobar_not?: InputMaybe<Scalars['String']['input']>;
  frigobar_not_contains?: InputMaybe<Scalars['String']['input']>;
  frigobar_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  lettini?: InputMaybe<Scalars['String']['input']>;
  lettiniInPiscina?: InputMaybe<Scalars['String']['input']>;
  lettiniInPiscina_contains?: InputMaybe<Scalars['String']['input']>;
  lettiniInPiscina_exists?: InputMaybe<Scalars['Boolean']['input']>;
  lettiniInPiscina_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  lettiniInPiscina_not?: InputMaybe<Scalars['String']['input']>;
  lettiniInPiscina_not_contains?: InputMaybe<Scalars['String']['input']>;
  lettiniInPiscina_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  lettini_contains?: InputMaybe<Scalars['String']['input']>;
  lettini_exists?: InputMaybe<Scalars['Boolean']['input']>;
  lettini_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  lettini_not?: InputMaybe<Scalars['String']['input']>;
  lettini_not_contains?: InputMaybe<Scalars['String']['input']>;
  lettini_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  lugano?: InputMaybe<Scalars['String']['input']>;
  lugano_contains?: InputMaybe<Scalars['String']['input']>;
  lugano_exists?: InputMaybe<Scalars['Boolean']['input']>;
  lugano_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  lugano_not?: InputMaybe<Scalars['String']['input']>;
  lugano_not_contains?: InputMaybe<Scalars['String']['input']>;
  lugano_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  milano?: InputMaybe<Scalars['String']['input']>;
  milano_contains?: InputMaybe<Scalars['String']['input']>;
  milano_exists?: InputMaybe<Scalars['Boolean']['input']>;
  milano_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  milano_not?: InputMaybe<Scalars['String']['input']>;
  milano_not_contains?: InputMaybe<Scalars['String']['input']>;
  milano_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  nespressoConBollitore?: InputMaybe<Scalars['String']['input']>;
  nespressoConBollitore_contains?: InputMaybe<Scalars['String']['input']>;
  nespressoConBollitore_exists?: InputMaybe<Scalars['Boolean']['input']>;
  nespressoConBollitore_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  nespressoConBollitore_not?: InputMaybe<Scalars['String']['input']>;
  nespressoConBollitore_not_contains?: InputMaybe<Scalars['String']['input']>;
  nespressoConBollitore_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  palestra?: InputMaybe<Scalars['String']['input']>;
  palestra_contains?: InputMaybe<Scalars['String']['input']>;
  palestra_exists?: InputMaybe<Scalars['Boolean']['input']>;
  palestra_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  palestra_not?: InputMaybe<Scalars['String']['input']>;
  palestra_not_contains?: InputMaybe<Scalars['String']['input']>;
  palestra_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  parcheggi?: InputMaybe<Scalars['String']['input']>;
  parcheggi_contains?: InputMaybe<Scalars['String']['input']>;
  parcheggi_exists?: InputMaybe<Scalars['Boolean']['input']>;
  parcheggi_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  parcheggi_not?: InputMaybe<Scalars['String']['input']>;
  parcheggi_not_contains?: InputMaybe<Scalars['String']['input']>;
  parcheggi_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  parcheggioInterno?: InputMaybe<Scalars['String']['input']>;
  parcheggioInterno_contains?: InputMaybe<Scalars['String']['input']>;
  parcheggioInterno_exists?: InputMaybe<Scalars['Boolean']['input']>;
  parcheggioInterno_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  parcheggioInterno_not?: InputMaybe<Scalars['String']['input']>;
  parcheggioInterno_not_contains?: InputMaybe<Scalars['String']['input']>;
  parcheggioInterno_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  phonProfessionaleParlux?: InputMaybe<Scalars['String']['input']>;
  phonProfessionaleParlux_contains?: InputMaybe<Scalars['String']['input']>;
  phonProfessionaleParlux_exists?: InputMaybe<Scalars['Boolean']['input']>;
  phonProfessionaleParlux_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  phonProfessionaleParlux_not?: InputMaybe<Scalars['String']['input']>;
  phonProfessionaleParlux_not_contains?: InputMaybe<Scalars['String']['input']>;
  phonProfessionaleParlux_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  prodottiDaBagnoRitualsCosmetics?: InputMaybe<Scalars['String']['input']>;
  prodottiDaBagnoRitualsCosmetics_contains?: InputMaybe<Scalars['String']['input']>;
  prodottiDaBagnoRitualsCosmetics_exists?: InputMaybe<Scalars['Boolean']['input']>;
  prodottiDaBagnoRitualsCosmetics_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  prodottiDaBagnoRitualsCosmetics_not?: InputMaybe<Scalars['String']['input']>;
  prodottiDaBagnoRitualsCosmetics_not_contains?: InputMaybe<Scalars['String']['input']>;
  prodottiDaBagnoRitualsCosmetics_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  roomService?: InputMaybe<Scalars['String']['input']>;
  roomService_contains?: InputMaybe<Scalars['String']['input']>;
  roomService_exists?: InputMaybe<Scalars['Boolean']['input']>;
  roomService_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  roomService_not?: InputMaybe<Scalars['String']['input']>;
  roomService_not_contains?: InputMaybe<Scalars['String']['input']>;
  roomService_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  stanzeComunicanti?: InputMaybe<Scalars['String']['input']>;
  stanzeComunicanti_contains?: InputMaybe<Scalars['String']['input']>;
  stanzeComunicanti_exists?: InputMaybe<Scalars['Boolean']['input']>;
  stanzeComunicanti_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  stanzeComunicanti_not?: InputMaybe<Scalars['String']['input']>;
  stanzeComunicanti_not_contains?: InputMaybe<Scalars['String']['input']>;
  stanzeComunicanti_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sys?: InputMaybe<SysFilter>;
  telefonoFisso?: InputMaybe<Scalars['String']['input']>;
  telefonoFisso_contains?: InputMaybe<Scalars['String']['input']>;
  telefonoFisso_exists?: InputMaybe<Scalars['Boolean']['input']>;
  telefonoFisso_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  telefonoFisso_not?: InputMaybe<Scalars['String']['input']>;
  telefonoFisso_not_contains?: InputMaybe<Scalars['String']['input']>;
  telefonoFisso_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  teliSpiaggia?: InputMaybe<Scalars['String']['input']>;
  teliSpiaggia_contains?: InputMaybe<Scalars['String']['input']>;
  teliSpiaggia_exists?: InputMaybe<Scalars['Boolean']['input']>;
  teliSpiaggia_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  teliSpiaggia_not?: InputMaybe<Scalars['String']['input']>;
  teliSpiaggia_not_contains?: InputMaybe<Scalars['String']['input']>;
  teliSpiaggia_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  transferAeroporto?: InputMaybe<Scalars['String']['input']>;
  transferAeroporto_contains?: InputMaybe<Scalars['String']['input']>;
  transferAeroporto_exists?: InputMaybe<Scalars['Boolean']['input']>;
  transferAeroporto_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  transferAeroporto_not?: InputMaybe<Scalars['String']['input']>;
  transferAeroporto_not_contains?: InputMaybe<Scalars['String']['input']>;
  transferAeroporto_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  tv50ASchermoPiatto?: InputMaybe<Scalars['String']['input']>;
  tv50ASchermoPiatto_contains?: InputMaybe<Scalars['String']['input']>;
  tv50ASchermoPiatto_exists?: InputMaybe<Scalars['Boolean']['input']>;
  tv50ASchermoPiatto_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  tv50ASchermoPiatto_not?: InputMaybe<Scalars['String']['input']>;
  tv50ASchermoPiatto_not_contains?: InputMaybe<Scalars['String']['input']>;
  tv50ASchermoPiatto_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  vietatoFumare?: InputMaybe<Scalars['String']['input']>;
  vietatoFumare_contains?: InputMaybe<Scalars['String']['input']>;
  vietatoFumare_exists?: InputMaybe<Scalars['Boolean']['input']>;
  vietatoFumare_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  vietatoFumare_not?: InputMaybe<Scalars['String']['input']>;
  vietatoFumare_not_contains?: InputMaybe<Scalars['String']['input']>;
  vietatoFumare_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type IconeLinkingCollections = {
  __typename?: 'IconeLinkingCollections';
  entryCollection?: Maybe<EntryCollection>;
};


export type IconeLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export enum IconeOrder {
  AriaCondizionataAsc = 'ariaCondizionata_ASC',
  AriaCondizionataDesc = 'ariaCondizionata_DESC',
  ArrediDiDesignAsc = 'arrediDiDesign_ASC',
  ArrediDiDesignDesc = 'arrediDiDesign_DESC',
  AutostradaAsc = 'autostrada_ASC',
  AutostradaDesc = 'autostrada_DESC',
  BagnoInMarmoAsc = 'bagnoInMarmo_ASC',
  BagnoInMarmoDesc = 'bagnoInMarmo_DESC',
  BarBistrotERistoranteAsc = 'barBistrotERistorante_ASC',
  BarBistrotERistoranteDesc = 'barBistrotERistorante_DESC',
  CabinaArmadioAsc = 'cabinaArmadio_ASC',
  CabinaArmadioDesc = 'cabinaArmadio_DESC',
  CaniDiPiccolaTagliaAsc = 'caniDiPiccolaTaglia_ASC',
  CaniDiPiccolaTagliaDesc = 'caniDiPiccolaTaglia_DESC',
  CassaforteAsc = 'cassaforte_ASC',
  CassaforteDesc = 'cassaforte_DESC',
  CleaningServiceAsc = 'cleaningService_ASC',
  CleaningServiceDesc = 'cleaningService_DESC',
  ColonnineDiRicaricaAsc = 'colonnineDiRicarica_ASC',
  ColonnineDiRicaricaDesc = 'colonnineDiRicarica_DESC',
  ComoAsc = 'como_ASC',
  ComoDesc = 'como_DESC',
  CusciniEPiuminiKingSizeAsc = 'cusciniEPiuminiKingSize_ASC',
  CusciniEPiuminiKingSizeDesc = 'cusciniEPiuminiKingSize_DESC',
  DayBedAsc = 'dayBed_ASC',
  DayBedDesc = 'dayBed_DESC',
  DeskAsc = 'desk_ASC',
  DeskDesc = 'desk_DESC',
  EventiAsc = 'eventi_ASC',
  EventiDesc = 'eventi_DESC',
  FreeWifiAsc = 'freeWifi_ASC',
  FreeWifiDesc = 'freeWifi_DESC',
  FrigobarAsc = 'frigobar_ASC',
  FrigobarDesc = 'frigobar_DESC',
  LettiniInPiscinaAsc = 'lettiniInPiscina_ASC',
  LettiniInPiscinaDesc = 'lettiniInPiscina_DESC',
  LettiniAsc = 'lettini_ASC',
  LettiniDesc = 'lettini_DESC',
  LuganoAsc = 'lugano_ASC',
  LuganoDesc = 'lugano_DESC',
  MilanoAsc = 'milano_ASC',
  MilanoDesc = 'milano_DESC',
  NespressoConBollitoreAsc = 'nespressoConBollitore_ASC',
  NespressoConBollitoreDesc = 'nespressoConBollitore_DESC',
  PalestraAsc = 'palestra_ASC',
  PalestraDesc = 'palestra_DESC',
  ParcheggiAsc = 'parcheggi_ASC',
  ParcheggiDesc = 'parcheggi_DESC',
  ParcheggioInternoAsc = 'parcheggioInterno_ASC',
  ParcheggioInternoDesc = 'parcheggioInterno_DESC',
  PhonProfessionaleParluxAsc = 'phonProfessionaleParlux_ASC',
  PhonProfessionaleParluxDesc = 'phonProfessionaleParlux_DESC',
  ProdottiDaBagnoRitualsCosmeticsAsc = 'prodottiDaBagnoRitualsCosmetics_ASC',
  ProdottiDaBagnoRitualsCosmeticsDesc = 'prodottiDaBagnoRitualsCosmetics_DESC',
  RoomServiceAsc = 'roomService_ASC',
  RoomServiceDesc = 'roomService_DESC',
  StanzeComunicantiAsc = 'stanzeComunicanti_ASC',
  StanzeComunicantiDesc = 'stanzeComunicanti_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TelefonoFissoAsc = 'telefonoFisso_ASC',
  TelefonoFissoDesc = 'telefonoFisso_DESC',
  TeliSpiaggiaAsc = 'teliSpiaggia_ASC',
  TeliSpiaggiaDesc = 'teliSpiaggia_DESC',
  TransferAeroportoAsc = 'transferAeroporto_ASC',
  TransferAeroportoDesc = 'transferAeroporto_DESC',
  Tv50ASchermoPiattoAsc = 'tv50ASchermoPiatto_ASC',
  Tv50ASchermoPiattoDesc = 'tv50ASchermoPiatto_DESC',
  VietatoFumareAsc = 'vietatoFumare_ASC',
  VietatoFumareDesc = 'vietatoFumare_DESC'
}

export enum ImageFormat {
  Avif = 'AVIF',
  /** JPG image format. */
  Jpg = 'JPG',
  /**
   * Progressive JPG format stores multiple passes of an image in progressively higher detail.
   *         When a progressive image is loading, the viewer will first see a lower quality pixelated version which
   *         will gradually improve in detail, until the image is fully downloaded. This is to display an image as
   *         early as possible to make the layout look as designed.
   */
  JpgProgressive = 'JPG_PROGRESSIVE',
  /** PNG image format */
  Png = 'PNG',
  /**
   * 8-bit PNG images support up to 256 colors and weigh less than the standard 24-bit PNG equivalent.
   *         The 8-bit PNG format is mostly used for simple images, such as icons or logos.
   */
  Png8 = 'PNG8',
  /** WebP image format. */
  Webp = 'WEBP'
}

export enum ImageResizeFocus {
  /** Focus the resizing on the bottom. */
  Bottom = 'BOTTOM',
  /** Focus the resizing on the bottom left. */
  BottomLeft = 'BOTTOM_LEFT',
  /** Focus the resizing on the bottom right. */
  BottomRight = 'BOTTOM_RIGHT',
  /** Focus the resizing on the center. */
  Center = 'CENTER',
  /** Focus the resizing on the largest face. */
  Face = 'FACE',
  /** Focus the resizing on the area containing all the faces. */
  Faces = 'FACES',
  /** Focus the resizing on the left. */
  Left = 'LEFT',
  /** Focus the resizing on the right. */
  Right = 'RIGHT',
  /** Focus the resizing on the top. */
  Top = 'TOP',
  /** Focus the resizing on the top left. */
  TopLeft = 'TOP_LEFT',
  /** Focus the resizing on the top right. */
  TopRight = 'TOP_RIGHT'
}

export enum ImageResizeStrategy {
  /** Crops a part of the original image to fit into the specified dimensions. */
  Crop = 'CROP',
  /** Resizes the image to the specified dimensions, cropping the image if needed. */
  Fill = 'FILL',
  /** Resizes the image to fit into the specified dimensions. */
  Fit = 'FIT',
  /**
   * Resizes the image to the specified dimensions, padding the image if needed.
   *         Uses desired background color as padding color.
   */
  Pad = 'PAD',
  /** Resizes the image to the specified dimensions, changing the original aspect ratio if needed. */
  Scale = 'SCALE',
  /** Creates a thumbnail from the image. */
  Thumb = 'THUMB'
}

export type ImageTransformOptions = {
  /**
   * Desired background color, used with corner radius or `PAD` resize strategy.
   *         Defaults to transparent (for `PNG`, `PNG8` and `WEBP`) or white (for `JPG` and `JPG_PROGRESSIVE`).
   */
  backgroundColor?: InputMaybe<Scalars['HexColor']['input']>;
  /**
   * Desired corner radius in pixels.
   *         Results in an image with rounded corners (pass `-1` for a full circle/ellipse).
   *         Defaults to `0`. Uses desired background color as padding color,
   *         unless the format is `JPG` or `JPG_PROGRESSIVE` and resize strategy is `PAD`, then defaults to white.
   */
  cornerRadius?: InputMaybe<Scalars['Int']['input']>;
  /** Desired image format. Defaults to the original image format. */
  format?: InputMaybe<ImageFormat>;
  /** Desired height in pixels. Defaults to the original image height. */
  height?: InputMaybe<Scalars['Dimension']['input']>;
  /**
   * Desired quality of the image in percents.
   *         Used for `PNG8`, `JPG`, `JPG_PROGRESSIVE` and `WEBP` formats.
   */
  quality?: InputMaybe<Scalars['Quality']['input']>;
  /** Desired resize focus area. Defaults to `CENTER`. */
  resizeFocus?: InputMaybe<ImageResizeFocus>;
  /** Desired resize strategy. Defaults to `FIT`. */
  resizeStrategy?: InputMaybe<ImageResizeStrategy>;
  /** Desired width in pixels. Defaults to the original image width. */
  width?: InputMaybe<Scalars['Dimension']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/v2h0bz1hqac3/content_types/imgTitleDesc) */
export type ImgTitleDesc = Entry & _Node & {
  __typename?: 'ImgTitleDesc';
  _id: Scalars['ID']['output'];
  contentfulMetadata: ContentfulMetadata;
  description?: Maybe<Scalars['String']['output']>;
  img?: Maybe<Asset>;
  linkedFrom?: Maybe<ImgTitleDescLinkingCollections>;
  sys: Sys;
  title?: Maybe<Scalars['String']['output']>;
};


/** [See type definition](https://app.contentful.com/spaces/v2h0bz1hqac3/content_types/imgTitleDesc) */
export type ImgTitleDescDescriptionArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** [See type definition](https://app.contentful.com/spaces/v2h0bz1hqac3/content_types/imgTitleDesc) */
export type ImgTitleDescImgArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};


/** [See type definition](https://app.contentful.com/spaces/v2h0bz1hqac3/content_types/imgTitleDesc) */
export type ImgTitleDescLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


/** [See type definition](https://app.contentful.com/spaces/v2h0bz1hqac3/content_types/imgTitleDesc) */
export type ImgTitleDescTitleArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

export type ImgTitleDescCollection = {
  __typename?: 'ImgTitleDescCollection';
  items: Array<Maybe<ImgTitleDesc>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type ImgTitleDescFilter = {
  AND?: InputMaybe<Array<InputMaybe<ImgTitleDescFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<ImgTitleDescFilter>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  description?: InputMaybe<Scalars['String']['input']>;
  description_contains?: InputMaybe<Scalars['String']['input']>;
  description_exists?: InputMaybe<Scalars['Boolean']['input']>;
  description_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  description_not?: InputMaybe<Scalars['String']['input']>;
  description_not_contains?: InputMaybe<Scalars['String']['input']>;
  description_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  img_exists?: InputMaybe<Scalars['Boolean']['input']>;
  sys?: InputMaybe<SysFilter>;
  title?: InputMaybe<Scalars['String']['input']>;
  title_contains?: InputMaybe<Scalars['String']['input']>;
  title_exists?: InputMaybe<Scalars['Boolean']['input']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title_not?: InputMaybe<Scalars['String']['input']>;
  title_not_contains?: InputMaybe<Scalars['String']['input']>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type ImgTitleDescLinkingCollections = {
  __typename?: 'ImgTitleDescLinkingCollections';
  entryCollection?: Maybe<EntryCollection>;
};


export type ImgTitleDescLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export enum ImgTitleDescOrder {
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC'
}

/** [See type definition](https://app.contentful.com/spaces/v2h0bz1hqac3/content_types/istrutturi) */
export type Istrutturi = Entry & _Node & {
  __typename?: 'Istrutturi';
  _id: Scalars['ID']['output'];
  christianBio?: Maybe<Scalars['String']['output']>;
  contentfulMetadata: ContentfulMetadata;
  linkedFrom?: Maybe<IstrutturiLinkingCollections>;
  robertaBio?: Maybe<Scalars['String']['output']>;
  sys: Sys;
  titolo?: Maybe<Scalars['String']['output']>;
};


/** [See type definition](https://app.contentful.com/spaces/v2h0bz1hqac3/content_types/istrutturi) */
export type IstrutturiChristianBioArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** [See type definition](https://app.contentful.com/spaces/v2h0bz1hqac3/content_types/istrutturi) */
export type IstrutturiLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


/** [See type definition](https://app.contentful.com/spaces/v2h0bz1hqac3/content_types/istrutturi) */
export type IstrutturiRobertaBioArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** [See type definition](https://app.contentful.com/spaces/v2h0bz1hqac3/content_types/istrutturi) */
export type IstrutturiTitoloArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

export type IstrutturiCollection = {
  __typename?: 'IstrutturiCollection';
  items: Array<Maybe<Istrutturi>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type IstrutturiFilter = {
  AND?: InputMaybe<Array<InputMaybe<IstrutturiFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<IstrutturiFilter>>>;
  christianBio?: InputMaybe<Scalars['String']['input']>;
  christianBio_contains?: InputMaybe<Scalars['String']['input']>;
  christianBio_exists?: InputMaybe<Scalars['Boolean']['input']>;
  christianBio_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  christianBio_not?: InputMaybe<Scalars['String']['input']>;
  christianBio_not_contains?: InputMaybe<Scalars['String']['input']>;
  christianBio_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  robertaBio?: InputMaybe<Scalars['String']['input']>;
  robertaBio_contains?: InputMaybe<Scalars['String']['input']>;
  robertaBio_exists?: InputMaybe<Scalars['Boolean']['input']>;
  robertaBio_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  robertaBio_not?: InputMaybe<Scalars['String']['input']>;
  robertaBio_not_contains?: InputMaybe<Scalars['String']['input']>;
  robertaBio_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sys?: InputMaybe<SysFilter>;
  titolo?: InputMaybe<Scalars['String']['input']>;
  titolo_contains?: InputMaybe<Scalars['String']['input']>;
  titolo_exists?: InputMaybe<Scalars['Boolean']['input']>;
  titolo_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  titolo_not?: InputMaybe<Scalars['String']['input']>;
  titolo_not_contains?: InputMaybe<Scalars['String']['input']>;
  titolo_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type IstrutturiLinkingCollections = {
  __typename?: 'IstrutturiLinkingCollections';
  entryCollection?: Maybe<EntryCollection>;
};


export type IstrutturiLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export enum IstrutturiOrder {
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitoloAsc = 'titolo_ASC',
  TitoloDesc = 'titolo_DESC'
}

/** [See type definition](https://app.contentful.com/spaces/v2h0bz1hqac3/content_types/lavoraConNoi) */
export type LavoraConNoi = Entry & _Node & {
  __typename?: 'LavoraConNoi';
  _id: Scalars['ID']['output'];
  contentfulMetadata: ContentfulMetadata;
  invia?: Maybe<Scalars['String']['output']>;
  linkedFrom?: Maybe<LavoraConNoiLinkingCollections>;
  sys: Sys;
  testo?: Maybe<Scalars['String']['output']>;
  titolo?: Maybe<Scalars['String']['output']>;
};


/** [See type definition](https://app.contentful.com/spaces/v2h0bz1hqac3/content_types/lavoraConNoi) */
export type LavoraConNoiInviaArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** [See type definition](https://app.contentful.com/spaces/v2h0bz1hqac3/content_types/lavoraConNoi) */
export type LavoraConNoiLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


/** [See type definition](https://app.contentful.com/spaces/v2h0bz1hqac3/content_types/lavoraConNoi) */
export type LavoraConNoiTestoArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** [See type definition](https://app.contentful.com/spaces/v2h0bz1hqac3/content_types/lavoraConNoi) */
export type LavoraConNoiTitoloArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

export type LavoraConNoiCollection = {
  __typename?: 'LavoraConNoiCollection';
  items: Array<Maybe<LavoraConNoi>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type LavoraConNoiFilter = {
  AND?: InputMaybe<Array<InputMaybe<LavoraConNoiFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<LavoraConNoiFilter>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  invia?: InputMaybe<Scalars['String']['input']>;
  invia_contains?: InputMaybe<Scalars['String']['input']>;
  invia_exists?: InputMaybe<Scalars['Boolean']['input']>;
  invia_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  invia_not?: InputMaybe<Scalars['String']['input']>;
  invia_not_contains?: InputMaybe<Scalars['String']['input']>;
  invia_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sys?: InputMaybe<SysFilter>;
  testo?: InputMaybe<Scalars['String']['input']>;
  testo_contains?: InputMaybe<Scalars['String']['input']>;
  testo_exists?: InputMaybe<Scalars['Boolean']['input']>;
  testo_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  testo_not?: InputMaybe<Scalars['String']['input']>;
  testo_not_contains?: InputMaybe<Scalars['String']['input']>;
  testo_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  titolo?: InputMaybe<Scalars['String']['input']>;
  titolo_contains?: InputMaybe<Scalars['String']['input']>;
  titolo_exists?: InputMaybe<Scalars['Boolean']['input']>;
  titolo_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  titolo_not?: InputMaybe<Scalars['String']['input']>;
  titolo_not_contains?: InputMaybe<Scalars['String']['input']>;
  titolo_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type LavoraConNoiLinkingCollections = {
  __typename?: 'LavoraConNoiLinkingCollections';
  entryCollection?: Maybe<EntryCollection>;
};


export type LavoraConNoiLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export enum LavoraConNoiOrder {
  InviaAsc = 'invia_ASC',
  InviaDesc = 'invia_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitoloAsc = 'titolo_ASC',
  TitoloDesc = 'titolo_DESC'
}

/** [See type definition](https://app.contentful.com/spaces/v2h0bz1hqac3/content_types/padelAccademyDesc) */
export type PadelAccademyDesc = Entry & _Node & {
  __typename?: 'PadelAccademyDesc';
  _id: Scalars['ID']['output'];
  contentfulMetadata: ContentfulMetadata;
  descrizione?: Maybe<PadelAccademyDescDescrizione>;
  linkedFrom?: Maybe<PadelAccademyDescLinkingCollections>;
  prezzi?: Maybe<Asset>;
  sys: Sys;
  titolo?: Maybe<Scalars['String']['output']>;
};


/** [See type definition](https://app.contentful.com/spaces/v2h0bz1hqac3/content_types/padelAccademyDesc) */
export type PadelAccademyDescDescrizioneArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** [See type definition](https://app.contentful.com/spaces/v2h0bz1hqac3/content_types/padelAccademyDesc) */
export type PadelAccademyDescLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


/** [See type definition](https://app.contentful.com/spaces/v2h0bz1hqac3/content_types/padelAccademyDesc) */
export type PadelAccademyDescPrezziArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};


/** [See type definition](https://app.contentful.com/spaces/v2h0bz1hqac3/content_types/padelAccademyDesc) */
export type PadelAccademyDescTitoloArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

export type PadelAccademyDescCollection = {
  __typename?: 'PadelAccademyDescCollection';
  items: Array<Maybe<PadelAccademyDesc>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type PadelAccademyDescDescrizione = {
  __typename?: 'PadelAccademyDescDescrizione';
  json: Scalars['JSON']['output'];
  links: PadelAccademyDescDescrizioneLinks;
};

export type PadelAccademyDescDescrizioneAssets = {
  __typename?: 'PadelAccademyDescDescrizioneAssets';
  block: Array<Maybe<Asset>>;
  hyperlink: Array<Maybe<Asset>>;
};

export type PadelAccademyDescDescrizioneEntries = {
  __typename?: 'PadelAccademyDescDescrizioneEntries';
  block: Array<Maybe<Entry>>;
  hyperlink: Array<Maybe<Entry>>;
  inline: Array<Maybe<Entry>>;
};

export type PadelAccademyDescDescrizioneLinks = {
  __typename?: 'PadelAccademyDescDescrizioneLinks';
  assets: PadelAccademyDescDescrizioneAssets;
  entries: PadelAccademyDescDescrizioneEntries;
  resources: PadelAccademyDescDescrizioneResources;
};

export type PadelAccademyDescDescrizioneResources = {
  __typename?: 'PadelAccademyDescDescrizioneResources';
  block: Array<PadelAccademyDescDescrizioneResourcesBlock>;
  hyperlink: Array<PadelAccademyDescDescrizioneResourcesHyperlink>;
  inline: Array<PadelAccademyDescDescrizioneResourcesInline>;
};

export type PadelAccademyDescDescrizioneResourcesBlock = ResourceLink & {
  __typename?: 'PadelAccademyDescDescrizioneResourcesBlock';
  sys: ResourceSys;
};

export type PadelAccademyDescDescrizioneResourcesHyperlink = ResourceLink & {
  __typename?: 'PadelAccademyDescDescrizioneResourcesHyperlink';
  sys: ResourceSys;
};

export type PadelAccademyDescDescrizioneResourcesInline = ResourceLink & {
  __typename?: 'PadelAccademyDescDescrizioneResourcesInline';
  sys: ResourceSys;
};

export type PadelAccademyDescFilter = {
  AND?: InputMaybe<Array<InputMaybe<PadelAccademyDescFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<PadelAccademyDescFilter>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  descrizione_contains?: InputMaybe<Scalars['String']['input']>;
  descrizione_exists?: InputMaybe<Scalars['Boolean']['input']>;
  descrizione_not_contains?: InputMaybe<Scalars['String']['input']>;
  prezzi_exists?: InputMaybe<Scalars['Boolean']['input']>;
  sys?: InputMaybe<SysFilter>;
  titolo?: InputMaybe<Scalars['String']['input']>;
  titolo_contains?: InputMaybe<Scalars['String']['input']>;
  titolo_exists?: InputMaybe<Scalars['Boolean']['input']>;
  titolo_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  titolo_not?: InputMaybe<Scalars['String']['input']>;
  titolo_not_contains?: InputMaybe<Scalars['String']['input']>;
  titolo_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type PadelAccademyDescLinkingCollections = {
  __typename?: 'PadelAccademyDescLinkingCollections';
  entryCollection?: Maybe<EntryCollection>;
};


export type PadelAccademyDescLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export enum PadelAccademyDescOrder {
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitoloAsc = 'titolo_ASC',
  TitoloDesc = 'titolo_DESC'
}

/** [See type definition](https://app.contentful.com/spaces/v2h0bz1hqac3/content_types/padelRules) */
export type PadelRules = Entry & _Node & {
  __typename?: 'PadelRules';
  _id: Scalars['ID']['output'];
  campo?: Maybe<Scalars['String']['output']>;
  contentfulMetadata: ContentfulMetadata;
  linkedFrom?: Maybe<PadelRulesLinkingCollections>;
  palla?: Maybe<Scalars['String']['output']>;
  punteggio?: Maybe<Scalars['String']['output']>;
  racchetta?: Maybe<Scalars['String']['output']>;
  sys: Sys;
  titolo?: Maybe<Scalars['String']['output']>;
};


/** [See type definition](https://app.contentful.com/spaces/v2h0bz1hqac3/content_types/padelRules) */
export type PadelRulesCampoArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** [See type definition](https://app.contentful.com/spaces/v2h0bz1hqac3/content_types/padelRules) */
export type PadelRulesLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


/** [See type definition](https://app.contentful.com/spaces/v2h0bz1hqac3/content_types/padelRules) */
export type PadelRulesPallaArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** [See type definition](https://app.contentful.com/spaces/v2h0bz1hqac3/content_types/padelRules) */
export type PadelRulesPunteggioArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** [See type definition](https://app.contentful.com/spaces/v2h0bz1hqac3/content_types/padelRules) */
export type PadelRulesRacchettaArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** [See type definition](https://app.contentful.com/spaces/v2h0bz1hqac3/content_types/padelRules) */
export type PadelRulesTitoloArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

export type PadelRulesCollection = {
  __typename?: 'PadelRulesCollection';
  items: Array<Maybe<PadelRules>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type PadelRulesFilter = {
  AND?: InputMaybe<Array<InputMaybe<PadelRulesFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<PadelRulesFilter>>>;
  campo?: InputMaybe<Scalars['String']['input']>;
  campo_contains?: InputMaybe<Scalars['String']['input']>;
  campo_exists?: InputMaybe<Scalars['Boolean']['input']>;
  campo_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  campo_not?: InputMaybe<Scalars['String']['input']>;
  campo_not_contains?: InputMaybe<Scalars['String']['input']>;
  campo_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  palla?: InputMaybe<Scalars['String']['input']>;
  palla_contains?: InputMaybe<Scalars['String']['input']>;
  palla_exists?: InputMaybe<Scalars['Boolean']['input']>;
  palla_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  palla_not?: InputMaybe<Scalars['String']['input']>;
  palla_not_contains?: InputMaybe<Scalars['String']['input']>;
  palla_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  punteggio?: InputMaybe<Scalars['String']['input']>;
  punteggio_contains?: InputMaybe<Scalars['String']['input']>;
  punteggio_exists?: InputMaybe<Scalars['Boolean']['input']>;
  punteggio_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  punteggio_not?: InputMaybe<Scalars['String']['input']>;
  punteggio_not_contains?: InputMaybe<Scalars['String']['input']>;
  punteggio_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  racchetta?: InputMaybe<Scalars['String']['input']>;
  racchetta_contains?: InputMaybe<Scalars['String']['input']>;
  racchetta_exists?: InputMaybe<Scalars['Boolean']['input']>;
  racchetta_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  racchetta_not?: InputMaybe<Scalars['String']['input']>;
  racchetta_not_contains?: InputMaybe<Scalars['String']['input']>;
  racchetta_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sys?: InputMaybe<SysFilter>;
  titolo?: InputMaybe<Scalars['String']['input']>;
  titolo_contains?: InputMaybe<Scalars['String']['input']>;
  titolo_exists?: InputMaybe<Scalars['Boolean']['input']>;
  titolo_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  titolo_not?: InputMaybe<Scalars['String']['input']>;
  titolo_not_contains?: InputMaybe<Scalars['String']['input']>;
  titolo_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type PadelRulesLinkingCollections = {
  __typename?: 'PadelRulesLinkingCollections';
  entryCollection?: Maybe<EntryCollection>;
};


export type PadelRulesLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export enum PadelRulesOrder {
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitoloAsc = 'titolo_ASC',
  TitoloDesc = 'titolo_DESC'
}

/** [See type definition](https://app.contentful.com/spaces/v2h0bz1hqac3/content_types/pool) */
export type Pool = Entry & _Node & {
  __typename?: 'Pool';
  _id: Scalars['ID']['output'];
  contentfulMetadata: ContentfulMetadata;
  linkedFrom?: Maybe<PoolLinkingCollections>;
  prezzi?: Maybe<Asset>;
  sys: Sys;
  testoSfondoNero?: Maybe<PoolTestoSfondoNero>;
  titolo?: Maybe<Scalars['String']['output']>;
};


/** [See type definition](https://app.contentful.com/spaces/v2h0bz1hqac3/content_types/pool) */
export type PoolLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


/** [See type definition](https://app.contentful.com/spaces/v2h0bz1hqac3/content_types/pool) */
export type PoolPrezziArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};


/** [See type definition](https://app.contentful.com/spaces/v2h0bz1hqac3/content_types/pool) */
export type PoolTestoSfondoNeroArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** [See type definition](https://app.contentful.com/spaces/v2h0bz1hqac3/content_types/pool) */
export type PoolTitoloArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

export type PoolCollection = {
  __typename?: 'PoolCollection';
  items: Array<Maybe<Pool>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type PoolFilter = {
  AND?: InputMaybe<Array<InputMaybe<PoolFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<PoolFilter>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  prezzi_exists?: InputMaybe<Scalars['Boolean']['input']>;
  sys?: InputMaybe<SysFilter>;
  testoSfondoNero_contains?: InputMaybe<Scalars['String']['input']>;
  testoSfondoNero_exists?: InputMaybe<Scalars['Boolean']['input']>;
  testoSfondoNero_not_contains?: InputMaybe<Scalars['String']['input']>;
  titolo?: InputMaybe<Scalars['String']['input']>;
  titolo_contains?: InputMaybe<Scalars['String']['input']>;
  titolo_exists?: InputMaybe<Scalars['Boolean']['input']>;
  titolo_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  titolo_not?: InputMaybe<Scalars['String']['input']>;
  titolo_not_contains?: InputMaybe<Scalars['String']['input']>;
  titolo_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type PoolLinkingCollections = {
  __typename?: 'PoolLinkingCollections';
  entryCollection?: Maybe<EntryCollection>;
};


export type PoolLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export enum PoolOrder {
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitoloAsc = 'titolo_ASC',
  TitoloDesc = 'titolo_DESC'
}

export type PoolTestoSfondoNero = {
  __typename?: 'PoolTestoSfondoNero';
  json: Scalars['JSON']['output'];
  links: PoolTestoSfondoNeroLinks;
};

export type PoolTestoSfondoNeroAssets = {
  __typename?: 'PoolTestoSfondoNeroAssets';
  block: Array<Maybe<Asset>>;
  hyperlink: Array<Maybe<Asset>>;
};

export type PoolTestoSfondoNeroEntries = {
  __typename?: 'PoolTestoSfondoNeroEntries';
  block: Array<Maybe<Entry>>;
  hyperlink: Array<Maybe<Entry>>;
  inline: Array<Maybe<Entry>>;
};

export type PoolTestoSfondoNeroLinks = {
  __typename?: 'PoolTestoSfondoNeroLinks';
  assets: PoolTestoSfondoNeroAssets;
  entries: PoolTestoSfondoNeroEntries;
  resources: PoolTestoSfondoNeroResources;
};

export type PoolTestoSfondoNeroResources = {
  __typename?: 'PoolTestoSfondoNeroResources';
  block: Array<PoolTestoSfondoNeroResourcesBlock>;
  hyperlink: Array<PoolTestoSfondoNeroResourcesHyperlink>;
  inline: Array<PoolTestoSfondoNeroResourcesInline>;
};

export type PoolTestoSfondoNeroResourcesBlock = ResourceLink & {
  __typename?: 'PoolTestoSfondoNeroResourcesBlock';
  sys: ResourceSys;
};

export type PoolTestoSfondoNeroResourcesHyperlink = ResourceLink & {
  __typename?: 'PoolTestoSfondoNeroResourcesHyperlink';
  sys: ResourceSys;
};

export type PoolTestoSfondoNeroResourcesInline = ResourceLink & {
  __typename?: 'PoolTestoSfondoNeroResourcesInline';
  sys: ResourceSys;
};

export type Query = {
  __typename?: 'Query';
  _node?: Maybe<_Node>;
  articoloStampa?: Maybe<ArticoloStampa>;
  articoloStampaCollection?: Maybe<ArticoloStampaCollection>;
  asset?: Maybe<Asset>;
  assetCollection?: Maybe<AssetCollection>;
  bloccoServizi?: Maybe<BloccoServizi>;
  bloccoServiziCollection?: Maybe<BloccoServiziCollection>;
  camera?: Maybe<Camera>;
  cameraCollection?: Maybe<CameraCollection>;
  campiPadel?: Maybe<CampiPadel>;
  campiPadelCollection?: Maybe<CampiPadelCollection>;
  comoShowCase?: Maybe<ComoShowCase>;
  comoShowCaseCollection?: Maybe<ComoShowCaseCollection>;
  contatti?: Maybe<Contatti>;
  contattiCollection?: Maybe<ContattiCollection>;
  descrizionePrincipale?: Maybe<DescrizionePrincipale>;
  descrizionePrincipaleCollection?: Maybe<DescrizionePrincipaleCollection>;
  entryCollection?: Maybe<EntryCollection>;
  eventoPiscina?: Maybe<EventoPiscina>;
  eventoPiscinaCollection?: Maybe<EventoPiscinaCollection>;
  expirience?: Maybe<Expirience>;
  expirienceCollection?: Maybe<ExpirienceCollection>;
  girella?: Maybe<Girella>;
  girellaCollection?: Maybe<GirellaCollection>;
  hotelPage?: Maybe<HotelPage>;
  hotelPageCollection?: Maybe<HotelPageCollection>;
  icone?: Maybe<Icone>;
  iconeCollection?: Maybe<IconeCollection>;
  imgTitleDesc?: Maybe<ImgTitleDesc>;
  imgTitleDescCollection?: Maybe<ImgTitleDescCollection>;
  istrutturi?: Maybe<Istrutturi>;
  istrutturiCollection?: Maybe<IstrutturiCollection>;
  lavoraConNoi?: Maybe<LavoraConNoi>;
  lavoraConNoiCollection?: Maybe<LavoraConNoiCollection>;
  padelAccademyDesc?: Maybe<PadelAccademyDesc>;
  padelAccademyDescCollection?: Maybe<PadelAccademyDescCollection>;
  padelRules?: Maybe<PadelRules>;
  padelRulesCollection?: Maybe<PadelRulesCollection>;
  pool?: Maybe<Pool>;
  poolCollection?: Maybe<PoolCollection>;
  ristoranteFilosofia?: Maybe<RistoranteFilosofia>;
  ristoranteFilosofiaCollection?: Maybe<RistoranteFilosofiaCollection>;
  servizi?: Maybe<Servizi>;
  serviziCollection?: Maybe<ServiziCollection>;
  testoRistorante?: Maybe<TestoRistorante>;
  testoRistoranteCollection?: Maybe<TestoRistoranteCollection>;
  traduzioniVarie?: Maybe<TraduzioniVarie>;
  traduzioniVarieCollection?: Maybe<TraduzioniVarieCollection>;
};


export type Query_NodeArgs = {
  id: Scalars['ID']['input'];
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};


export type QueryArticoloStampaArgs = {
  id: Scalars['String']['input'];
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};


export type QueryArticoloStampaCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<ArticoloStampaOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ArticoloStampaFilter>;
};


export type QueryAssetArgs = {
  id: Scalars['String']['input'];
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};


export type QueryAssetCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<AssetOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<AssetFilter>;
};


export type QueryBloccoServiziArgs = {
  id: Scalars['String']['input'];
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};


export type QueryBloccoServiziCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<BloccoServiziOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<BloccoServiziFilter>;
};


export type QueryCameraArgs = {
  id: Scalars['String']['input'];
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};


export type QueryCameraCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<CameraOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<CameraFilter>;
};


export type QueryCampiPadelArgs = {
  id: Scalars['String']['input'];
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};


export type QueryCampiPadelCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<CampiPadelOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<CampiPadelFilter>;
};


export type QueryComoShowCaseArgs = {
  id: Scalars['String']['input'];
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};


export type QueryComoShowCaseCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<ComoShowCaseOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ComoShowCaseFilter>;
};


export type QueryContattiArgs = {
  id: Scalars['String']['input'];
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};


export type QueryContattiCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<ContattiOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ContattiFilter>;
};


export type QueryDescrizionePrincipaleArgs = {
  id: Scalars['String']['input'];
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};


export type QueryDescrizionePrincipaleCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<DescrizionePrincipaleOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<DescrizionePrincipaleFilter>;
};


export type QueryEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<EntryOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<EntryFilter>;
};


export type QueryEventoPiscinaArgs = {
  id: Scalars['String']['input'];
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};


export type QueryEventoPiscinaCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<EventoPiscinaOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<EventoPiscinaFilter>;
};


export type QueryExpirienceArgs = {
  id: Scalars['String']['input'];
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};


export type QueryExpirienceCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<ExpirienceOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ExpirienceFilter>;
};


export type QueryGirellaArgs = {
  id: Scalars['String']['input'];
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};


export type QueryGirellaCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<GirellaOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<GirellaFilter>;
};


export type QueryHotelPageArgs = {
  id: Scalars['String']['input'];
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};


export type QueryHotelPageCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<HotelPageOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<HotelPageFilter>;
};


export type QueryIconeArgs = {
  id: Scalars['String']['input'];
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};


export type QueryIconeCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<IconeOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<IconeFilter>;
};


export type QueryImgTitleDescArgs = {
  id: Scalars['String']['input'];
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};


export type QueryImgTitleDescCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<ImgTitleDescOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ImgTitleDescFilter>;
};


export type QueryIstrutturiArgs = {
  id: Scalars['String']['input'];
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};


export type QueryIstrutturiCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<IstrutturiOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<IstrutturiFilter>;
};


export type QueryLavoraConNoiArgs = {
  id: Scalars['String']['input'];
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};


export type QueryLavoraConNoiCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<LavoraConNoiOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<LavoraConNoiFilter>;
};


export type QueryPadelAccademyDescArgs = {
  id: Scalars['String']['input'];
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};


export type QueryPadelAccademyDescCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<PadelAccademyDescOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<PadelAccademyDescFilter>;
};


export type QueryPadelRulesArgs = {
  id: Scalars['String']['input'];
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};


export type QueryPadelRulesCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<PadelRulesOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<PadelRulesFilter>;
};


export type QueryPoolArgs = {
  id: Scalars['String']['input'];
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};


export type QueryPoolCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<PoolOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<PoolFilter>;
};


export type QueryRistoranteFilosofiaArgs = {
  id: Scalars['String']['input'];
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};


export type QueryRistoranteFilosofiaCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<RistoranteFilosofiaOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<RistoranteFilosofiaFilter>;
};


export type QueryServiziArgs = {
  id: Scalars['String']['input'];
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};


export type QueryServiziCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<ServiziOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ServiziFilter>;
};


export type QueryTestoRistoranteArgs = {
  id: Scalars['String']['input'];
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};


export type QueryTestoRistoranteCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<TestoRistoranteOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<TestoRistoranteFilter>;
};


export type QueryTraduzioniVarieArgs = {
  id: Scalars['String']['input'];
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};


export type QueryTraduzioniVarieCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<TraduzioniVarieOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<TraduzioniVarieFilter>;
};

export type ResourceLink = {
  sys: ResourceSys;
};

export type ResourceSys = {
  __typename?: 'ResourceSys';
  linkType: Scalars['String']['output'];
  urn: Scalars['String']['output'];
};

/** [See type definition](https://app.contentful.com/spaces/v2h0bz1hqac3/content_types/ristoranteFilosofia) */
export type RistoranteFilosofia = Entry & _Node & {
  __typename?: 'RistoranteFilosofia';
  _id: Scalars['ID']['output'];
  contentfulMetadata: ContentfulMetadata;
  descrizioneBistrot?: Maybe<RistoranteFilosofiaDescrizioneBistrot>;
  descrizioneBreve?: Maybe<RistoranteFilosofiaDescrizioneBreve>;
  descrizioneLoungeBar?: Maybe<RistoranteFilosofiaDescrizioneLoungeBar>;
  descrizioneRistorante?: Maybe<RistoranteFilosofiaDescrizioneRistorante>;
  linkedFrom?: Maybe<RistoranteFilosofiaLinkingCollections>;
  menuBistrot?: Maybe<Asset>;
  menuLoungeBar?: Maybe<Asset>;
  menuRis?: Maybe<Asset>;
  prenotaBistrot?: Maybe<Scalars['String']['output']>;
  prenotaRistorante?: Maybe<Scalars['String']['output']>;
  sys: Sys;
  testoFilosofia?: Maybe<Scalars['String']['output']>;
  titolo?: Maybe<Scalars['String']['output']>;
};


/** [See type definition](https://app.contentful.com/spaces/v2h0bz1hqac3/content_types/ristoranteFilosofia) */
export type RistoranteFilosofiaDescrizioneBistrotArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** [See type definition](https://app.contentful.com/spaces/v2h0bz1hqac3/content_types/ristoranteFilosofia) */
export type RistoranteFilosofiaDescrizioneBreveArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** [See type definition](https://app.contentful.com/spaces/v2h0bz1hqac3/content_types/ristoranteFilosofia) */
export type RistoranteFilosofiaDescrizioneLoungeBarArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** [See type definition](https://app.contentful.com/spaces/v2h0bz1hqac3/content_types/ristoranteFilosofia) */
export type RistoranteFilosofiaDescrizioneRistoranteArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** [See type definition](https://app.contentful.com/spaces/v2h0bz1hqac3/content_types/ristoranteFilosofia) */
export type RistoranteFilosofiaLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


/** [See type definition](https://app.contentful.com/spaces/v2h0bz1hqac3/content_types/ristoranteFilosofia) */
export type RistoranteFilosofiaMenuBistrotArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};


/** [See type definition](https://app.contentful.com/spaces/v2h0bz1hqac3/content_types/ristoranteFilosofia) */
export type RistoranteFilosofiaMenuLoungeBarArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};


/** [See type definition](https://app.contentful.com/spaces/v2h0bz1hqac3/content_types/ristoranteFilosofia) */
export type RistoranteFilosofiaMenuRisArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};


/** [See type definition](https://app.contentful.com/spaces/v2h0bz1hqac3/content_types/ristoranteFilosofia) */
export type RistoranteFilosofiaPrenotaBistrotArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** [See type definition](https://app.contentful.com/spaces/v2h0bz1hqac3/content_types/ristoranteFilosofia) */
export type RistoranteFilosofiaPrenotaRistoranteArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** [See type definition](https://app.contentful.com/spaces/v2h0bz1hqac3/content_types/ristoranteFilosofia) */
export type RistoranteFilosofiaTestoFilosofiaArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** [See type definition](https://app.contentful.com/spaces/v2h0bz1hqac3/content_types/ristoranteFilosofia) */
export type RistoranteFilosofiaTitoloArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

export type RistoranteFilosofiaCollection = {
  __typename?: 'RistoranteFilosofiaCollection';
  items: Array<Maybe<RistoranteFilosofia>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type RistoranteFilosofiaDescrizioneBistrot = {
  __typename?: 'RistoranteFilosofiaDescrizioneBistrot';
  json: Scalars['JSON']['output'];
  links: RistoranteFilosofiaDescrizioneBistrotLinks;
};

export type RistoranteFilosofiaDescrizioneBistrotAssets = {
  __typename?: 'RistoranteFilosofiaDescrizioneBistrotAssets';
  block: Array<Maybe<Asset>>;
  hyperlink: Array<Maybe<Asset>>;
};

export type RistoranteFilosofiaDescrizioneBistrotEntries = {
  __typename?: 'RistoranteFilosofiaDescrizioneBistrotEntries';
  block: Array<Maybe<Entry>>;
  hyperlink: Array<Maybe<Entry>>;
  inline: Array<Maybe<Entry>>;
};

export type RistoranteFilosofiaDescrizioneBistrotLinks = {
  __typename?: 'RistoranteFilosofiaDescrizioneBistrotLinks';
  assets: RistoranteFilosofiaDescrizioneBistrotAssets;
  entries: RistoranteFilosofiaDescrizioneBistrotEntries;
  resources: RistoranteFilosofiaDescrizioneBistrotResources;
};

export type RistoranteFilosofiaDescrizioneBistrotResources = {
  __typename?: 'RistoranteFilosofiaDescrizioneBistrotResources';
  block: Array<RistoranteFilosofiaDescrizioneBistrotResourcesBlock>;
  hyperlink: Array<RistoranteFilosofiaDescrizioneBistrotResourcesHyperlink>;
  inline: Array<RistoranteFilosofiaDescrizioneBistrotResourcesInline>;
};

export type RistoranteFilosofiaDescrizioneBistrotResourcesBlock = ResourceLink & {
  __typename?: 'RistoranteFilosofiaDescrizioneBistrotResourcesBlock';
  sys: ResourceSys;
};

export type RistoranteFilosofiaDescrizioneBistrotResourcesHyperlink = ResourceLink & {
  __typename?: 'RistoranteFilosofiaDescrizioneBistrotResourcesHyperlink';
  sys: ResourceSys;
};

export type RistoranteFilosofiaDescrizioneBistrotResourcesInline = ResourceLink & {
  __typename?: 'RistoranteFilosofiaDescrizioneBistrotResourcesInline';
  sys: ResourceSys;
};

export type RistoranteFilosofiaDescrizioneBreve = {
  __typename?: 'RistoranteFilosofiaDescrizioneBreve';
  json: Scalars['JSON']['output'];
  links: RistoranteFilosofiaDescrizioneBreveLinks;
};

export type RistoranteFilosofiaDescrizioneBreveAssets = {
  __typename?: 'RistoranteFilosofiaDescrizioneBreveAssets';
  block: Array<Maybe<Asset>>;
  hyperlink: Array<Maybe<Asset>>;
};

export type RistoranteFilosofiaDescrizioneBreveEntries = {
  __typename?: 'RistoranteFilosofiaDescrizioneBreveEntries';
  block: Array<Maybe<Entry>>;
  hyperlink: Array<Maybe<Entry>>;
  inline: Array<Maybe<Entry>>;
};

export type RistoranteFilosofiaDescrizioneBreveLinks = {
  __typename?: 'RistoranteFilosofiaDescrizioneBreveLinks';
  assets: RistoranteFilosofiaDescrizioneBreveAssets;
  entries: RistoranteFilosofiaDescrizioneBreveEntries;
  resources: RistoranteFilosofiaDescrizioneBreveResources;
};

export type RistoranteFilosofiaDescrizioneBreveResources = {
  __typename?: 'RistoranteFilosofiaDescrizioneBreveResources';
  block: Array<RistoranteFilosofiaDescrizioneBreveResourcesBlock>;
  hyperlink: Array<RistoranteFilosofiaDescrizioneBreveResourcesHyperlink>;
  inline: Array<RistoranteFilosofiaDescrizioneBreveResourcesInline>;
};

export type RistoranteFilosofiaDescrizioneBreveResourcesBlock = ResourceLink & {
  __typename?: 'RistoranteFilosofiaDescrizioneBreveResourcesBlock';
  sys: ResourceSys;
};

export type RistoranteFilosofiaDescrizioneBreveResourcesHyperlink = ResourceLink & {
  __typename?: 'RistoranteFilosofiaDescrizioneBreveResourcesHyperlink';
  sys: ResourceSys;
};

export type RistoranteFilosofiaDescrizioneBreveResourcesInline = ResourceLink & {
  __typename?: 'RistoranteFilosofiaDescrizioneBreveResourcesInline';
  sys: ResourceSys;
};

export type RistoranteFilosofiaDescrizioneLoungeBar = {
  __typename?: 'RistoranteFilosofiaDescrizioneLoungeBar';
  json: Scalars['JSON']['output'];
  links: RistoranteFilosofiaDescrizioneLoungeBarLinks;
};

export type RistoranteFilosofiaDescrizioneLoungeBarAssets = {
  __typename?: 'RistoranteFilosofiaDescrizioneLoungeBarAssets';
  block: Array<Maybe<Asset>>;
  hyperlink: Array<Maybe<Asset>>;
};

export type RistoranteFilosofiaDescrizioneLoungeBarEntries = {
  __typename?: 'RistoranteFilosofiaDescrizioneLoungeBarEntries';
  block: Array<Maybe<Entry>>;
  hyperlink: Array<Maybe<Entry>>;
  inline: Array<Maybe<Entry>>;
};

export type RistoranteFilosofiaDescrizioneLoungeBarLinks = {
  __typename?: 'RistoranteFilosofiaDescrizioneLoungeBarLinks';
  assets: RistoranteFilosofiaDescrizioneLoungeBarAssets;
  entries: RistoranteFilosofiaDescrizioneLoungeBarEntries;
  resources: RistoranteFilosofiaDescrizioneLoungeBarResources;
};

export type RistoranteFilosofiaDescrizioneLoungeBarResources = {
  __typename?: 'RistoranteFilosofiaDescrizioneLoungeBarResources';
  block: Array<RistoranteFilosofiaDescrizioneLoungeBarResourcesBlock>;
  hyperlink: Array<RistoranteFilosofiaDescrizioneLoungeBarResourcesHyperlink>;
  inline: Array<RistoranteFilosofiaDescrizioneLoungeBarResourcesInline>;
};

export type RistoranteFilosofiaDescrizioneLoungeBarResourcesBlock = ResourceLink & {
  __typename?: 'RistoranteFilosofiaDescrizioneLoungeBarResourcesBlock';
  sys: ResourceSys;
};

export type RistoranteFilosofiaDescrizioneLoungeBarResourcesHyperlink = ResourceLink & {
  __typename?: 'RistoranteFilosofiaDescrizioneLoungeBarResourcesHyperlink';
  sys: ResourceSys;
};

export type RistoranteFilosofiaDescrizioneLoungeBarResourcesInline = ResourceLink & {
  __typename?: 'RistoranteFilosofiaDescrizioneLoungeBarResourcesInline';
  sys: ResourceSys;
};

export type RistoranteFilosofiaDescrizioneRistorante = {
  __typename?: 'RistoranteFilosofiaDescrizioneRistorante';
  json: Scalars['JSON']['output'];
  links: RistoranteFilosofiaDescrizioneRistoranteLinks;
};

export type RistoranteFilosofiaDescrizioneRistoranteAssets = {
  __typename?: 'RistoranteFilosofiaDescrizioneRistoranteAssets';
  block: Array<Maybe<Asset>>;
  hyperlink: Array<Maybe<Asset>>;
};

export type RistoranteFilosofiaDescrizioneRistoranteEntries = {
  __typename?: 'RistoranteFilosofiaDescrizioneRistoranteEntries';
  block: Array<Maybe<Entry>>;
  hyperlink: Array<Maybe<Entry>>;
  inline: Array<Maybe<Entry>>;
};

export type RistoranteFilosofiaDescrizioneRistoranteLinks = {
  __typename?: 'RistoranteFilosofiaDescrizioneRistoranteLinks';
  assets: RistoranteFilosofiaDescrizioneRistoranteAssets;
  entries: RistoranteFilosofiaDescrizioneRistoranteEntries;
  resources: RistoranteFilosofiaDescrizioneRistoranteResources;
};

export type RistoranteFilosofiaDescrizioneRistoranteResources = {
  __typename?: 'RistoranteFilosofiaDescrizioneRistoranteResources';
  block: Array<RistoranteFilosofiaDescrizioneRistoranteResourcesBlock>;
  hyperlink: Array<RistoranteFilosofiaDescrizioneRistoranteResourcesHyperlink>;
  inline: Array<RistoranteFilosofiaDescrizioneRistoranteResourcesInline>;
};

export type RistoranteFilosofiaDescrizioneRistoranteResourcesBlock = ResourceLink & {
  __typename?: 'RistoranteFilosofiaDescrizioneRistoranteResourcesBlock';
  sys: ResourceSys;
};

export type RistoranteFilosofiaDescrizioneRistoranteResourcesHyperlink = ResourceLink & {
  __typename?: 'RistoranteFilosofiaDescrizioneRistoranteResourcesHyperlink';
  sys: ResourceSys;
};

export type RistoranteFilosofiaDescrizioneRistoranteResourcesInline = ResourceLink & {
  __typename?: 'RistoranteFilosofiaDescrizioneRistoranteResourcesInline';
  sys: ResourceSys;
};

export type RistoranteFilosofiaFilter = {
  AND?: InputMaybe<Array<InputMaybe<RistoranteFilosofiaFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<RistoranteFilosofiaFilter>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  descrizioneBistrot_contains?: InputMaybe<Scalars['String']['input']>;
  descrizioneBistrot_exists?: InputMaybe<Scalars['Boolean']['input']>;
  descrizioneBistrot_not_contains?: InputMaybe<Scalars['String']['input']>;
  descrizioneBreve_contains?: InputMaybe<Scalars['String']['input']>;
  descrizioneBreve_exists?: InputMaybe<Scalars['Boolean']['input']>;
  descrizioneBreve_not_contains?: InputMaybe<Scalars['String']['input']>;
  descrizioneLoungeBar_contains?: InputMaybe<Scalars['String']['input']>;
  descrizioneLoungeBar_exists?: InputMaybe<Scalars['Boolean']['input']>;
  descrizioneLoungeBar_not_contains?: InputMaybe<Scalars['String']['input']>;
  descrizioneRistorante_contains?: InputMaybe<Scalars['String']['input']>;
  descrizioneRistorante_exists?: InputMaybe<Scalars['Boolean']['input']>;
  descrizioneRistorante_not_contains?: InputMaybe<Scalars['String']['input']>;
  menuBistrot_exists?: InputMaybe<Scalars['Boolean']['input']>;
  menuLoungeBar_exists?: InputMaybe<Scalars['Boolean']['input']>;
  menuRis_exists?: InputMaybe<Scalars['Boolean']['input']>;
  prenotaBistrot?: InputMaybe<Scalars['String']['input']>;
  prenotaBistrot_contains?: InputMaybe<Scalars['String']['input']>;
  prenotaBistrot_exists?: InputMaybe<Scalars['Boolean']['input']>;
  prenotaBistrot_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  prenotaBistrot_not?: InputMaybe<Scalars['String']['input']>;
  prenotaBistrot_not_contains?: InputMaybe<Scalars['String']['input']>;
  prenotaBistrot_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  prenotaRistorante?: InputMaybe<Scalars['String']['input']>;
  prenotaRistorante_contains?: InputMaybe<Scalars['String']['input']>;
  prenotaRistorante_exists?: InputMaybe<Scalars['Boolean']['input']>;
  prenotaRistorante_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  prenotaRistorante_not?: InputMaybe<Scalars['String']['input']>;
  prenotaRistorante_not_contains?: InputMaybe<Scalars['String']['input']>;
  prenotaRistorante_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sys?: InputMaybe<SysFilter>;
  testoFilosofia?: InputMaybe<Scalars['String']['input']>;
  testoFilosofia_contains?: InputMaybe<Scalars['String']['input']>;
  testoFilosofia_exists?: InputMaybe<Scalars['Boolean']['input']>;
  testoFilosofia_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  testoFilosofia_not?: InputMaybe<Scalars['String']['input']>;
  testoFilosofia_not_contains?: InputMaybe<Scalars['String']['input']>;
  testoFilosofia_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  titolo?: InputMaybe<Scalars['String']['input']>;
  titolo_contains?: InputMaybe<Scalars['String']['input']>;
  titolo_exists?: InputMaybe<Scalars['Boolean']['input']>;
  titolo_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  titolo_not?: InputMaybe<Scalars['String']['input']>;
  titolo_not_contains?: InputMaybe<Scalars['String']['input']>;
  titolo_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type RistoranteFilosofiaLinkingCollections = {
  __typename?: 'RistoranteFilosofiaLinkingCollections';
  entryCollection?: Maybe<EntryCollection>;
};


export type RistoranteFilosofiaLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export enum RistoranteFilosofiaOrder {
  PrenotaBistrotAsc = 'prenotaBistrot_ASC',
  PrenotaBistrotDesc = 'prenotaBistrot_DESC',
  PrenotaRistoranteAsc = 'prenotaRistorante_ASC',
  PrenotaRistoranteDesc = 'prenotaRistorante_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitoloAsc = 'titolo_ASC',
  TitoloDesc = 'titolo_DESC'
}

/** [See type definition](https://app.contentful.com/spaces/v2h0bz1hqac3/content_types/servizi) */
export type Servizi = Entry & _Node & {
  __typename?: 'Servizi';
  _id: Scalars['ID']['output'];
  bistrotERistorante?: Maybe<Scalars['String']['output']>;
  bistrotERistoranteTitolo?: Maybe<Scalars['String']['output']>;
  contentfulMetadata: ContentfulMetadata;
  coworking?: Maybe<Scalars['String']['output']>;
  coworkingTitolo?: Maybe<Scalars['String']['output']>;
  fisioterapistaEOsteopata?: Maybe<Scalars['String']['output']>;
  fisioterapistaEOsteopataTitolo?: Maybe<Scalars['String']['output']>;
  linkedFrom?: Maybe<ServiziLinkingCollections>;
  padelArena?: Maybe<Scalars['String']['output']>;
  padelArenaTitolo?: Maybe<Scalars['String']['output']>;
  palestra?: Maybe<Scalars['String']['output']>;
  palestraTitolo?: Maybe<Scalars['String']['output']>;
  sys: Sys;
  testoBottone?: Maybe<Scalars['String']['output']>;
  titolo?: Maybe<Scalars['String']['output']>;
};


/** [See type definition](https://app.contentful.com/spaces/v2h0bz1hqac3/content_types/servizi) */
export type ServiziBistrotERistoranteArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** [See type definition](https://app.contentful.com/spaces/v2h0bz1hqac3/content_types/servizi) */
export type ServiziBistrotERistoranteTitoloArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** [See type definition](https://app.contentful.com/spaces/v2h0bz1hqac3/content_types/servizi) */
export type ServiziCoworkingArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** [See type definition](https://app.contentful.com/spaces/v2h0bz1hqac3/content_types/servizi) */
export type ServiziCoworkingTitoloArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** [See type definition](https://app.contentful.com/spaces/v2h0bz1hqac3/content_types/servizi) */
export type ServiziFisioterapistaEOsteopataArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** [See type definition](https://app.contentful.com/spaces/v2h0bz1hqac3/content_types/servizi) */
export type ServiziFisioterapistaEOsteopataTitoloArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** [See type definition](https://app.contentful.com/spaces/v2h0bz1hqac3/content_types/servizi) */
export type ServiziLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


/** [See type definition](https://app.contentful.com/spaces/v2h0bz1hqac3/content_types/servizi) */
export type ServiziPadelArenaArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** [See type definition](https://app.contentful.com/spaces/v2h0bz1hqac3/content_types/servizi) */
export type ServiziPadelArenaTitoloArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** [See type definition](https://app.contentful.com/spaces/v2h0bz1hqac3/content_types/servizi) */
export type ServiziPalestraArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** [See type definition](https://app.contentful.com/spaces/v2h0bz1hqac3/content_types/servizi) */
export type ServiziPalestraTitoloArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** [See type definition](https://app.contentful.com/spaces/v2h0bz1hqac3/content_types/servizi) */
export type ServiziTestoBottoneArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** [See type definition](https://app.contentful.com/spaces/v2h0bz1hqac3/content_types/servizi) */
export type ServiziTitoloArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

export type ServiziCollection = {
  __typename?: 'ServiziCollection';
  items: Array<Maybe<Servizi>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type ServiziFilter = {
  AND?: InputMaybe<Array<InputMaybe<ServiziFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<ServiziFilter>>>;
  bistrotERistorante?: InputMaybe<Scalars['String']['input']>;
  bistrotERistoranteTitolo?: InputMaybe<Scalars['String']['input']>;
  bistrotERistoranteTitolo_contains?: InputMaybe<Scalars['String']['input']>;
  bistrotERistoranteTitolo_exists?: InputMaybe<Scalars['Boolean']['input']>;
  bistrotERistoranteTitolo_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  bistrotERistoranteTitolo_not?: InputMaybe<Scalars['String']['input']>;
  bistrotERistoranteTitolo_not_contains?: InputMaybe<Scalars['String']['input']>;
  bistrotERistoranteTitolo_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  bistrotERistorante_contains?: InputMaybe<Scalars['String']['input']>;
  bistrotERistorante_exists?: InputMaybe<Scalars['Boolean']['input']>;
  bistrotERistorante_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  bistrotERistorante_not?: InputMaybe<Scalars['String']['input']>;
  bistrotERistorante_not_contains?: InputMaybe<Scalars['String']['input']>;
  bistrotERistorante_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  coworking?: InputMaybe<Scalars['String']['input']>;
  coworkingTitolo?: InputMaybe<Scalars['String']['input']>;
  coworkingTitolo_contains?: InputMaybe<Scalars['String']['input']>;
  coworkingTitolo_exists?: InputMaybe<Scalars['Boolean']['input']>;
  coworkingTitolo_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  coworkingTitolo_not?: InputMaybe<Scalars['String']['input']>;
  coworkingTitolo_not_contains?: InputMaybe<Scalars['String']['input']>;
  coworkingTitolo_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  coworking_contains?: InputMaybe<Scalars['String']['input']>;
  coworking_exists?: InputMaybe<Scalars['Boolean']['input']>;
  coworking_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  coworking_not?: InputMaybe<Scalars['String']['input']>;
  coworking_not_contains?: InputMaybe<Scalars['String']['input']>;
  coworking_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fisioterapistaEOsteopata?: InputMaybe<Scalars['String']['input']>;
  fisioterapistaEOsteopataTitolo?: InputMaybe<Scalars['String']['input']>;
  fisioterapistaEOsteopataTitolo_contains?: InputMaybe<Scalars['String']['input']>;
  fisioterapistaEOsteopataTitolo_exists?: InputMaybe<Scalars['Boolean']['input']>;
  fisioterapistaEOsteopataTitolo_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fisioterapistaEOsteopataTitolo_not?: InputMaybe<Scalars['String']['input']>;
  fisioterapistaEOsteopataTitolo_not_contains?: InputMaybe<Scalars['String']['input']>;
  fisioterapistaEOsteopataTitolo_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fisioterapistaEOsteopata_contains?: InputMaybe<Scalars['String']['input']>;
  fisioterapistaEOsteopata_exists?: InputMaybe<Scalars['Boolean']['input']>;
  fisioterapistaEOsteopata_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fisioterapistaEOsteopata_not?: InputMaybe<Scalars['String']['input']>;
  fisioterapistaEOsteopata_not_contains?: InputMaybe<Scalars['String']['input']>;
  fisioterapistaEOsteopata_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  padelArena?: InputMaybe<Scalars['String']['input']>;
  padelArenaTitolo?: InputMaybe<Scalars['String']['input']>;
  padelArenaTitolo_contains?: InputMaybe<Scalars['String']['input']>;
  padelArenaTitolo_exists?: InputMaybe<Scalars['Boolean']['input']>;
  padelArenaTitolo_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  padelArenaTitolo_not?: InputMaybe<Scalars['String']['input']>;
  padelArenaTitolo_not_contains?: InputMaybe<Scalars['String']['input']>;
  padelArenaTitolo_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  padelArena_contains?: InputMaybe<Scalars['String']['input']>;
  padelArena_exists?: InputMaybe<Scalars['Boolean']['input']>;
  padelArena_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  padelArena_not?: InputMaybe<Scalars['String']['input']>;
  padelArena_not_contains?: InputMaybe<Scalars['String']['input']>;
  padelArena_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  palestra?: InputMaybe<Scalars['String']['input']>;
  palestraTitolo?: InputMaybe<Scalars['String']['input']>;
  palestraTitolo_contains?: InputMaybe<Scalars['String']['input']>;
  palestraTitolo_exists?: InputMaybe<Scalars['Boolean']['input']>;
  palestraTitolo_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  palestraTitolo_not?: InputMaybe<Scalars['String']['input']>;
  palestraTitolo_not_contains?: InputMaybe<Scalars['String']['input']>;
  palestraTitolo_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  palestra_contains?: InputMaybe<Scalars['String']['input']>;
  palestra_exists?: InputMaybe<Scalars['Boolean']['input']>;
  palestra_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  palestra_not?: InputMaybe<Scalars['String']['input']>;
  palestra_not_contains?: InputMaybe<Scalars['String']['input']>;
  palestra_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sys?: InputMaybe<SysFilter>;
  testoBottone?: InputMaybe<Scalars['String']['input']>;
  testoBottone_contains?: InputMaybe<Scalars['String']['input']>;
  testoBottone_exists?: InputMaybe<Scalars['Boolean']['input']>;
  testoBottone_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  testoBottone_not?: InputMaybe<Scalars['String']['input']>;
  testoBottone_not_contains?: InputMaybe<Scalars['String']['input']>;
  testoBottone_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  titolo?: InputMaybe<Scalars['String']['input']>;
  titolo_contains?: InputMaybe<Scalars['String']['input']>;
  titolo_exists?: InputMaybe<Scalars['Boolean']['input']>;
  titolo_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  titolo_not?: InputMaybe<Scalars['String']['input']>;
  titolo_not_contains?: InputMaybe<Scalars['String']['input']>;
  titolo_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type ServiziLinkingCollections = {
  __typename?: 'ServiziLinkingCollections';
  entryCollection?: Maybe<EntryCollection>;
};


export type ServiziLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export enum ServiziOrder {
  BistrotERistoranteTitoloAsc = 'bistrotERistoranteTitolo_ASC',
  BistrotERistoranteTitoloDesc = 'bistrotERistoranteTitolo_DESC',
  CoworkingTitoloAsc = 'coworkingTitolo_ASC',
  CoworkingTitoloDesc = 'coworkingTitolo_DESC',
  FisioterapistaEOsteopataTitoloAsc = 'fisioterapistaEOsteopataTitolo_ASC',
  FisioterapistaEOsteopataTitoloDesc = 'fisioterapistaEOsteopataTitolo_DESC',
  PadelArenaTitoloAsc = 'padelArenaTitolo_ASC',
  PadelArenaTitoloDesc = 'padelArenaTitolo_DESC',
  PalestraTitoloAsc = 'palestraTitolo_ASC',
  PalestraTitoloDesc = 'palestraTitolo_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TestoBottoneAsc = 'testoBottone_ASC',
  TestoBottoneDesc = 'testoBottone_DESC',
  TitoloAsc = 'titolo_ASC',
  TitoloDesc = 'titolo_DESC'
}

export type Sys = {
  __typename?: 'Sys';
  environmentId: Scalars['String']['output'];
  firstPublishedAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['String']['output'];
  /** The locale that was requested. */
  locale?: Maybe<Scalars['String']['output']>;
  publishedAt?: Maybe<Scalars['DateTime']['output']>;
  publishedVersion?: Maybe<Scalars['Int']['output']>;
  spaceId: Scalars['String']['output'];
};

export type SysFilter = {
  firstPublishedAt?: InputMaybe<Scalars['DateTime']['input']>;
  firstPublishedAt_exists?: InputMaybe<Scalars['Boolean']['input']>;
  firstPublishedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  firstPublishedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  firstPublishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  firstPublishedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  firstPublishedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  firstPublishedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  firstPublishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  id?: InputMaybe<Scalars['String']['input']>;
  id_contains?: InputMaybe<Scalars['String']['input']>;
  id_exists?: InputMaybe<Scalars['Boolean']['input']>;
  id_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  id_not?: InputMaybe<Scalars['String']['input']>;
  id_not_contains?: InputMaybe<Scalars['String']['input']>;
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>;
  publishedAt_exists?: InputMaybe<Scalars['Boolean']['input']>;
  publishedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  publishedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  publishedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  publishedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  publishedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  publishedVersion?: InputMaybe<Scalars['Float']['input']>;
  publishedVersion_exists?: InputMaybe<Scalars['Boolean']['input']>;
  publishedVersion_gt?: InputMaybe<Scalars['Float']['input']>;
  publishedVersion_gte?: InputMaybe<Scalars['Float']['input']>;
  publishedVersion_in?: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
  publishedVersion_lt?: InputMaybe<Scalars['Float']['input']>;
  publishedVersion_lte?: InputMaybe<Scalars['Float']['input']>;
  publishedVersion_not?: InputMaybe<Scalars['Float']['input']>;
  publishedVersion_not_in?: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
};

/** [See type definition](https://app.contentful.com/spaces/v2h0bz1hqac3/content_types/testoRistorante) */
export type TestoRistorante = Entry & _Node & {
  __typename?: 'TestoRistorante';
  _id: Scalars['ID']['output'];
  contentfulMetadata: ContentfulMetadata;
  linkedFrom?: Maybe<TestoRistoranteLinkingCollections>;
  sottoTitolo?: Maybe<Scalars['String']['output']>;
  sys: Sys;
  testo?: Maybe<Scalars['String']['output']>;
  titolo?: Maybe<Scalars['String']['output']>;
};


/** [See type definition](https://app.contentful.com/spaces/v2h0bz1hqac3/content_types/testoRistorante) */
export type TestoRistoranteLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


/** [See type definition](https://app.contentful.com/spaces/v2h0bz1hqac3/content_types/testoRistorante) */
export type TestoRistoranteSottoTitoloArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** [See type definition](https://app.contentful.com/spaces/v2h0bz1hqac3/content_types/testoRistorante) */
export type TestoRistoranteTestoArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** [See type definition](https://app.contentful.com/spaces/v2h0bz1hqac3/content_types/testoRistorante) */
export type TestoRistoranteTitoloArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

export type TestoRistoranteCollection = {
  __typename?: 'TestoRistoranteCollection';
  items: Array<Maybe<TestoRistorante>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type TestoRistoranteFilter = {
  AND?: InputMaybe<Array<InputMaybe<TestoRistoranteFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<TestoRistoranteFilter>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  sottoTitolo?: InputMaybe<Scalars['String']['input']>;
  sottoTitolo_contains?: InputMaybe<Scalars['String']['input']>;
  sottoTitolo_exists?: InputMaybe<Scalars['Boolean']['input']>;
  sottoTitolo_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sottoTitolo_not?: InputMaybe<Scalars['String']['input']>;
  sottoTitolo_not_contains?: InputMaybe<Scalars['String']['input']>;
  sottoTitolo_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sys?: InputMaybe<SysFilter>;
  testo?: InputMaybe<Scalars['String']['input']>;
  testo_contains?: InputMaybe<Scalars['String']['input']>;
  testo_exists?: InputMaybe<Scalars['Boolean']['input']>;
  testo_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  testo_not?: InputMaybe<Scalars['String']['input']>;
  testo_not_contains?: InputMaybe<Scalars['String']['input']>;
  testo_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  titolo?: InputMaybe<Scalars['String']['input']>;
  titolo_contains?: InputMaybe<Scalars['String']['input']>;
  titolo_exists?: InputMaybe<Scalars['Boolean']['input']>;
  titolo_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  titolo_not?: InputMaybe<Scalars['String']['input']>;
  titolo_not_contains?: InputMaybe<Scalars['String']['input']>;
  titolo_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type TestoRistoranteLinkingCollections = {
  __typename?: 'TestoRistoranteLinkingCollections';
  entryCollection?: Maybe<EntryCollection>;
};


export type TestoRistoranteLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export enum TestoRistoranteOrder {
  SottoTitoloAsc = 'sottoTitolo_ASC',
  SottoTitoloDesc = 'sottoTitolo_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitoloAsc = 'titolo_ASC',
  TitoloDesc = 'titolo_DESC'
}

/** [See type definition](https://app.contentful.com/spaces/v2h0bz1hqac3/content_types/traduzioniVarie) */
export type TraduzioniVarie = Entry & _Node & {
  __typename?: 'TraduzioniVarie';
  _id: Scalars['ID']['output'];
  acquista?: Maybe<Scalars['String']['output']>;
  chiama?: Maybe<Scalars['String']['output']>;
  contatti?: Maybe<Scalars['String']['output']>;
  contentfulMetadata: ContentfulMetadata;
  esperienze?: Maybe<Scalars['String']['output']>;
  esperienzeSottoTitolo?: Maybe<Scalars['String']['output']>;
  hotel?: Maybe<Scalars['String']['output']>;
  linkedFrom?: Maybe<TraduzioniVarieLinkingCollections>;
  nostriPartner?: Maybe<Scalars['String']['output']>;
  padel?: Maybe<Scalars['String']['output']>;
  piscina?: Maybe<Scalars['String']['output']>;
  prenota?: Maybe<Scalars['String']['output']>;
  prezzi?: Maybe<Scalars['String']['output']>;
  richiediInformazioni?: Maybe<Scalars['String']['output']>;
  ristorante?: Maybe<Scalars['String']['output']>;
  scopriDiPi?: Maybe<Scalars['String']['output']>;
  scuderiaBlu?: Maybe<Scalars['String']['output']>;
  seguici?: Maybe<Scalars['String']['output']>;
  servizi?: Maybe<Scalars['String']['output']>;
  sys: Sys;
  vuoiDiventarePartner?: Maybe<TraduzioniVarieVuoiDiventarePartner>;
};


/** [See type definition](https://app.contentful.com/spaces/v2h0bz1hqac3/content_types/traduzioniVarie) */
export type TraduzioniVarieAcquistaArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** [See type definition](https://app.contentful.com/spaces/v2h0bz1hqac3/content_types/traduzioniVarie) */
export type TraduzioniVarieChiamaArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** [See type definition](https://app.contentful.com/spaces/v2h0bz1hqac3/content_types/traduzioniVarie) */
export type TraduzioniVarieContattiArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** [See type definition](https://app.contentful.com/spaces/v2h0bz1hqac3/content_types/traduzioniVarie) */
export type TraduzioniVarieEsperienzeArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** [See type definition](https://app.contentful.com/spaces/v2h0bz1hqac3/content_types/traduzioniVarie) */
export type TraduzioniVarieEsperienzeSottoTitoloArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** [See type definition](https://app.contentful.com/spaces/v2h0bz1hqac3/content_types/traduzioniVarie) */
export type TraduzioniVarieHotelArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** [See type definition](https://app.contentful.com/spaces/v2h0bz1hqac3/content_types/traduzioniVarie) */
export type TraduzioniVarieLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


/** [See type definition](https://app.contentful.com/spaces/v2h0bz1hqac3/content_types/traduzioniVarie) */
export type TraduzioniVarieNostriPartnerArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** [See type definition](https://app.contentful.com/spaces/v2h0bz1hqac3/content_types/traduzioniVarie) */
export type TraduzioniVariePadelArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** [See type definition](https://app.contentful.com/spaces/v2h0bz1hqac3/content_types/traduzioniVarie) */
export type TraduzioniVariePiscinaArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** [See type definition](https://app.contentful.com/spaces/v2h0bz1hqac3/content_types/traduzioniVarie) */
export type TraduzioniVariePrenotaArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** [See type definition](https://app.contentful.com/spaces/v2h0bz1hqac3/content_types/traduzioniVarie) */
export type TraduzioniVariePrezziArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** [See type definition](https://app.contentful.com/spaces/v2h0bz1hqac3/content_types/traduzioniVarie) */
export type TraduzioniVarieRichiediInformazioniArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** [See type definition](https://app.contentful.com/spaces/v2h0bz1hqac3/content_types/traduzioniVarie) */
export type TraduzioniVarieRistoranteArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** [See type definition](https://app.contentful.com/spaces/v2h0bz1hqac3/content_types/traduzioniVarie) */
export type TraduzioniVarieScopriDiPiArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** [See type definition](https://app.contentful.com/spaces/v2h0bz1hqac3/content_types/traduzioniVarie) */
export type TraduzioniVarieScuderiaBluArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** [See type definition](https://app.contentful.com/spaces/v2h0bz1hqac3/content_types/traduzioniVarie) */
export type TraduzioniVarieSeguiciArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** [See type definition](https://app.contentful.com/spaces/v2h0bz1hqac3/content_types/traduzioniVarie) */
export type TraduzioniVarieServiziArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** [See type definition](https://app.contentful.com/spaces/v2h0bz1hqac3/content_types/traduzioniVarie) */
export type TraduzioniVarieVuoiDiventarePartnerArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

export type TraduzioniVarieCollection = {
  __typename?: 'TraduzioniVarieCollection';
  items: Array<Maybe<TraduzioniVarie>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type TraduzioniVarieFilter = {
  AND?: InputMaybe<Array<InputMaybe<TraduzioniVarieFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<TraduzioniVarieFilter>>>;
  acquista?: InputMaybe<Scalars['String']['input']>;
  acquista_contains?: InputMaybe<Scalars['String']['input']>;
  acquista_exists?: InputMaybe<Scalars['Boolean']['input']>;
  acquista_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  acquista_not?: InputMaybe<Scalars['String']['input']>;
  acquista_not_contains?: InputMaybe<Scalars['String']['input']>;
  acquista_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  chiama?: InputMaybe<Scalars['String']['input']>;
  chiama_contains?: InputMaybe<Scalars['String']['input']>;
  chiama_exists?: InputMaybe<Scalars['Boolean']['input']>;
  chiama_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  chiama_not?: InputMaybe<Scalars['String']['input']>;
  chiama_not_contains?: InputMaybe<Scalars['String']['input']>;
  chiama_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  contatti?: InputMaybe<Scalars['String']['input']>;
  contatti_contains?: InputMaybe<Scalars['String']['input']>;
  contatti_exists?: InputMaybe<Scalars['Boolean']['input']>;
  contatti_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  contatti_not?: InputMaybe<Scalars['String']['input']>;
  contatti_not_contains?: InputMaybe<Scalars['String']['input']>;
  contatti_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  esperienze?: InputMaybe<Scalars['String']['input']>;
  esperienzeSottoTitolo?: InputMaybe<Scalars['String']['input']>;
  esperienzeSottoTitolo_contains?: InputMaybe<Scalars['String']['input']>;
  esperienzeSottoTitolo_exists?: InputMaybe<Scalars['Boolean']['input']>;
  esperienzeSottoTitolo_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  esperienzeSottoTitolo_not?: InputMaybe<Scalars['String']['input']>;
  esperienzeSottoTitolo_not_contains?: InputMaybe<Scalars['String']['input']>;
  esperienzeSottoTitolo_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  esperienze_contains?: InputMaybe<Scalars['String']['input']>;
  esperienze_exists?: InputMaybe<Scalars['Boolean']['input']>;
  esperienze_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  esperienze_not?: InputMaybe<Scalars['String']['input']>;
  esperienze_not_contains?: InputMaybe<Scalars['String']['input']>;
  esperienze_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  hotel?: InputMaybe<Scalars['String']['input']>;
  hotel_contains?: InputMaybe<Scalars['String']['input']>;
  hotel_exists?: InputMaybe<Scalars['Boolean']['input']>;
  hotel_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  hotel_not?: InputMaybe<Scalars['String']['input']>;
  hotel_not_contains?: InputMaybe<Scalars['String']['input']>;
  hotel_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  nostriPartner?: InputMaybe<Scalars['String']['input']>;
  nostriPartner_contains?: InputMaybe<Scalars['String']['input']>;
  nostriPartner_exists?: InputMaybe<Scalars['Boolean']['input']>;
  nostriPartner_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  nostriPartner_not?: InputMaybe<Scalars['String']['input']>;
  nostriPartner_not_contains?: InputMaybe<Scalars['String']['input']>;
  nostriPartner_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  padel?: InputMaybe<Scalars['String']['input']>;
  padel_contains?: InputMaybe<Scalars['String']['input']>;
  padel_exists?: InputMaybe<Scalars['Boolean']['input']>;
  padel_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  padel_not?: InputMaybe<Scalars['String']['input']>;
  padel_not_contains?: InputMaybe<Scalars['String']['input']>;
  padel_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  piscina?: InputMaybe<Scalars['String']['input']>;
  piscina_contains?: InputMaybe<Scalars['String']['input']>;
  piscina_exists?: InputMaybe<Scalars['Boolean']['input']>;
  piscina_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  piscina_not?: InputMaybe<Scalars['String']['input']>;
  piscina_not_contains?: InputMaybe<Scalars['String']['input']>;
  piscina_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  prenota?: InputMaybe<Scalars['String']['input']>;
  prenota_contains?: InputMaybe<Scalars['String']['input']>;
  prenota_exists?: InputMaybe<Scalars['Boolean']['input']>;
  prenota_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  prenota_not?: InputMaybe<Scalars['String']['input']>;
  prenota_not_contains?: InputMaybe<Scalars['String']['input']>;
  prenota_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  prezzi?: InputMaybe<Scalars['String']['input']>;
  prezzi_contains?: InputMaybe<Scalars['String']['input']>;
  prezzi_exists?: InputMaybe<Scalars['Boolean']['input']>;
  prezzi_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  prezzi_not?: InputMaybe<Scalars['String']['input']>;
  prezzi_not_contains?: InputMaybe<Scalars['String']['input']>;
  prezzi_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  richiediInformazioni?: InputMaybe<Scalars['String']['input']>;
  richiediInformazioni_contains?: InputMaybe<Scalars['String']['input']>;
  richiediInformazioni_exists?: InputMaybe<Scalars['Boolean']['input']>;
  richiediInformazioni_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  richiediInformazioni_not?: InputMaybe<Scalars['String']['input']>;
  richiediInformazioni_not_contains?: InputMaybe<Scalars['String']['input']>;
  richiediInformazioni_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  ristorante?: InputMaybe<Scalars['String']['input']>;
  ristorante_contains?: InputMaybe<Scalars['String']['input']>;
  ristorante_exists?: InputMaybe<Scalars['Boolean']['input']>;
  ristorante_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  ristorante_not?: InputMaybe<Scalars['String']['input']>;
  ristorante_not_contains?: InputMaybe<Scalars['String']['input']>;
  ristorante_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  scopriDiPi?: InputMaybe<Scalars['String']['input']>;
  scopriDiPi_contains?: InputMaybe<Scalars['String']['input']>;
  scopriDiPi_exists?: InputMaybe<Scalars['Boolean']['input']>;
  scopriDiPi_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  scopriDiPi_not?: InputMaybe<Scalars['String']['input']>;
  scopriDiPi_not_contains?: InputMaybe<Scalars['String']['input']>;
  scopriDiPi_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  scuderiaBlu?: InputMaybe<Scalars['String']['input']>;
  scuderiaBlu_contains?: InputMaybe<Scalars['String']['input']>;
  scuderiaBlu_exists?: InputMaybe<Scalars['Boolean']['input']>;
  scuderiaBlu_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  scuderiaBlu_not?: InputMaybe<Scalars['String']['input']>;
  scuderiaBlu_not_contains?: InputMaybe<Scalars['String']['input']>;
  scuderiaBlu_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  seguici?: InputMaybe<Scalars['String']['input']>;
  seguici_contains?: InputMaybe<Scalars['String']['input']>;
  seguici_exists?: InputMaybe<Scalars['Boolean']['input']>;
  seguici_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  seguici_not?: InputMaybe<Scalars['String']['input']>;
  seguici_not_contains?: InputMaybe<Scalars['String']['input']>;
  seguici_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  servizi?: InputMaybe<Scalars['String']['input']>;
  servizi_contains?: InputMaybe<Scalars['String']['input']>;
  servizi_exists?: InputMaybe<Scalars['Boolean']['input']>;
  servizi_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  servizi_not?: InputMaybe<Scalars['String']['input']>;
  servizi_not_contains?: InputMaybe<Scalars['String']['input']>;
  servizi_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sys?: InputMaybe<SysFilter>;
  vuoiDiventarePartner_contains?: InputMaybe<Scalars['String']['input']>;
  vuoiDiventarePartner_exists?: InputMaybe<Scalars['Boolean']['input']>;
  vuoiDiventarePartner_not_contains?: InputMaybe<Scalars['String']['input']>;
};

export type TraduzioniVarieLinkingCollections = {
  __typename?: 'TraduzioniVarieLinkingCollections';
  entryCollection?: Maybe<EntryCollection>;
};


export type TraduzioniVarieLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export enum TraduzioniVarieOrder {
  AcquistaAsc = 'acquista_ASC',
  AcquistaDesc = 'acquista_DESC',
  ChiamaAsc = 'chiama_ASC',
  ChiamaDesc = 'chiama_DESC',
  ContattiAsc = 'contatti_ASC',
  ContattiDesc = 'contatti_DESC',
  EsperienzeSottoTitoloAsc = 'esperienzeSottoTitolo_ASC',
  EsperienzeSottoTitoloDesc = 'esperienzeSottoTitolo_DESC',
  EsperienzeAsc = 'esperienze_ASC',
  EsperienzeDesc = 'esperienze_DESC',
  HotelAsc = 'hotel_ASC',
  HotelDesc = 'hotel_DESC',
  NostriPartnerAsc = 'nostriPartner_ASC',
  NostriPartnerDesc = 'nostriPartner_DESC',
  PadelAsc = 'padel_ASC',
  PadelDesc = 'padel_DESC',
  PiscinaAsc = 'piscina_ASC',
  PiscinaDesc = 'piscina_DESC',
  PrenotaAsc = 'prenota_ASC',
  PrenotaDesc = 'prenota_DESC',
  PrezziAsc = 'prezzi_ASC',
  PrezziDesc = 'prezzi_DESC',
  RichiediInformazioniAsc = 'richiediInformazioni_ASC',
  RichiediInformazioniDesc = 'richiediInformazioni_DESC',
  RistoranteAsc = 'ristorante_ASC',
  RistoranteDesc = 'ristorante_DESC',
  ScopriDiPiAsc = 'scopriDiPi_ASC',
  ScopriDiPiDesc = 'scopriDiPi_DESC',
  ScuderiaBluAsc = 'scuderiaBlu_ASC',
  ScuderiaBluDesc = 'scuderiaBlu_DESC',
  SeguiciAsc = 'seguici_ASC',
  SeguiciDesc = 'seguici_DESC',
  ServiziAsc = 'servizi_ASC',
  ServiziDesc = 'servizi_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

export type TraduzioniVarieVuoiDiventarePartner = {
  __typename?: 'TraduzioniVarieVuoiDiventarePartner';
  json: Scalars['JSON']['output'];
  links: TraduzioniVarieVuoiDiventarePartnerLinks;
};

export type TraduzioniVarieVuoiDiventarePartnerAssets = {
  __typename?: 'TraduzioniVarieVuoiDiventarePartnerAssets';
  block: Array<Maybe<Asset>>;
  hyperlink: Array<Maybe<Asset>>;
};

export type TraduzioniVarieVuoiDiventarePartnerEntries = {
  __typename?: 'TraduzioniVarieVuoiDiventarePartnerEntries';
  block: Array<Maybe<Entry>>;
  hyperlink: Array<Maybe<Entry>>;
  inline: Array<Maybe<Entry>>;
};

export type TraduzioniVarieVuoiDiventarePartnerLinks = {
  __typename?: 'TraduzioniVarieVuoiDiventarePartnerLinks';
  assets: TraduzioniVarieVuoiDiventarePartnerAssets;
  entries: TraduzioniVarieVuoiDiventarePartnerEntries;
  resources: TraduzioniVarieVuoiDiventarePartnerResources;
};

export type TraduzioniVarieVuoiDiventarePartnerResources = {
  __typename?: 'TraduzioniVarieVuoiDiventarePartnerResources';
  block: Array<TraduzioniVarieVuoiDiventarePartnerResourcesBlock>;
  hyperlink: Array<TraduzioniVarieVuoiDiventarePartnerResourcesHyperlink>;
  inline: Array<TraduzioniVarieVuoiDiventarePartnerResourcesInline>;
};

export type TraduzioniVarieVuoiDiventarePartnerResourcesBlock = ResourceLink & {
  __typename?: 'TraduzioniVarieVuoiDiventarePartnerResourcesBlock';
  sys: ResourceSys;
};

export type TraduzioniVarieVuoiDiventarePartnerResourcesHyperlink = ResourceLink & {
  __typename?: 'TraduzioniVarieVuoiDiventarePartnerResourcesHyperlink';
  sys: ResourceSys;
};

export type TraduzioniVarieVuoiDiventarePartnerResourcesInline = ResourceLink & {
  __typename?: 'TraduzioniVarieVuoiDiventarePartnerResourcesInline';
  sys: ResourceSys;
};

export type _Node = {
  _id: Scalars['ID']['output'];
};

export type MainDescriptionsQueryVariables = Exact<{
  id: Scalars['String']['input'];
  locale?: InputMaybe<Scalars['String']['input']>;
}>;


export type MainDescriptionsQuery = { __typename?: 'Query', descrizionePrincipale?: { __typename?: 'DescrizionePrincipale', titolo?: string | null, descrizione?: { __typename?: 'DescrizionePrincipaleDescrizione', json: any } | null } | null };

export type GirellaQueryVariables = Exact<{
  locale?: InputMaybe<Scalars['String']['input']>;
}>;


export type GirellaQuery = { __typename?: 'Query', traduzioniVarie?: { __typename?: 'TraduzioniVarie', padel?: string | null, ristorante?: string | null, hotel?: string | null, scuderiaBlu?: string | null, servizi?: string | null, seguici?: string | null, contatti?: string | null, prenota?: string | null, chiama?: string | null, richiediInformazioni?: string | null } | null, girella?: { __typename?: 'Girella', padel?: { __typename?: 'GirellaPadel', json: any } | null, ristorante?: { __typename?: 'GirellaRistorante', json: any } | null, hotel?: { __typename?: 'GirellaHotel', json: any } | null, concessionaria?: { __typename?: 'GirellaConcessionaria', json: any } | null } | null };

export type ServiziQueryVariables = Exact<{
  locale?: InputMaybe<Scalars['String']['input']>;
}>;


export type ServiziQuery = { __typename?: 'Query', servizi?: { __typename?: 'Servizi', palestra?: string | null, coworking?: string | null, bistrotERistorante?: string | null, fisioterapistaEOsteopata?: string | null, padelArena?: string | null, titolo?: string | null, palestraTitolo?: string | null, coworkingTitolo?: string | null, padelArenaTitolo?: string | null, fisioterapistaEOsteopataTitolo?: string | null, bistrotERistoranteTitolo?: string | null, testoBottone?: string | null } | null };

export type ContactUsQueryVariables = Exact<{
  locale?: InputMaybe<Scalars['String']['input']>;
}>;


export type ContactUsQuery = { __typename?: 'Query', lavoraConNoi?: { __typename?: 'LavoraConNoi', titolo?: string | null, testo?: string | null, invia?: string | null } | null };

export type RistoranteQueryVariables = Exact<{
  locale?: InputMaybe<Scalars['String']['input']>;
}>;


export type RistoranteQuery = { __typename?: 'Query', ristoranteFilosofia?: { __typename?: 'RistoranteFilosofia', titolo?: string | null, prenotaRistorante?: string | null, prenotaBistrot?: string | null, testoFilosofia?: string | null, descrizioneBreve?: { __typename?: 'RistoranteFilosofiaDescrizioneBreve', json: any } | null, descrizioneBistrot?: { __typename?: 'RistoranteFilosofiaDescrizioneBistrot', json: any } | null, descrizioneLoungeBar?: { __typename?: 'RistoranteFilosofiaDescrizioneLoungeBar', json: any } | null, descrizioneRistorante?: { __typename?: 'RistoranteFilosofiaDescrizioneRistorante', json: any } | null, menuBistrot?: { __typename?: 'Asset', url?: string | null } | null, menuRis?: { __typename?: 'Asset', url?: string | null } | null, menuLoungeBar?: { __typename?: 'Asset', url?: string | null } | null } | null, location?: { __typename?: 'TestoRistorante', titolo?: string | null, sottoTitolo?: string | null, testo?: string | null } | null, cucina?: { __typename?: 'TestoRistorante', titolo?: string | null, sottoTitolo?: string | null, testo?: string | null } | null };

export type PadelQueryVariables = Exact<{
  locale?: InputMaybe<Scalars['String']['input']>;
}>;


export type PadelQuery = { __typename?: 'Query', istrutturi?: { __typename?: 'Istrutturi', titolo?: string | null, christianBio?: string | null, robertaBio?: string | null } | null, padelAccademyDesc?: { __typename?: 'PadelAccademyDesc', titolo?: string | null, prezzi?: { __typename?: 'Asset', url?: string | null } | null, descrizione?: { __typename?: 'PadelAccademyDescDescrizione', json: any } | null } | null, interni?: { __typename?: 'CampiPadel', titolo?: string | null, descrizione?: string | null } | null, esterni?: { __typename?: 'CampiPadel', titolo?: string | null, descrizione?: string | null } | null, shop?: { __typename?: 'CampiPadel', titolo?: string | null, descrizione?: string | null } | null };

export type ServicePageQueryVariables = Exact<{
  id: Scalars['String']['input'];
  locale?: InputMaybe<Scalars['String']['input']>;
}>;


export type ServicePageQuery = { __typename?: 'Query', bloccoServizi?: { __typename?: 'BloccoServizi', titolo?: string | null, sottoTitolo?: string | null, testo?: string | null, linkPrenota?: string | null, linkInfo?: string | null, testoPulsantePrimario?: string | null, testoPulsanteInfo?: string | null, listino?: { __typename?: 'Asset', url?: string | null } | null } | null };

export type ArticlesQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']['input']>;
}>;


export type ArticlesQuery = { __typename?: 'Query', articoloStampaCollection?: { __typename?: 'ArticoloStampaCollection', total: number, items: Array<{ __typename?: 'ArticoloStampa', link?: string | null, anteprima?: string | null, publisher?: string | null, data?: any | null, immagine?: { __typename?: 'Asset', url?: string | null } | null } | null> } | null };

export type ContattiQueryVariables = Exact<{
  locale?: InputMaybe<Scalars['String']['input']>;
}>;


export type ContattiQuery = { __typename?: 'Query', servizi?: { __typename?: 'Servizi', palestraTitolo?: string | null, coworkingTitolo?: string | null, padelArenaTitolo?: string | null, fisioterapistaEOsteopataTitolo?: string | null, bistrotERistoranteTitolo?: string | null } | null, contatti?: { __typename?: 'Contatti', padelArenaDettagli?: string | null, iTigliDettagli?: string | null, serviziDettagli?: string | null, testoAccettoPrivacy?: string | null, testoIscriviti?: string | null, testoContattaci?: string | null, iscrivitiAlClub?: string | null, piscinaDettagli?: string | null, gdpr?: string | null, datiAzienda?: { __typename?: 'ContattiDatiAzienda', json: any } | null } | null };

export type PadelRulesQueryVariables = Exact<{
  locale?: InputMaybe<Scalars['String']['input']>;
}>;


export type PadelRulesQuery = { __typename?: 'Query', padelRules?: { __typename?: 'PadelRules', titolo?: string | null, campo?: string | null, palla?: string | null, racchetta?: string | null, punteggio?: string | null } | null };

export type IconeQueryVariables = Exact<{
  locale?: InputMaybe<Scalars['String']['input']>;
}>;


export type IconeQuery = { __typename?: 'Query', icone?: { __typename?: 'Icone', como?: string | null, lugano?: string | null, milano?: string | null, autostrada?: string | null, parcheggi?: string | null, arrediDiDesign?: string | null, nespressoConBollitore?: string | null, cassaforte?: string | null, palestra?: string | null, cleaningService?: string | null, cusciniEPiuminiKingSize?: string | null, prodottiDaBagnoRitualsCosmetics?: string | null, freeWifi?: string | null, ariaCondizionata?: string | null, roomService?: string | null, telefonoFisso?: string | null, phonProfessionaleParlux?: string | null, frigobar?: string | null, tv50ASchermoPiatto?: string | null, bagnoInMarmo?: string | null, lettiniInPiscina?: string | null, desk?: string | null, cabinaArmadio?: string | null, stanzeComunicanti?: string | null, transferAeroporto?: string | null, lettini?: string | null, dayBed?: string | null, teliSpiaggia?: string | null, barBistrotERistorante?: string | null, eventi?: string | null, caniDiPiccolaTaglia?: string | null, vietatoFumare?: string | null, colonnineDiRicarica?: string | null, parcheggioInterno?: string | null } | null, hotelPage?: { __typename?: 'HotelPage', camereIncludono?: string | null, serviziSuRichiesta?: string | null } | null };

export type TraduzioniQueryVariables = Exact<{
  locale?: InputMaybe<Scalars['String']['input']>;
}>;


export type TraduzioniQuery = { __typename?: 'Query', traduzioniVarie?: { __typename?: 'TraduzioniVarie', padel?: string | null, ristorante?: string | null, hotel?: string | null, scuderiaBlu?: string | null, servizi?: string | null, seguici?: string | null, contatti?: string | null, prenota?: string | null, chiama?: string | null, piscina?: string | null, prezzi?: string | null, scopriDiPi?: string | null, richiediInformazioni?: string | null, nostriPartner?: string | null, esperienzeSottoTitolo?: string | null, esperienze?: string | null, acquista?: string | null, vuoiDiventarePartner?: { __typename?: 'TraduzioniVarieVuoiDiventarePartner', json: any } | null } | null };

export type ExpirienceQueryVariables = Exact<{
  locale?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['String']['input'];
}>;


export type ExpirienceQuery = { __typename?: 'Query', expirience?: { __typename?: 'Expirience', titolo?: string | null, sottotitolo?: string | null, prezzo?: string | null, descrizione?: { __typename?: 'ExpirienceDescrizione', json: any } | null } | null };

export type ComoShowCaseQueryVariables = Exact<{
  locale?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['String']['input'];
}>;


export type ComoShowCaseQuery = { __typename?: 'Query', comoShowCase?: { __typename?: 'ComoShowCase', title?: string | null, testo?: { __typename?: 'ComoShowCaseTesto', json: any } | null } | null };

export type PoolQueryVariables = Exact<{
  locale?: InputMaybe<Scalars['String']['input']>;
  now?: InputMaybe<Scalars['DateTime']['input']>;
}>;


export type PoolQuery = { __typename?: 'Query', eventoPiscinaCollection?: { __typename?: 'EventoPiscinaCollection', items: Array<{ __typename?: 'EventoPiscina', titolo?: string | null, data?: any | null, prenotaLInk?: string | null, foto?: { __typename?: 'Asset', url?: string | null } | null, descrizione?: { __typename?: 'EventoPiscinaDescrizione', json: any } | null } | null> } | null, pool?: { __typename?: 'Pool', titolo?: string | null, testoSfondoNero?: { __typename?: 'PoolTestoSfondoNero', json: any } | null, prezzi?: { __typename?: 'Asset', url?: string | null } | null } | null };

export type CameraQueryVariables = Exact<{
  locale?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['String']['input'];
}>;


export type CameraQuery = { __typename?: 'Query', camera?: { __typename?: 'Camera', titolo?: string | null, sottitolo?: string | null, coloriDisponibili?: string | null, checkTimes?: string | null, descrizione?: { __typename?: 'CameraDescrizione', json: any } | null, foto?: { __typename?: 'Asset', url?: string | null } | null } | null, hotelPage?: { __typename?: 'HotelPage', titolo?: string | null, titoloAlternativo?: string | null, sottoTitolo?: string | null, camereIncludono?: string | null, serviziSuRichiesta?: string | null, descrizione?: { __typename?: 'HotelPageDescrizione', json: any } | null } | null };

export type CamereQueryVariables = Exact<{
  locale?: InputMaybe<Scalars['String']['input']>;
}>;


export type CamereQuery = { __typename?: 'Query', cameraCollection?: { __typename?: 'CameraCollection', items: Array<{ __typename?: 'Camera', titolo?: string | null, caratteristiche?: Array<string | null> | null, sys: { __typename?: 'Sys', id: string }, foto?: { __typename?: 'Asset', url?: string | null } | null } | null> } | null };

export type HotelQueryVariables = Exact<{
  locale?: InputMaybe<Scalars['String']['input']>;
}>;


export type HotelQuery = { __typename?: 'Query', hotelPage?: { __typename?: 'HotelPage', titolo?: string | null, titoloAlternativo?: string | null, sottoTitolo?: string | null, camereIncludono?: string | null, serviziSuRichiesta?: string | null, descrizione?: { __typename?: 'HotelPageDescrizione', json: any } | null } | null };

export type ImgDescQueryVariables = Exact<{
  locale?: InputMaybe<Scalars['String']['input']>;
}>;


export type ImgDescQuery = { __typename?: 'Query', imgTitleDescCollection?: { __typename?: 'ImgTitleDescCollection', total: number, items: Array<{ __typename?: 'ImgTitleDesc', title?: string | null, description?: string | null, img?: { __typename?: 'Asset', url?: string | null } | null } | null> } | null };


export const MainDescriptionsDocument = gql`
    query mainDescriptions($id: String!, $locale: String = "it") {
  descrizionePrincipale(id: $id, locale: $locale) {
    titolo
    descrizione {
      json
    }
  }
}
    `;

/**
 * __useMainDescriptionsQuery__
 *
 * To run a query within a React component, call `useMainDescriptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMainDescriptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMainDescriptionsQuery({
 *   variables: {
 *      id: // value for 'id'
 *      locale: // value for 'locale'
 *   },
 * });
 */
export function useMainDescriptionsQuery(baseOptions: Apollo.QueryHookOptions<MainDescriptionsQuery, MainDescriptionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MainDescriptionsQuery, MainDescriptionsQueryVariables>(MainDescriptionsDocument, options);
      }
export function useMainDescriptionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MainDescriptionsQuery, MainDescriptionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MainDescriptionsQuery, MainDescriptionsQueryVariables>(MainDescriptionsDocument, options);
        }
export function useMainDescriptionsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<MainDescriptionsQuery, MainDescriptionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<MainDescriptionsQuery, MainDescriptionsQueryVariables>(MainDescriptionsDocument, options);
        }
export type MainDescriptionsQueryHookResult = ReturnType<typeof useMainDescriptionsQuery>;
export type MainDescriptionsLazyQueryHookResult = ReturnType<typeof useMainDescriptionsLazyQuery>;
export type MainDescriptionsSuspenseQueryHookResult = ReturnType<typeof useMainDescriptionsSuspenseQuery>;
export type MainDescriptionsQueryResult = Apollo.QueryResult<MainDescriptionsQuery, MainDescriptionsQueryVariables>;
export const GirellaDocument = gql`
    query girella($locale: String = "it") {
  traduzioniVarie(id: "1DAXxvioWVpUzUlsV52Kfi", locale: $locale) {
    padel
    ristorante
    hotel
    scuderiaBlu
    servizi
    seguici
    contatti
    prenota
    chiama
    richiediInformazioni
  }
  girella(id: "4AaS4B2cX4jGfvc5RZbu4y", locale: $locale) {
    padel {
      json
    }
    ristorante {
      json
    }
    hotel {
      json
    }
    concessionaria {
      json
    }
  }
}
    `;

/**
 * __useGirellaQuery__
 *
 * To run a query within a React component, call `useGirellaQuery` and pass it any options that fit your needs.
 * When your component renders, `useGirellaQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGirellaQuery({
 *   variables: {
 *      locale: // value for 'locale'
 *   },
 * });
 */
export function useGirellaQuery(baseOptions?: Apollo.QueryHookOptions<GirellaQuery, GirellaQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GirellaQuery, GirellaQueryVariables>(GirellaDocument, options);
      }
export function useGirellaLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GirellaQuery, GirellaQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GirellaQuery, GirellaQueryVariables>(GirellaDocument, options);
        }
export function useGirellaSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GirellaQuery, GirellaQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GirellaQuery, GirellaQueryVariables>(GirellaDocument, options);
        }
export type GirellaQueryHookResult = ReturnType<typeof useGirellaQuery>;
export type GirellaLazyQueryHookResult = ReturnType<typeof useGirellaLazyQuery>;
export type GirellaSuspenseQueryHookResult = ReturnType<typeof useGirellaSuspenseQuery>;
export type GirellaQueryResult = Apollo.QueryResult<GirellaQuery, GirellaQueryVariables>;
export const ServiziDocument = gql`
    query servizi($locale: String = "it") {
  servizi(id: "6QyHxtVohPs9Nsvl6L5D4N", locale: $locale) {
    palestra
    coworking
    bistrotERistorante
    fisioterapistaEOsteopata
    padelArena
    titolo
    palestraTitolo
    coworkingTitolo
    padelArenaTitolo
    fisioterapistaEOsteopataTitolo
    bistrotERistoranteTitolo
    testoBottone
  }
}
    `;

/**
 * __useServiziQuery__
 *
 * To run a query within a React component, call `useServiziQuery` and pass it any options that fit your needs.
 * When your component renders, `useServiziQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useServiziQuery({
 *   variables: {
 *      locale: // value for 'locale'
 *   },
 * });
 */
export function useServiziQuery(baseOptions?: Apollo.QueryHookOptions<ServiziQuery, ServiziQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ServiziQuery, ServiziQueryVariables>(ServiziDocument, options);
      }
export function useServiziLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ServiziQuery, ServiziQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ServiziQuery, ServiziQueryVariables>(ServiziDocument, options);
        }
export function useServiziSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<ServiziQuery, ServiziQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ServiziQuery, ServiziQueryVariables>(ServiziDocument, options);
        }
export type ServiziQueryHookResult = ReturnType<typeof useServiziQuery>;
export type ServiziLazyQueryHookResult = ReturnType<typeof useServiziLazyQuery>;
export type ServiziSuspenseQueryHookResult = ReturnType<typeof useServiziSuspenseQuery>;
export type ServiziQueryResult = Apollo.QueryResult<ServiziQuery, ServiziQueryVariables>;
export const ContactUsDocument = gql`
    query contactUs($locale: String = "it") {
  lavoraConNoi(id: "6jRzkfm3I8dqAFBHroCcIv", locale: $locale) {
    titolo
    testo
    invia
  }
}
    `;

/**
 * __useContactUsQuery__
 *
 * To run a query within a React component, call `useContactUsQuery` and pass it any options that fit your needs.
 * When your component renders, `useContactUsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useContactUsQuery({
 *   variables: {
 *      locale: // value for 'locale'
 *   },
 * });
 */
export function useContactUsQuery(baseOptions?: Apollo.QueryHookOptions<ContactUsQuery, ContactUsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ContactUsQuery, ContactUsQueryVariables>(ContactUsDocument, options);
      }
export function useContactUsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ContactUsQuery, ContactUsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ContactUsQuery, ContactUsQueryVariables>(ContactUsDocument, options);
        }
export function useContactUsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<ContactUsQuery, ContactUsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ContactUsQuery, ContactUsQueryVariables>(ContactUsDocument, options);
        }
export type ContactUsQueryHookResult = ReturnType<typeof useContactUsQuery>;
export type ContactUsLazyQueryHookResult = ReturnType<typeof useContactUsLazyQuery>;
export type ContactUsSuspenseQueryHookResult = ReturnType<typeof useContactUsSuspenseQuery>;
export type ContactUsQueryResult = Apollo.QueryResult<ContactUsQuery, ContactUsQueryVariables>;
export const RistoranteDocument = gql`
    query ristorante($locale: String = "it") {
  ristoranteFilosofia(id: "6TJxRXmljxfdQD1S0TlA6F", locale: $locale) {
    titolo
    prenotaRistorante
    prenotaBistrot
    testoFilosofia
    descrizioneBreve {
      json
    }
    descrizioneBistrot {
      json
    }
    descrizioneLoungeBar {
      json
    }
    descrizioneRistorante {
      json
    }
    menuBistrot {
      url
    }
    menuRis {
      url
    }
    menuLoungeBar {
      url
    }
  }
  location: testoRistorante(id: "6JDaDMmZCWA6G29eRmepMg", locale: $locale) {
    titolo
    sottoTitolo
    testo
  }
  cucina: testoRistorante(id: "7A0MW8M26GWdBfrRJkHKki", locale: $locale) {
    titolo
    sottoTitolo
    testo
  }
}
    `;

/**
 * __useRistoranteQuery__
 *
 * To run a query within a React component, call `useRistoranteQuery` and pass it any options that fit your needs.
 * When your component renders, `useRistoranteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRistoranteQuery({
 *   variables: {
 *      locale: // value for 'locale'
 *   },
 * });
 */
export function useRistoranteQuery(baseOptions?: Apollo.QueryHookOptions<RistoranteQuery, RistoranteQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RistoranteQuery, RistoranteQueryVariables>(RistoranteDocument, options);
      }
export function useRistoranteLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RistoranteQuery, RistoranteQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RistoranteQuery, RistoranteQueryVariables>(RistoranteDocument, options);
        }
export function useRistoranteSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<RistoranteQuery, RistoranteQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<RistoranteQuery, RistoranteQueryVariables>(RistoranteDocument, options);
        }
export type RistoranteQueryHookResult = ReturnType<typeof useRistoranteQuery>;
export type RistoranteLazyQueryHookResult = ReturnType<typeof useRistoranteLazyQuery>;
export type RistoranteSuspenseQueryHookResult = ReturnType<typeof useRistoranteSuspenseQuery>;
export type RistoranteQueryResult = Apollo.QueryResult<RistoranteQuery, RistoranteQueryVariables>;
export const PadelDocument = gql`
    query padel($locale: String = "it") {
  istrutturi(id: "1NvXmubI6CxCO9gQVaPyS1", locale: $locale) {
    titolo
    christianBio
    robertaBio
  }
  padelAccademyDesc(id: "7byl8RxPCXTmnxyeQSzHpz", locale: $locale) {
    titolo
    prezzi {
      url
    }
    descrizione {
      json
    }
  }
  interni: campiPadel(id: "2PglHDlWlqAMMQ0nElIj5i", locale: $locale) {
    titolo
    descrizione
  }
  esterni: campiPadel(id: "3SWEuxJQvNMXpQwY90DUBa", locale: $locale) {
    titolo
    descrizione
  }
  shop: campiPadel(id: "4yEWheiaFpzqirRYSfGxfL", locale: $locale) {
    titolo
    descrizione
  }
}
    `;

/**
 * __usePadelQuery__
 *
 * To run a query within a React component, call `usePadelQuery` and pass it any options that fit your needs.
 * When your component renders, `usePadelQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePadelQuery({
 *   variables: {
 *      locale: // value for 'locale'
 *   },
 * });
 */
export function usePadelQuery(baseOptions?: Apollo.QueryHookOptions<PadelQuery, PadelQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PadelQuery, PadelQueryVariables>(PadelDocument, options);
      }
export function usePadelLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PadelQuery, PadelQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PadelQuery, PadelQueryVariables>(PadelDocument, options);
        }
export function usePadelSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<PadelQuery, PadelQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<PadelQuery, PadelQueryVariables>(PadelDocument, options);
        }
export type PadelQueryHookResult = ReturnType<typeof usePadelQuery>;
export type PadelLazyQueryHookResult = ReturnType<typeof usePadelLazyQuery>;
export type PadelSuspenseQueryHookResult = ReturnType<typeof usePadelSuspenseQuery>;
export type PadelQueryResult = Apollo.QueryResult<PadelQuery, PadelQueryVariables>;
export const ServicePageDocument = gql`
    query servicePage($id: String!, $locale: String = "it") {
  bloccoServizi(id: $id, locale: $locale) {
    titolo
    sottoTitolo
    testo
    linkPrenota
    linkInfo
    testoPulsantePrimario
    listino {
      url
    }
    testoPulsanteInfo
  }
}
    `;

/**
 * __useServicePageQuery__
 *
 * To run a query within a React component, call `useServicePageQuery` and pass it any options that fit your needs.
 * When your component renders, `useServicePageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useServicePageQuery({
 *   variables: {
 *      id: // value for 'id'
 *      locale: // value for 'locale'
 *   },
 * });
 */
export function useServicePageQuery(baseOptions: Apollo.QueryHookOptions<ServicePageQuery, ServicePageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ServicePageQuery, ServicePageQueryVariables>(ServicePageDocument, options);
      }
export function useServicePageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ServicePageQuery, ServicePageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ServicePageQuery, ServicePageQueryVariables>(ServicePageDocument, options);
        }
export function useServicePageSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<ServicePageQuery, ServicePageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ServicePageQuery, ServicePageQueryVariables>(ServicePageDocument, options);
        }
export type ServicePageQueryHookResult = ReturnType<typeof useServicePageQuery>;
export type ServicePageLazyQueryHookResult = ReturnType<typeof useServicePageLazyQuery>;
export type ServicePageSuspenseQueryHookResult = ReturnType<typeof useServicePageSuspenseQuery>;
export type ServicePageQueryResult = Apollo.QueryResult<ServicePageQuery, ServicePageQueryVariables>;
export const ArticlesDocument = gql`
    query articles($limit: Int) {
  articoloStampaCollection(limit: $limit, order: data_DESC) {
    total
    items {
      link
      immagine {
        url
      }
      anteprima
      publisher
      data
    }
  }
}
    `;

/**
 * __useArticlesQuery__
 *
 * To run a query within a React component, call `useArticlesQuery` and pass it any options that fit your needs.
 * When your component renders, `useArticlesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useArticlesQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useArticlesQuery(baseOptions?: Apollo.QueryHookOptions<ArticlesQuery, ArticlesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ArticlesQuery, ArticlesQueryVariables>(ArticlesDocument, options);
      }
export function useArticlesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ArticlesQuery, ArticlesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ArticlesQuery, ArticlesQueryVariables>(ArticlesDocument, options);
        }
export function useArticlesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<ArticlesQuery, ArticlesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ArticlesQuery, ArticlesQueryVariables>(ArticlesDocument, options);
        }
export type ArticlesQueryHookResult = ReturnType<typeof useArticlesQuery>;
export type ArticlesLazyQueryHookResult = ReturnType<typeof useArticlesLazyQuery>;
export type ArticlesSuspenseQueryHookResult = ReturnType<typeof useArticlesSuspenseQuery>;
export type ArticlesQueryResult = Apollo.QueryResult<ArticlesQuery, ArticlesQueryVariables>;
export const ContattiDocument = gql`
    query contatti($locale: String = "it") {
  servizi(id: "6QyHxtVohPs9Nsvl6L5D4N", locale: $locale) {
    palestraTitolo
    coworkingTitolo
    padelArenaTitolo
    fisioterapistaEOsteopataTitolo
    bistrotERistoranteTitolo
  }
  contatti(id: "5NXUwhpIRmfZ8zxyerXiGJ", locale: $locale) {
    datiAzienda {
      json
    }
    padelArenaDettagli
    iTigliDettagli
    serviziDettagli
    testoAccettoPrivacy
    testoIscriviti
    testoContattaci
    iscrivitiAlClub
    piscinaDettagli
    gdpr
  }
}
    `;

/**
 * __useContattiQuery__
 *
 * To run a query within a React component, call `useContattiQuery` and pass it any options that fit your needs.
 * When your component renders, `useContattiQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useContattiQuery({
 *   variables: {
 *      locale: // value for 'locale'
 *   },
 * });
 */
export function useContattiQuery(baseOptions?: Apollo.QueryHookOptions<ContattiQuery, ContattiQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ContattiQuery, ContattiQueryVariables>(ContattiDocument, options);
      }
export function useContattiLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ContattiQuery, ContattiQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ContattiQuery, ContattiQueryVariables>(ContattiDocument, options);
        }
export function useContattiSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<ContattiQuery, ContattiQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ContattiQuery, ContattiQueryVariables>(ContattiDocument, options);
        }
export type ContattiQueryHookResult = ReturnType<typeof useContattiQuery>;
export type ContattiLazyQueryHookResult = ReturnType<typeof useContattiLazyQuery>;
export type ContattiSuspenseQueryHookResult = ReturnType<typeof useContattiSuspenseQuery>;
export type ContattiQueryResult = Apollo.QueryResult<ContattiQuery, ContattiQueryVariables>;
export const PadelRulesDocument = gql`
    query PadelRules($locale: String = "it") {
  padelRules(id: "1OhbpBakMbObOV6KVEt8dK", locale: $locale) {
    titolo
    campo
    palla
    racchetta
    punteggio
  }
}
    `;

/**
 * __usePadelRulesQuery__
 *
 * To run a query within a React component, call `usePadelRulesQuery` and pass it any options that fit your needs.
 * When your component renders, `usePadelRulesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePadelRulesQuery({
 *   variables: {
 *      locale: // value for 'locale'
 *   },
 * });
 */
export function usePadelRulesQuery(baseOptions?: Apollo.QueryHookOptions<PadelRulesQuery, PadelRulesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PadelRulesQuery, PadelRulesQueryVariables>(PadelRulesDocument, options);
      }
export function usePadelRulesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PadelRulesQuery, PadelRulesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PadelRulesQuery, PadelRulesQueryVariables>(PadelRulesDocument, options);
        }
export function usePadelRulesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<PadelRulesQuery, PadelRulesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<PadelRulesQuery, PadelRulesQueryVariables>(PadelRulesDocument, options);
        }
export type PadelRulesQueryHookResult = ReturnType<typeof usePadelRulesQuery>;
export type PadelRulesLazyQueryHookResult = ReturnType<typeof usePadelRulesLazyQuery>;
export type PadelRulesSuspenseQueryHookResult = ReturnType<typeof usePadelRulesSuspenseQuery>;
export type PadelRulesQueryResult = Apollo.QueryResult<PadelRulesQuery, PadelRulesQueryVariables>;
export const IconeDocument = gql`
    query Icone($locale: String = "it") {
  icone(id: "2b7zWJhLGKOJaoo3jGRqur", locale: $locale) {
    como
    lugano
    milano
    autostrada
    parcheggi
    arrediDiDesign
    nespressoConBollitore
    cassaforte
    palestra
    cleaningService
    cusciniEPiuminiKingSize
    prodottiDaBagnoRitualsCosmetics
    freeWifi
    ariaCondizionata
    roomService
    telefonoFisso
    phonProfessionaleParlux
    frigobar
    tv50ASchermoPiatto
    bagnoInMarmo
    lettiniInPiscina
    desk
    cabinaArmadio
    stanzeComunicanti
    transferAeroporto
    lettini
    dayBed
    teliSpiaggia
    barBistrotERistorante
    eventi
    caniDiPiccolaTaglia
    vietatoFumare
    colonnineDiRicarica
    parcheggioInterno
  }
  hotelPage(locale: $locale, id: "6j6Rj8UgS4SNt64LCEjvpE") {
    camereIncludono
    serviziSuRichiesta
  }
}
    `;

/**
 * __useIconeQuery__
 *
 * To run a query within a React component, call `useIconeQuery` and pass it any options that fit your needs.
 * When your component renders, `useIconeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIconeQuery({
 *   variables: {
 *      locale: // value for 'locale'
 *   },
 * });
 */
export function useIconeQuery(baseOptions?: Apollo.QueryHookOptions<IconeQuery, IconeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IconeQuery, IconeQueryVariables>(IconeDocument, options);
      }
export function useIconeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IconeQuery, IconeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IconeQuery, IconeQueryVariables>(IconeDocument, options);
        }
export function useIconeSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<IconeQuery, IconeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<IconeQuery, IconeQueryVariables>(IconeDocument, options);
        }
export type IconeQueryHookResult = ReturnType<typeof useIconeQuery>;
export type IconeLazyQueryHookResult = ReturnType<typeof useIconeLazyQuery>;
export type IconeSuspenseQueryHookResult = ReturnType<typeof useIconeSuspenseQuery>;
export type IconeQueryResult = Apollo.QueryResult<IconeQuery, IconeQueryVariables>;
export const TraduzioniDocument = gql`
    query Traduzioni($locale: String = "it") {
  traduzioniVarie(id: "1DAXxvioWVpUzUlsV52Kfi", locale: $locale) {
    padel
    ristorante
    hotel
    scuderiaBlu
    servizi
    seguici
    contatti
    prenota
    chiama
    piscina
    prezzi
    scopriDiPi
    richiediInformazioni
    nostriPartner
    esperienzeSottoTitolo
    esperienze
    acquista
    vuoiDiventarePartner {
      json
    }
  }
}
    `;

/**
 * __useTraduzioniQuery__
 *
 * To run a query within a React component, call `useTraduzioniQuery` and pass it any options that fit your needs.
 * When your component renders, `useTraduzioniQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTraduzioniQuery({
 *   variables: {
 *      locale: // value for 'locale'
 *   },
 * });
 */
export function useTraduzioniQuery(baseOptions?: Apollo.QueryHookOptions<TraduzioniQuery, TraduzioniQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TraduzioniQuery, TraduzioniQueryVariables>(TraduzioniDocument, options);
      }
export function useTraduzioniLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TraduzioniQuery, TraduzioniQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TraduzioniQuery, TraduzioniQueryVariables>(TraduzioniDocument, options);
        }
export function useTraduzioniSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<TraduzioniQuery, TraduzioniQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<TraduzioniQuery, TraduzioniQueryVariables>(TraduzioniDocument, options);
        }
export type TraduzioniQueryHookResult = ReturnType<typeof useTraduzioniQuery>;
export type TraduzioniLazyQueryHookResult = ReturnType<typeof useTraduzioniLazyQuery>;
export type TraduzioniSuspenseQueryHookResult = ReturnType<typeof useTraduzioniSuspenseQuery>;
export type TraduzioniQueryResult = Apollo.QueryResult<TraduzioniQuery, TraduzioniQueryVariables>;
export const ExpirienceDocument = gql`
    query expirience($locale: String = "it", $id: String!) {
  expirience(id: $id, locale: $locale) {
    titolo
    sottotitolo
    descrizione {
      json
    }
    prezzo
  }
}
    `;

/**
 * __useExpirienceQuery__
 *
 * To run a query within a React component, call `useExpirienceQuery` and pass it any options that fit your needs.
 * When your component renders, `useExpirienceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExpirienceQuery({
 *   variables: {
 *      locale: // value for 'locale'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useExpirienceQuery(baseOptions: Apollo.QueryHookOptions<ExpirienceQuery, ExpirienceQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ExpirienceQuery, ExpirienceQueryVariables>(ExpirienceDocument, options);
      }
export function useExpirienceLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ExpirienceQuery, ExpirienceQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ExpirienceQuery, ExpirienceQueryVariables>(ExpirienceDocument, options);
        }
export function useExpirienceSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<ExpirienceQuery, ExpirienceQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ExpirienceQuery, ExpirienceQueryVariables>(ExpirienceDocument, options);
        }
export type ExpirienceQueryHookResult = ReturnType<typeof useExpirienceQuery>;
export type ExpirienceLazyQueryHookResult = ReturnType<typeof useExpirienceLazyQuery>;
export type ExpirienceSuspenseQueryHookResult = ReturnType<typeof useExpirienceSuspenseQuery>;
export type ExpirienceQueryResult = Apollo.QueryResult<ExpirienceQuery, ExpirienceQueryVariables>;
export const ComoShowCaseDocument = gql`
    query comoShowCase($locale: String = "it", $id: String!) {
  comoShowCase(id: $id, locale: $locale) {
    title
    testo {
      json
    }
  }
}
    `;

/**
 * __useComoShowCaseQuery__
 *
 * To run a query within a React component, call `useComoShowCaseQuery` and pass it any options that fit your needs.
 * When your component renders, `useComoShowCaseQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useComoShowCaseQuery({
 *   variables: {
 *      locale: // value for 'locale'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useComoShowCaseQuery(baseOptions: Apollo.QueryHookOptions<ComoShowCaseQuery, ComoShowCaseQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ComoShowCaseQuery, ComoShowCaseQueryVariables>(ComoShowCaseDocument, options);
      }
export function useComoShowCaseLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ComoShowCaseQuery, ComoShowCaseQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ComoShowCaseQuery, ComoShowCaseQueryVariables>(ComoShowCaseDocument, options);
        }
export function useComoShowCaseSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<ComoShowCaseQuery, ComoShowCaseQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ComoShowCaseQuery, ComoShowCaseQueryVariables>(ComoShowCaseDocument, options);
        }
export type ComoShowCaseQueryHookResult = ReturnType<typeof useComoShowCaseQuery>;
export type ComoShowCaseLazyQueryHookResult = ReturnType<typeof useComoShowCaseLazyQuery>;
export type ComoShowCaseSuspenseQueryHookResult = ReturnType<typeof useComoShowCaseSuspenseQuery>;
export type ComoShowCaseQueryResult = Apollo.QueryResult<ComoShowCaseQuery, ComoShowCaseQueryVariables>;
export const PoolDocument = gql`
    query pool($locale: String = "it", $now: DateTime) {
  eventoPiscinaCollection(order: data_ASC, where: {data_gte: $now}) {
    items {
      titolo
      data
      foto {
        url
      }
      descrizione {
        json
      }
      prenotaLInk
    }
  }
  pool(id: "5Jz7YBAOB6j9Bx66JElPdk", locale: $locale) {
    titolo
    testoSfondoNero {
      json
    }
    prezzi {
      url
    }
  }
}
    `;

/**
 * __usePoolQuery__
 *
 * To run a query within a React component, call `usePoolQuery` and pass it any options that fit your needs.
 * When your component renders, `usePoolQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePoolQuery({
 *   variables: {
 *      locale: // value for 'locale'
 *      now: // value for 'now'
 *   },
 * });
 */
export function usePoolQuery(baseOptions?: Apollo.QueryHookOptions<PoolQuery, PoolQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PoolQuery, PoolQueryVariables>(PoolDocument, options);
      }
export function usePoolLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PoolQuery, PoolQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PoolQuery, PoolQueryVariables>(PoolDocument, options);
        }
export function usePoolSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<PoolQuery, PoolQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<PoolQuery, PoolQueryVariables>(PoolDocument, options);
        }
export type PoolQueryHookResult = ReturnType<typeof usePoolQuery>;
export type PoolLazyQueryHookResult = ReturnType<typeof usePoolLazyQuery>;
export type PoolSuspenseQueryHookResult = ReturnType<typeof usePoolSuspenseQuery>;
export type PoolQueryResult = Apollo.QueryResult<PoolQuery, PoolQueryVariables>;
export const CameraDocument = gql`
    query camera($locale: String = "it", $id: String!) {
  camera(id: $id, locale: $locale) {
    titolo
    sottitolo
    coloriDisponibili
    checkTimes
    descrizione {
      json
    }
    foto {
      url
    }
  }
  hotelPage(locale: $locale, id: "6j6Rj8UgS4SNt64LCEjvpE") {
    titolo
    titoloAlternativo
    sottoTitolo
    camereIncludono
    serviziSuRichiesta
    descrizione {
      json
    }
  }
}
    `;

/**
 * __useCameraQuery__
 *
 * To run a query within a React component, call `useCameraQuery` and pass it any options that fit your needs.
 * When your component renders, `useCameraQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCameraQuery({
 *   variables: {
 *      locale: // value for 'locale'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCameraQuery(baseOptions: Apollo.QueryHookOptions<CameraQuery, CameraQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CameraQuery, CameraQueryVariables>(CameraDocument, options);
      }
export function useCameraLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CameraQuery, CameraQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CameraQuery, CameraQueryVariables>(CameraDocument, options);
        }
export function useCameraSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<CameraQuery, CameraQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<CameraQuery, CameraQueryVariables>(CameraDocument, options);
        }
export type CameraQueryHookResult = ReturnType<typeof useCameraQuery>;
export type CameraLazyQueryHookResult = ReturnType<typeof useCameraLazyQuery>;
export type CameraSuspenseQueryHookResult = ReturnType<typeof useCameraSuspenseQuery>;
export type CameraQueryResult = Apollo.QueryResult<CameraQuery, CameraQueryVariables>;
export const CamereDocument = gql`
    query camere($locale: String = "it") {
  cameraCollection(locale: $locale) {
    items {
      titolo
      sys {
        id
      }
      foto {
        url
      }
      caratteristiche
    }
  }
}
    `;

/**
 * __useCamereQuery__
 *
 * To run a query within a React component, call `useCamereQuery` and pass it any options that fit your needs.
 * When your component renders, `useCamereQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCamereQuery({
 *   variables: {
 *      locale: // value for 'locale'
 *   },
 * });
 */
export function useCamereQuery(baseOptions?: Apollo.QueryHookOptions<CamereQuery, CamereQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CamereQuery, CamereQueryVariables>(CamereDocument, options);
      }
export function useCamereLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CamereQuery, CamereQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CamereQuery, CamereQueryVariables>(CamereDocument, options);
        }
export function useCamereSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<CamereQuery, CamereQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<CamereQuery, CamereQueryVariables>(CamereDocument, options);
        }
export type CamereQueryHookResult = ReturnType<typeof useCamereQuery>;
export type CamereLazyQueryHookResult = ReturnType<typeof useCamereLazyQuery>;
export type CamereSuspenseQueryHookResult = ReturnType<typeof useCamereSuspenseQuery>;
export type CamereQueryResult = Apollo.QueryResult<CamereQuery, CamereQueryVariables>;
export const HotelDocument = gql`
    query hotel($locale: String = "it") {
  hotelPage(locale: $locale, id: "6j6Rj8UgS4SNt64LCEjvpE") {
    titolo
    titoloAlternativo
    sottoTitolo
    camereIncludono
    serviziSuRichiesta
    descrizione {
      json
    }
  }
}
    `;

/**
 * __useHotelQuery__
 *
 * To run a query within a React component, call `useHotelQuery` and pass it any options that fit your needs.
 * When your component renders, `useHotelQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHotelQuery({
 *   variables: {
 *      locale: // value for 'locale'
 *   },
 * });
 */
export function useHotelQuery(baseOptions?: Apollo.QueryHookOptions<HotelQuery, HotelQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<HotelQuery, HotelQueryVariables>(HotelDocument, options);
      }
export function useHotelLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<HotelQuery, HotelQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<HotelQuery, HotelQueryVariables>(HotelDocument, options);
        }
export function useHotelSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<HotelQuery, HotelQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<HotelQuery, HotelQueryVariables>(HotelDocument, options);
        }
export type HotelQueryHookResult = ReturnType<typeof useHotelQuery>;
export type HotelLazyQueryHookResult = ReturnType<typeof useHotelLazyQuery>;
export type HotelSuspenseQueryHookResult = ReturnType<typeof useHotelSuspenseQuery>;
export type HotelQueryResult = Apollo.QueryResult<HotelQuery, HotelQueryVariables>;
export const ImgDescDocument = gql`
    query imgDesc($locale: String = "it") {
  imgTitleDescCollection(locale: $locale) {
    total
    items {
      img {
        url
      }
      title
      description
    }
  }
}
    `;

/**
 * __useImgDescQuery__
 *
 * To run a query within a React component, call `useImgDescQuery` and pass it any options that fit your needs.
 * When your component renders, `useImgDescQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useImgDescQuery({
 *   variables: {
 *      locale: // value for 'locale'
 *   },
 * });
 */
export function useImgDescQuery(baseOptions?: Apollo.QueryHookOptions<ImgDescQuery, ImgDescQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ImgDescQuery, ImgDescQueryVariables>(ImgDescDocument, options);
      }
export function useImgDescLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ImgDescQuery, ImgDescQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ImgDescQuery, ImgDescQueryVariables>(ImgDescDocument, options);
        }
export function useImgDescSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<ImgDescQuery, ImgDescQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ImgDescQuery, ImgDescQueryVariables>(ImgDescDocument, options);
        }
export type ImgDescQueryHookResult = ReturnType<typeof useImgDescQuery>;
export type ImgDescLazyQueryHookResult = ReturnType<typeof useImgDescLazyQuery>;
export type ImgDescSuspenseQueryHookResult = ReturnType<typeof useImgDescSuspenseQuery>;
export type ImgDescQueryResult = Apollo.QueryResult<ImgDescQuery, ImgDescQueryVariables>;