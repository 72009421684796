import styled from "styled-components";
import { useRistoranteQuery } from "../../generated/graphql";
import { Scroller } from "../../components/Scroller";

const Container = styled.div`
  display: flex;
  padding: 20px;
  gap: 20px;
  img {
    object-fit: contain;
    width: 100%;
    max-width: 400px;
  }
  & > div {
    flex: 1;
  }
  h2 {
    font-weight: 500;
    color: #a48559;
  }
  h1 {
    font-weight: 400;
  }
  &.reverse {
    flex-direction: row-reverse;
    background-color: #f8f7f7;
    @media (max-width: 900px) {
      flex-direction: column;
    }
  }

  @media (max-width: 900px) {
    flex-direction: column;
  }
`;

const GalleryContainer = styled.div`
  overflow: hidden;
  img {
    width: auto;
  }
`;

function TextImageBlock(props: {
  title: string;
  text: string;

  subtitle: string;
  reverse?: boolean;
  scrollerName: string;
  scrollLen: number;
}) {
  return (
    <Container className={props.reverse ? "reverse" : ""}>
      <GalleryContainer>
        <Scroller len={props.scrollLen} name={props.scrollerName} />
      </GalleryContainer>
      <div>
        <h2>{props.subtitle}</h2>
        <h1>{props.title}</h1>
        {props.text}
      </div>
    </Container>
  );
}

export function LocationDesc() {
  const { data } = useRistoranteQuery();
  return (
    <TextImageBlock
      title={data?.location?.titolo || ""}
      text={data?.location?.testo || ""}
      subtitle={data?.location?.sottoTitolo || ""}
      scrollerName="reslocation/resLocation"
      scrollLen={10}
    />
  );
}

export function ChefDesc() {
  const { data } = useRistoranteQuery();
  return (
    <TextImageBlock
      reverse
      title={data?.cucina?.titolo || ""}
      text={data?.cucina?.testo || ""}
      subtitle={data?.cucina?.sottoTitolo || ""}
      scrollerName="resBrigata/resBrigata"
      scrollLen={11}
    />
  );
}
