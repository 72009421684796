import styled from "styled-components";
import { RoomScroller } from "./RoomsScroller";
import { useHotelQuery, useTraduzioniQuery } from "../../generated/graphql";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { Scroller } from "../../components/Scroller";
import { GoldButton } from "../../components/GoldButton";

const RoomsContainer = styled.div`
  display: grid;
  grid-template-rows: auto auto;
  grid-template-columns: auto auto;

  color: white;

  width: auto;

  align-items: end;
`;
const Title = styled.h1`
  padding: 0 1rem;
  grid-row: 1;
  grid-column: 1 / 2;
`;

const Subtitle = styled.p`
  grid-row: 1;
  grid-column: 2 / 3;
  max-width: 400px;
  color: var(--gold);
  @media screen and (max-width: 700px) {
    padding: 0 1rem;
    grid-row: 2;
    grid-column: 1 / 3;
  }
`;
const Text = styled.div`
  grid-row: 2;
  max-width: 700px;
  grid-column: 1 / 3;
  text-align: justify;

  @media screen and (max-width: 700px) {
    padding: 0 1rem;
    grid-row: 3;
    grid-column: 1 / 3;
  }
`;

const FlexContainer = styled.div`
  display: flex;
  padding: 2rem 0rem;
  background-color: black;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  @media screen and (max-width: 900px) {
    padding: 0;
  }
`;

export function Rooms(props: { title?: string }) {
  const { data } = useHotelQuery();
  const trad = useTraduzioniQuery();
  return (
    <>
      <FlexContainer>
        <RoomsContainer>
          <Title>{data?.hotelPage?.titolo}</Title>
          <Subtitle>
            <i>{data?.hotelPage?.sottoTitolo}</i>
          </Subtitle>
          <Text>
            {documentToReactComponents(data?.hotelPage?.descrizione?.json)}
          </Text>
        </RoomsContainer>
        <a href="https://reservations.verticalbooking.com//premium/index.html?id_stile=20865&lingua_int=ita&id_albergo=27613&dc=8387">
          <GoldButton>{trad.data?.traduzioniVarie?.prenota}</GoldButton>
        </a>
        <RoomScroller />
      </FlexContainer>
      <div>
        <Scroller len={33} name="hotelImg/roomspics/roompic" />
      </div>
    </>
  );
}
