import styled, { createGlobalStyle } from "styled-components";
const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;

  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  img {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
  }
`;

const GlobalStyle = createGlobalStyle`
  html {
    overflow: hidden;
 
  }
`;
const CloseButton = styled.div`
  position: absolute;
  top: 20px;
  right: 20px;
  font-size: 3rem;
  cursor: pointer;
  z-index: 1001;
  color: white;
`;
export function FullScreenImg(props: { src: string; onclose: () => void }) {
  return (
    <Container>
      <CloseButton onClick={props.onclose}>✕</CloseButton>
      <GlobalStyle />
      <img src={props.src}></img>
    </Container>
  );
}
