import { MainSlideVideo } from "../../components/MainSlide";
import styled from "styled-components";
import { CurvedSlide } from "./curvedSlider/CurvedSlide";
import { TopDescription } from "../../components/TopDescription";
import { useServiziQuery } from "../../generated/graphql";
import {
  ServicesContainer,
  ServicesImgPair,
} from "../../components/Servicesnew";
import { IconsRow } from "../../components/IconsRow";
import { Footer } from "../../components/Footer";
import { Partner } from "../../components/Partner";
import { WorkWithUs } from "../../components/WorkWithUs";
import { Scroller } from "../../components/Scroller";
import { ContactUsStandard } from "../../components/ContactUsStandard";
import { ArticlesBlock } from "./ArticlesBlock";
import { ExpiriencesScroller } from "../hotel/expiriencesScroller";

export const TextDescription = styled.div`
  text-align: center;
  padding: 20px;
  font-size: 1.5em;
`;
export function ServicesResort() {
  const { data } = useServiziQuery();

  return (
    <ServicesContainer>
      <>
        <ServicesImgPair
          descImg="loghiServices/ris.webp"
          title={data?.servizi?.bistrotERistoranteTitolo || ""}
          imgCover="imgServices/ris.webp"
          desc={data?.servizi?.bistrotERistorante || ""}
          graident=" linear-gradient(90deg, rgba(0, 125, 53, 1) 0%, rgba(0, 125, 53, 1) 0.61%, rgba(140, 177, 26, 1) 70%, rgba(140, 177, 26, 1) 100%)"
        />
        <ServicesImgPair
          descImg="loghiServices/work.webp"
          title={data?.servizi?.coworkingTitolo || ""}
          desc={data?.servizi?.coworking || ""}
          imgCover="imgServices/work.webp"
          graident="linear-gradient(90deg, rgba(136, 31, 48, 1) 0%, rgba(136, 31, 48, 1) 0.21%, rgba(233, 79, 53, 1) 70.11%, rgba(233, 79, 53, 1) 100%)"
        />
        <ServicesImgPair
          descImg="loghiServices/pale.webp"
          title={data?.servizi?.palestraTitolo || ""}
          desc={data?.servizi?.palestra || ""}
          imgCover="imgServices/pale.webp"
          graident="linear-gradient(90deg, rgba(189, 85, 32, 1) 0%, rgba(189, 85, 32, 1) 0.11%, rgba(255, 209, 89, 1) 70%, rgba(255, 209, 89, 1) 100%)"
        />

        <ServicesImgPair
          descImg="loghiServices/fisio.webp"
          title={data?.servizi?.fisioterapistaEOsteopataTitolo || ""}
          imgCover="imgServices/fisio.webp"
          desc={data?.servizi?.fisioterapistaEOsteopata || ""}
          graident="linear-gradient(
          90deg,
          rgba(129, 73, 56, 1) 0%,
          rgba(129, 73, 56, 1) 0.38%,
          rgba(243, 151, 120, 1) 70.28%,
          rgba(243, 151, 120, 1) 100%
        )"
        />
      </>
    </ServicesContainer>
  );
}

export function Resort() {
  return (
    <>
      <MainSlideVideo />

      <TopDescription id="6uCHfccsrKWIckNgJP7OoI" logoImg="logo-bg.webp" />

      <CurvedSlide />
      <ExpiriencesScroller />

      <IconsRow />
      <ServicesResort />

      <ContactUsStandard />
      <Scroller len={18} name="homePics/home" />
      <Partner />
      <WorkWithUs />
      <ArticlesBlock />
      <Footer
        number="+390318180860"
        email="info@thepadelresort.it"
        prettyNumber="+39 031 8180 860"
      />
    </>
  );
}
