import { SlideScritta, VideoSlide } from "../../components/MainSlide";
import { TopDescription } from "../../components/TopDescription";
import { BookPadel } from "./BookPadel";
import { Instructor } from "./Instructors";
import { PadelAcademy } from "./PadelAcademy";
import { Rules } from "./Rules";
import {
  ServicesContainer,
  ServicesImgPair,
} from "../../components/Servicesnew";
import { useServiziQuery } from "../../generated/graphql";
import { IconsRow } from "../../components/IconsRow";
import { Footer } from "../../components/Footer";
import { WorkWithUs } from "../../components/WorkWithUs";
import { Partner } from "../../components/Partner";
import { Scroller } from "../../components/Scroller";
import { ContactUsStandard } from "../../components/ContactUsStandard";
import { ExpiriencesScroller } from "../hotel/expiriencesScroller";

function ServicesPadel() {
  const { data } = useServiziQuery();

  return (
    <ServicesContainer>
      <>
        <ServicesImgPair
          descImg="loghiServices/pale.webp"
          title={data?.servizi?.palestraTitolo || ""}
          desc={data?.servizi?.palestra || ""}
          imgCover="imgServices/pale.webp"
          graident="linear-gradient(90deg, rgba(189, 85, 32, 1) 0%, rgba(189, 85, 32, 1) 0.11%, rgba(255, 209, 89, 1) 70%, rgba(255, 209, 89, 1) 100%)"
        />
        <ServicesImgPair
          descImg="loghiServices/fisio.webp"
          title={data?.servizi?.fisioterapistaEOsteopataTitolo || ""}
          imgCover="imgServices/fisio.webp"
          desc={data?.servizi?.fisioterapistaEOsteopata || ""}
          graident="linear-gradient(
          90deg,
          rgba(129, 73, 56, 1) 0%,
          rgba(129, 73, 56, 1) 0.38%,
          rgba(243, 151, 120, 1) 70.28%,
          rgba(243, 151, 120, 1) 100%
        )"
        />
        <ServicesImgPair
          descImg="loghiServices/ris.webp"
          title={data?.servizi?.bistrotERistoranteTitolo || ""}
          imgCover="imgServices/ris.webp"
          desc={data?.servizi?.bistrotERistorante || ""}
          graident=" linear-gradient(90deg, rgba(0, 125, 53, 1) 0%, rgba(0, 125, 53, 1) 0.61%, rgba(140, 177, 26, 1) 70%, rgba(140, 177, 26, 1) 100%)"
        />
        <ServicesImgPair
          descImg="loghiServices/work.webp"
          title={data?.servizi?.coworkingTitolo || ""}
          desc={data?.servizi?.coworking || ""}
          imgCover="imgServices/work.webp"
          graident="linear-gradient(90deg, rgba(136, 31, 48, 1) 0%, rgba(136, 31, 48, 1) 0.21%, rgba(233, 79, 53, 1) 70.11%, rgba(233, 79, 53, 1) 100%)"
        />
      </>
    </ServicesContainer>
  );
}
export function Padel() {
  return (
    <>
      <VideoSlide
        onClick={() =>
          window.open(
            "https://playtomic.io/tenant/0ccaf81a-0f09-4779-8783-e5e4da356f99?q=PADEL~2024-03-20~~~",
            "_blank"
          )
        }
        logo="/scrittavideo.webp"
        videoUrl="https://tiglivideo.b-cdn.net/padel.mp4"
      />
      <TopDescription
        reverse
        id="cq6lZyblenTpR8QS0jXOp"
        logoImg="padelBg.webp"
        justifyContent
      />

      <BookPadel />
      <PadelAcademy />
      <Instructor />
      <Rules />
      <ExpiriencesScroller />
      <IconsRow />
      <ServicesPadel />

      <ContactUsStandard />
      <Scroller len={18} name="padelPics/padel" />
      <Partner padel />
      <WorkWithUs />
      <Footer
        number="+390318180860"
        email="prenotazioni@thepadelresort.it"
        prettyNumber="+39 031 8180 860"
      />
    </>
  );
}
